import React, { useState, useEffect } from 'react';
import TotalDeals from '../../components/Sales/TotalDeals';
import GrossProfit from '../../components/Sales/GrossProfit';
import PersonnelCards from '../../components/Sales/PersonnelCards';
import LineChartD3 from '../../components/Sales/LineChartD3'; // Import your D3 chart component
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // Arrow icons for month navigation
import './SalesDashboard.css'; // Import the CSS file
import LoadingAnimation from '../../components/LoadingAnimation';

const SalesDashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [personnelData, setPersonnelData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Current month
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [saleType, setSaleType] = useState('all'); // New state for Sale Type
  const [performancePlan, setPerformancePlan] = useState(null); // New state for Performance Plan

  const handleMonthChange = (direction) => {
    if (direction === 'previous') {
      if (selectedMonth === 1) {
        setSelectedMonth(12);
        setSelectedYear((prevYear) => prevYear - 1);
      } else {
        setSelectedMonth((prevMonth) => prevMonth - 1);
      }
    } else if (direction === 'next') {
      if (selectedMonth === 12) {
        setSelectedMonth(1);
        setSelectedYear((prevYear) => prevYear + 1);
      } else {
        setSelectedMonth((prevMonth) => prevMonth + 1);
      }
    }
  };

  const handleSaleTypeChange = (type) => {
    setSaleType(type);
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const token = sessionStorage.getItem('accessToken');
        const salesApiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/sales-dashboard?month=${selectedMonth}&year=${selectedYear}&saleType=${saleType}`;
        const personnelApiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/personnel-metrics?month=${selectedMonth}&year=${selectedYear}&saleType=${saleType}`;
        const chartApiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/sales-chart?month=${selectedMonth}&year=${selectedYear}&saleType=${saleType}`;
        const performancePlanUrl = `${process.env.REACT_APP_BACKEND_URL}/api/performance-plan?month=${selectedMonth}&year=${selectedYear}&saleType=${saleType}`;

        // Fetch sales data
        const salesResponse = await fetch(salesApiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!salesResponse.ok) {
          throw new Error(`Error fetching sales data: ${salesResponse.statusText}`);
        }

        const salesData = await salesResponse.json();
        setDashboardData(salesData);

        // Fetch personnel data
        const personnelResponse = await fetch(personnelApiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!personnelResponse.ok) {
          throw new Error(`Error fetching personnel data: ${personnelResponse.statusText}`);
        }

        const personnelData = await personnelResponse.json();
        setPersonnelData(personnelData);

        // Fetch line chart data
        const chartResponse = await fetch(chartApiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!chartResponse.ok) {
          throw new Error(`Error fetching chart data: ${chartResponse.statusText}`);
        }

        const chartData = await chartResponse.json();
        setChartData(chartData);

        // Fetch performance plan data
        const performanceResponse = await fetch(performancePlanUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!performanceResponse.ok) {
          throw new Error(`Error fetching performance plan data: ${performanceResponse.statusText}`);
        }

        const performanceData = await performanceResponse.json();
        setPerformancePlan(performanceData);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [selectedMonth, selectedYear, saleType]);

  if (loading) {
    return <div><LoadingAnimation /></div>;
  }

  if (!dashboardData || !personnelData || !chartData || !performancePlan) {
    return <div>No data available.</div>;
  }

  return (
    <div className="dashboard-container">
      <div className="month-selector">
        <h2 className="sales-analytics-title">Sales Analytics</h2>
        <div className="month-navigation">
          <button
            onClick={() => handleMonthChange("previous")}
            className="month-button"
          >
            <div>Last Month</div>
            <FaArrowLeft />
          </button>
          <span className="selected-month">
            {`${new Date(
              selectedYear,
              selectedMonth - 1
            ).toLocaleString("default", { month: "long" })} ${selectedYear}`}
          </span>
          <button
            onClick={() => handleMonthChange("next")}
            className="month-button"
          >
            <div>Next Month</div>
            <FaArrowRight />
          </button>
        </div>

        <div className="sale-type-toggle">
          <label className={`sale-type-button ${saleType === 'all' ? 'selected' : ''}`}>
            <input
              type="radio"
              name="saleType"
              value="all"
              onChange={() => handleSaleTypeChange('all')}
              checked={saleType === 'all'}
            />
            All
          </label>
          <label className={`sale-type-button ${saleType === 'new' ? 'selected' : ''}`}>
            <input
              type="radio"
              name="saleType"
              value="new"
              onChange={() => handleSaleTypeChange('new')}
              checked={saleType === 'new'}
            />
            New
          </label>
          <label className={`sale-type-button ${saleType === 'used' ? 'selected' : ''}`}>
            <input
              type="radio"
              name="saleType"
              value="used"
              onChange={() => handleSaleTypeChange('used')}
              checked={saleType === 'used'}
            />
            Used
          </label>
        </div>
      </div>

      <div className="personnel-container">
        <PersonnelCards
          personnelData={personnelData}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          saleType={saleType}
        />
      </div>

      <div className="bottom-container">
        <div className="chart-container">
          <LineChartD3
            data={chartData}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            toggle="total_deals"
            saleType={saleType}
          />
        </div>
        <div className="metrics-container">
          <div className="metric-component total-deals">
            <TotalDeals
              salesData={dashboardData}
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
              saleType={saleType}
              performancePlan={performancePlan} // Pass performance plan data to TotalDeals
            />
          </div>
          <div className="metric-component gross-profit">
            <GrossProfit
              profitData={dashboardData}
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
              saleType={saleType}
              performancePlan={performancePlan} // Pass performance plan data to GrossProfit
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesDashboard;
