import React, { useState } from 'react';
import { FaArrowUp, FaArrowDown, FaArrowLeft, FaArrowRight, FaTrophy } from 'react-icons/fa';  // Added FaTrophy for #1 ranking

const PersonnelCards = ({ personnelData }) => {
  if (!personnelData || personnelData.length === 0) {
    return <div>No Data Available</div>;
  }

  const salespeople = personnelData
    .filter(person => person.role === 'salesperson')
    .sort((a, b) => b.total_deals - a.total_deals); // Sort by total deals for ranking

  const fiProducers = personnelData
    .filter(person => person.role === 'fi_producer')
    .sort((a, b) => (b.total_back_gross / b.total_deals) - (a.total_back_gross / a.total_deals)); // Sort by PVR for ranking

  const salesManagers = personnelData
    .filter(person => person.role === 'sales_manager')
    .sort((a, b) => b.total_front_gross - a.total_front_gross); // Sort by front gross for ranking

  return (
    <div className="personnel-container">
      <SalespeopleCard salespeople={salespeople} />
      <FiProducerCard fiProducers={fiProducers} />
      <SalesManagerCard salesManagers={salesManagers} />
    </div>
  );
};

// SalespeopleCard Component
const SalespeopleCard = ({ salespeople }) => {
  const [salespersonIndex, setSalespersonIndex] = useState(0);
  const [metricIndex, setMetricIndex] = useState(1); // Default to Total Deals

  if (salespeople.length === 0) return <div>No Salespeople Data Available</div>;

  const metrics = [
    { label: 'Total Sales ', value: Math.round(salespeople[salespersonIndex].total_sales).toLocaleString(), isCurrency: true },
    { label: 'Total Deals ', value: Math.round(salespeople[salespersonIndex].total_deals).toLocaleString(), isCurrency: false },
    { label: 'Front Gross ', value: Math.round(salespeople[salespersonIndex].total_front_gross).toLocaleString(), isCurrency: true },
    { label: 'Back Gross ', value: Math.round(salespeople[salespersonIndex].total_back_gross).toLocaleString(), isCurrency: true },
  ];

  const handleCyclePerson = (direction) => {
    setSalespersonIndex((direction === 'up')
      ? (salespersonIndex - 1 + salespeople.length) % salespeople.length
      : (salespersonIndex + 1) % salespeople.length);
  };

  const handleCycleMetric = (direction) => {
    setMetricIndex((direction === 'left')
      ? (metricIndex - 1 + metrics.length) % metrics.length
      : (metricIndex + 1) % metrics.length);
  };

  // Calculate the ranking of the current salesperson
  const rank = salespersonIndex + 1;

  return (
    <div className="personnel-card-compact">
      <h2 className="personnel-card-header" style={{ display: 'flex', alignItems: 'center' }}>
        {rank === 1 && <FaTrophy style={{ color: 'gold', marginRight: '8px' }} />} {/* Trophy for #1 */}
        {`#${rank} Salesperson`}
      </h2>
      <div className="personnel-row">
        <button onClick={() => handleCyclePerson('up')}><FaArrowUp /></button>
        <div className="personnel-name">{salespeople[salespersonIndex].personnel}</div>
        <button onClick={() => handleCyclePerson('down')}><FaArrowDown /></button>
      </div>

      {/* Metric Display with Toggle */}
      <div className="metric-display">
        <button onClick={() => handleCycleMetric('left')}><FaArrowLeft /></button>
        <div className="metric">
          <p>
            {metrics[metricIndex].label} 
            <span>{metrics[metricIndex].isCurrency ? '$' : ''}{metrics[metricIndex].value}</span>
          </p>
        </div>
        <button onClick={() => handleCycleMetric('right')}><FaArrowRight /></button>
      </div>
    </div>
  );
};

// FiProducerCard Component
const FiProducerCard = ({ fiProducers }) => {
  const [fiProducerIndex, setFiProducerIndex] = useState(0);
  const [metricIndex, setMetricIndex] = useState(0); // Default to PVR

  if (fiProducers.length === 0) return <div>No FI Producer Data Available</div>;

  const metrics = [
    {
      label: 'PVR ',
      value: Math.round(Number((fiProducers[fiProducerIndex].total_back_gross / fiProducers[fiProducerIndex].total_deals).toFixed(2)))
        .toLocaleString(),  // This ensures rounding and commas are applied correctly
      isCurrency: true
    },     
    { label: 'Total Deals ', value: Math.round(fiProducers[fiProducerIndex].total_deals).toLocaleString(), isCurrency: false },
    { label: 'Front Gross ', value: Math.round(fiProducers[fiProducerIndex].total_front_gross).toLocaleString(), isCurrency: true },
    { label: 'Back Gross ', value: Math.round(fiProducers[fiProducerIndex].total_back_gross).toLocaleString(), isCurrency: true },
  ];

  const handleCyclePerson = (direction) => {
    setFiProducerIndex((direction === 'up')
      ? (fiProducerIndex - 1 + fiProducers.length) % fiProducers.length
      : (fiProducerIndex + 1) % fiProducers.length);
  };

  const handleCycleMetric = (direction) => {
    setMetricIndex((direction === 'left')
      ? (metricIndex - 1 + metrics.length) % metrics.length
      : (metricIndex + 1) % metrics.length);
  };

  // Calculate the ranking of the current FI producer
  const rank = fiProducerIndex + 1;

  return (
    <div className="personnel-card-compact">
      <h2 className="personnel-card-header" style={{ display: 'flex', alignItems: 'center' }}>
        {rank === 1 && <FaTrophy style={{ color: 'gold', marginRight: '8px' }} />} {/* Trophy for #1 */}
        {`#${rank} FI Producer`}
      </h2>
      <div className="personnel-row">
        <button onClick={() => handleCyclePerson('up')}><FaArrowUp /></button>
        <div className="personnel-name">{fiProducers[fiProducerIndex].personnel}</div>
        <button onClick={() => handleCyclePerson('down')}><FaArrowDown /></button>
      </div>

      {/* Metric Display with Toggle */}
      <div className="metric-display">
        <button onClick={() => handleCycleMetric('left')}><FaArrowLeft /></button>
        <div className="metric">
          <p>
            {metrics[metricIndex].label} 
            <span>{metrics[metricIndex].isCurrency ? '$' : ''}{metrics[metricIndex].value}</span>
          </p>
        </div>
        <button onClick={() => handleCycleMetric('right')}><FaArrowRight /></button>
      </div>
    </div>
  );
};

// SalesManagerCard Component
const SalesManagerCard = ({ salesManagers }) => {
  const [salesManagerIndex, setSalesManagerIndex] = useState(0);
  const [metricIndex, setMetricIndex] = useState(2); // Default to Front Gross

  if (salesManagers.length === 0) return <div>No Sales Manager Data Available</div>;

  const metrics = [
    {
      label: 'Average Front ',
      value: Math.round(Number((salesManagers[salesManagerIndex].total_front_gross / salesManagers[salesManagerIndex].total_deals).toFixed(2)))
        .toLocaleString(),  // This ensures rounding and commas are applied correctly
      isCurrency: true
    },        
    { label: 'Total Deals ', value: Math.round(salesManagers[salesManagerIndex].total_deals).toLocaleString(), isCurrency: false },
    { label: 'Front Gross ', value: Math.round(salesManagers[salesManagerIndex].total_front_gross).toLocaleString(), isCurrency: true },
    { label: 'Back Gross ', value: Math.round(salesManagers[salesManagerIndex].total_back_gross).toLocaleString(), isCurrency: true },
  ];

  const handleCyclePerson = (direction) => {
    setSalesManagerIndex((direction === 'up')
      ? (salesManagerIndex - 1 + salesManagers.length) % salesManagers.length
      : (salesManagerIndex + 1) % salesManagers.length);
  };

  const handleCycleMetric = (direction) => {
    setMetricIndex((direction === 'left')
      ? (metricIndex - 1 + metrics.length) % metrics.length
      : (metricIndex + 1) % metrics.length);
  };

  // Calculate the ranking of the current sales manager
  const rank = salesManagerIndex + 1;

  return (
    <div className="personnel-card-compact">
<h2 className="personnel-card-header" style={{ display: 'flex', alignItems: 'center' }}>
  {rank === 1 && <FaTrophy style={{ color: 'gold', marginRight: '8px' }} />} {/* Trophy for #1 */}
  {`#${rank} Sales Manager`}
</h2>
      <div className="personnel-row">
        <button onClick={() => handleCyclePerson('up')}><FaArrowUp /></button>
        <div className="personnel-name">{salesManagers[salesManagerIndex].personnel}</div>
        <button onClick={() => handleCyclePerson('down')}><FaArrowDown /></button>
      </div>

      {/* Metric Display with Toggle */}
      <div className="metric-display">
        <button onClick={() => handleCycleMetric('left')}><FaArrowLeft /></button>
        <div className="metric">
          <p>
            {metrics[metricIndex].label} 
            <span>{metrics[metricIndex].isCurrency ? '$' : ''}{metrics[metricIndex].value}</span>
          </p>
        </div>
        <button onClick={() => handleCycleMetric('right')}><FaArrowRight /></button>
      </div>
    </div>
  );
};

export default PersonnelCards;
