// src/pages/Forms/InventoryTradeForm.js

import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "./InventoryTradeForm.css"; // optional styling

// -------------------------------------------------------------------
// 1) Helper function: Decode VIN via vPIC (NHTSA) API
// -------------------------------------------------------------------
const decodeVin = async (vin, setExternalVehicleInfo) => {
  try {
    const response = await fetch(
      `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinValuesExtended/${vin}?format=json`
    );
    const data = await response.json();
    if (data.Results && data.Results.length > 0) {
      const result = data.Results[0];
      const capitalize = (str) => {
        if (!str) return "";
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      };
      setExternalVehicleInfo({
        make: capitalize(result.Make) || "",
        model: result.Model || "",
        model_year: result.ModelYear || "",
        vehicle_type: result.VehicleType || "",
        plant_country: result.PlantCountry || "",
        fuel_type: result.FuelTypePrimary || "",
        electrification_level: result.EVDriveUnit || "",
      });
    }
  } catch (error) {
    console.error("Error decoding VIN:", error);
  }
};

// -------------------------------------------------------------------
// 2) Constant for pagination (20 records per page)
// -------------------------------------------------------------------
const recordsPerPage = 20;

// -------------------------------------------------------------------
// 3) New Pagination Component: Horizontal Numbered Pagination
// -------------------------------------------------------------------
const renderNumberedPagination = (data, currentPage, setPage) => {
  const totalPages = Math.ceil(data.length / recordsPerPage);
  if (totalPages <= 1) return null;
  const pageNumbers = [];
  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    pageNumbers.push(1);
    if (currentPage > 3) {
      pageNumbers.push("...");
    }
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 1, currentPage + 1);
      i++
    ) {
      pageNumbers.push(i);
    }
    if (currentPage < totalPages - 2) {
      pageNumbers.push("...");
    }
    pageNumbers.push(totalPages);
  }
  return (
    <div className="pagination-numbers">
      {currentPage > 1 && (
        <button onClick={() => setPage(currentPage - 1)}>Prev</button>
      )}
      {pageNumbers.map((num, index) =>
        num === "..." ? (
          <span key={index}>...</span>
        ) : (
          <button
            key={index}
            onClick={() => setPage(num)}
            style={
              num === currentPage
                ? { fontWeight: "bold", border: "2px solid #3498db" }
                : {}
            }
          >
            {num}
          </button>
        )
      )}
      {currentPage < totalPages && (
        <button onClick={() => setPage(currentPage + 1)}>Next</button>
      )}
    </div>
  );
};

// -------------------------------------------------------------------
// 4) Main Component: InventoryTradeForm
// -------------------------------------------------------------------
function InventoryTradeForm() {
  // Component State
  const [user, setUser] = useState(null);
  const [localStore, setLocalStore] = useState("");
  const [remoteStore, setRemoteStore] = useState("");

  const [localInventory, setLocalInventory] = useState([]);
  const [remoteInventory, setRemoteInventory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Dropdown Filter State
  const [filterSaleType, setFilterSaleType] = useState("");
  const [filterMake, setFilterMake] = useState("");
  const [filterModel, setFilterModel] = useState("");
  const [filterYear, setFilterYear] = useState("");

  // -------------------------------------------------------------------
  // New Search State for External Trade (local inventory)
  // -------------------------------------------------------------------
  const [localSearchInput, setLocalSearchInput] = useState("");
  const [localSearchQuery, setLocalSearchQuery] = useState("");

  // -------------------------------------------------------------------
  // New Search State for Internal Trade (applied to both local & remote)
  // -------------------------------------------------------------------
  const [internalSearchInput, setInternalSearchInput] = useState("");
  const [internalSearchQuery, setInternalSearchQuery] = useState("");

  // Image Carousel State
  const [localImageIndexes, setLocalImageIndexes] = useState({});
  const [remoteImageIndexes, setRemoteImageIndexes] = useState({});

  // For normal "side-by-side" selection (internal trade)
  const [selectedLocalVehicle, setSelectedLocalVehicle] = useState(null);
  const [selectedRemoteVehicle, setSelectedRemoteVehicle] = useState(null);

  // For "Review" mode if we come in with ?request_id=XYZ
  const [searchParams] = useSearchParams();
  const requestId = searchParams.get("request_id");
  const [reviewLocalVehicle, setReviewLocalVehicle] = useState(null);
  const [reviewRemoteVehicle, setReviewRemoteVehicle] = useState(null);

  // External trade specific state
  const [tradeType, setTradeType] = useState("internal"); // "internal" or "external"
  const [externalDealerName, setExternalDealerName] = useState("");
  const [externalVIN, setExternalVIN] = useState("");
  const [externalVehicleInfo, setExternalVehicleInfo] = useState({});

  // Pagination State (we use a single page for internal trade)
  const [localPage, setLocalPage] = useState(1);

  // -------------------------------------------------------------------
  // 5) Fetch user details on mount
  // -------------------------------------------------------------------
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/getUserDetails`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          console.error("Failed to fetch user details");
          return;
        }
        const userData = await response.json();
        setUser(userData);
        if (userData.defaultDatasetId) {
          setLocalStore(userData.defaultDatasetId);
        } else if (userData.dataset_mapping && userData.dataset_mapping.length > 0) {
          setLocalStore(userData.dataset_mapping[0].datasetId);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();
  }, []);

  // -------------------------------------------------------------------
  // 6) If request_id exists, fetch comparison data (Review Mode)
  // -------------------------------------------------------------------
  useEffect(() => {
    if (!requestId) return; // not in "review" mode
    const fetchComparisonData = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        if (!token) return;
        const compareUrl = `${process.env.REACT_APP_BACKEND_URL}/api/get-trade-request-compare?request_id=${requestId}`;
        const resp = await fetch(compareUrl, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (resp.ok) {
          const data = await resp.json();
          if (data.localVehicle) flattenDateFields(data.localVehicle);
          if (data.remoteVehicle) flattenDateFields(data.remoteVehicle);
          setReviewLocalVehicle(data.localVehicle);
          setReviewRemoteVehicle(data.remoteVehicle);
        } else {
          console.error("Failed to fetch trade comparison data");
        }
      } catch (err) {
        console.error("Error fetching trade comparison:", err);
      }
    };
    fetchComparisonData();
  }, [requestId]);

  // -------------------------------------------------------------------
  // 7) Flatten BigQuery date fields
  // -------------------------------------------------------------------
  const flattenDateFields = (row) => {
    if (!row) return row;
    if (row.stock_in_date && typeof row.stock_in_date === "object") {
      row.stock_in_date = row.stock_in_date.value;
    }
    if (row.submitted_at && typeof row.submitted_at === "object") {
      row.submitted_at = row.submitted_at.value;
    }
    return row;
  };

  // -------------------------------------------------------------------
  // 8) Fetch local/remote inventories (normal usage)
  // -------------------------------------------------------------------
  useEffect(() => {
    const fetchInventories = async () => {
      // For internal trade, both local and remote must be set.
      // For external trade, only local is needed.
      if (!localStore || (tradeType === "internal" && !remoteStore)) return;
      setIsLoading(true);
      try {
        const token = localStorage.getItem("accessToken");
        if (!token) {
          console.error("No token found");
          setIsLoading(false);
          return;
        }
        // Fetch local inventory
        const localUrl = `${process.env.REACT_APP_BACKEND_URL}/api/fetch-inventory-by-store?store=${localStore}`;
        const localResp = await fetch(localUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const localData = localResp.ok ? await localResp.json() : [];

        // For internal trade, fetch remote inventory
        let remoteData = [];
        if (tradeType === "internal") {
          const remoteUrl = `${process.env.REACT_APP_BACKEND_URL}/api/fetch-inventory-by-store?store=${remoteStore}`;
          const remoteResp = await fetch(remoteUrl, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          remoteData = remoteResp.ok ? await remoteResp.json() : [];
        }

        localData.forEach(flattenDateFields);
        remoteData.forEach(flattenDateFields);

        const fixFields = (arr) => {
          arr.forEach((v) => {
            v.sale_price = v.sale_price ? Number(v.sale_price) : 0;
            v.inventory_cost = v.inventory_cost ? Number(v.inventory_cost) : 0;
          });
        };
        fixFields(localData);
        fixFields(remoteData);

        const sortFn = (a, b) => {
          const makeCmp = (a.make || "").localeCompare(b.make || "");
          if (makeCmp !== 0) return makeCmp;
          const modelCmp = (a.model || "").localeCompare(b.model || "");
          if (modelCmp !== 0) return modelCmp;
          const yearA = a.model_year || 0;
          const yearB = b.model_year || 0;
          if (yearA !== yearB) return yearA - yearB;
          return (a.sale_price || 0) - (b.sale_price || 0);
        };
        localData.sort(sortFn);
        remoteData.sort(sortFn);

        setLocalInventory(localData);
        setRemoteInventory(remoteData);
        setLocalPage(1);
      } catch (err) {
        console.error("Error fetching inventories:", err);
      }
      setIsLoading(false);
    };
    fetchInventories();
  }, [localStore, remoteStore, tradeType]);

  // -------------------------------------------------------------------
  // 9) Filtering based on dropdowns
  // -------------------------------------------------------------------
  const applyFilters = (vehicles) => {
    return vehicles.filter((v) => {
      if (filterSaleType && v.sale_type !== filterSaleType) return false;
      if (filterMake && v.make !== filterMake) return false;
      if (filterModel && v.model !== filterModel) return false;
      if (filterYear && String(v.model_year) !== filterYear) return false;
      return true;
    });
  };
  const filteredLocal = applyFilters(localInventory);
  const filteredRemote = applyFilters(remoteInventory);

  // -------------------------------------------------------------------
  // 10) Additional Search Filtering for External Trade
  // -------------------------------------------------------------------
  const filteredLocalWithSearch = localSearchQuery
    ? filteredLocal.filter(
        (v) =>
          (v.vin_number &&
            v.vin_number.toLowerCase().includes(localSearchQuery.toLowerCase())) ||
          (v.stock_number &&
            v.stock_number.toLowerCase().includes(localSearchQuery.toLowerCase()))
      )
    : filteredLocal;

  // -------------------------------------------------------------------
  // 11) Additional Search Filtering for Internal Trade (applied to both arrays)
  // -------------------------------------------------------------------
  const filteredInternalLocal = internalSearchQuery
    ? filteredLocal.filter(
        (v) =>
          (v.vin_number &&
            v.vin_number.toLowerCase().includes(internalSearchQuery.toLowerCase())) ||
          (v.stock_number &&
            v.stock_number.toLowerCase().includes(internalSearchQuery.toLowerCase()))
      )
    : filteredLocal;
  const filteredInternalRemote = internalSearchQuery
    ? filteredRemote.filter(
        (v) =>
          (v.vin_number &&
            v.vin_number.toLowerCase().includes(internalSearchQuery.toLowerCase())) ||
          (v.stock_number &&
            v.stock_number.toLowerCase().includes(internalSearchQuery.toLowerCase()))
      )
    : filteredRemote;

  // -------------------------------------------------------------------
  // 12) Pagination helper function
  // -------------------------------------------------------------------
  const paginate = (data, page) => {
    const start = (page - 1) * recordsPerPage;
    return data.slice(start, start + recordsPerPage);
  };

  // For internal trade, use the same page for both local and remote inventories:
  const paginatedInternalLocal = paginate(filteredInternalLocal, localPage);
  const paginatedInternalRemote = paginate(filteredInternalRemote, localPage);

  // -------------------------------------------------------------------
  // 13) Format currency
  // -------------------------------------------------------------------
  const formatCurrency = (num) => {
    if (num === null || num === undefined) return "";
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(num);
  };

  // -------------------------------------------------------------------
  // 14) Difference Calculations
  // -------------------------------------------------------------------
  const calcDiff = (localVal, remoteVal) => {
    return Number(remoteVal || 0) - Number(localVal || 0);
  };

  const calcDiffReviewMode = (localVal, remoteVal) => {
    return Number(localVal || 0) - Number(remoteVal || 0);
  };

  const calcDaysDiff = (localDate, remoteDate) => {
    if (!localDate || !remoteDate) return "";
    try {
      const dLocal = new Date(localDate);
      const dRemote = new Date(remoteDate);
      return Math.floor((dRemote - dLocal) / (1000 * 60 * 60 * 24));
    } catch {
      return "";
    }
  };

  const arrowIcon = (diff, isCurrency = false) => {
    const diffVal = isCurrency ? formatCurrency(Math.abs(diff)) : Math.abs(diff);
    if (diff > 0) {
      return <span style={{ color: "green" }}>▲ +{diffVal}</span>;
    } else if (diff < 0) {
      return <span style={{ color: "red" }}>▼ -{diffVal}</span>;
    }
    return <span>{diffVal}</span>;
  };

  // -------------------------------------------------------------------
  // 15) Image Carousel helpers
  // -------------------------------------------------------------------
  const getLocalImgIndex = (i) => localImageIndexes[i] || 0;
  const getRemoteImgIndex = (i) => remoteImageIndexes[i] || 0;

  const handleLocalImgPrev = (rowIndex, total) => {
    setLocalImageIndexes((prev) => {
      const current = prev[rowIndex] || 0;
      const nextIndex = (current - 1 + total) % total;
      return { ...prev, [rowIndex]: nextIndex };
    });
  };
  const handleLocalImgNext = (rowIndex, total) => {
    setLocalImageIndexes((prev) => {
      const current = prev[rowIndex] || 0;
      const nextIndex = (current + 1) % total;
      return { ...prev, [rowIndex]: nextIndex };
    });
  };

  const handleRemoteImgPrev = (rowIndex, total) => {
    setRemoteImageIndexes((prev) => {
      const current = prev[rowIndex] || 0;
      const nextIndex = (current - 1 + total) % total;
      return { ...prev, [rowIndex]: nextIndex };
    });
  };
  const handleRemoteImgNext = (rowIndex, total) => {
    setRemoteImageIndexes((prev) => {
      const current = prev[rowIndex] || 0;
      const nextIndex = (current + 1) % total;
      return { ...prev, [rowIndex]: nextIndex };
    });
  };

  // -------------------------------------------------------------------
  // 16) Selection handlers
  // -------------------------------------------------------------------
  const handleSelectLocal = (vehicle) => {
    setSelectedLocalVehicle(vehicle);
    if (selectedRemoteVehicle) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  const handleSelectRemote = (vehicle) => {
    setSelectedRemoteVehicle(vehicle);
    if (selectedLocalVehicle) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  const clearSelections = () => {
    setSelectedLocalVehicle(null);
    setSelectedRemoteVehicle(null);
  };

  // -------------------------------------------------------------------
  // 17) External VIN handling (for external trade)
  // -------------------------------------------------------------------
  const handleExternalVINLookup = async () => {
    if (externalVIN) {
      await decodeVin(externalVIN, setExternalVehicleInfo);
    }
  };

  // -------------------------------------------------------------------
  // 18) Trade Request Submission
  // -------------------------------------------------------------------
  const handleRequestTrade = async () => {
    if (!selectedLocalVehicle) {
      console.error("A local vehicle must be selected.");
      return;
    }
    if (tradeType === "internal" && !selectedRemoteVehicle) {
      console.error("For internal trade, a remote vehicle must be selected.");
      return;
    }
    if (
      tradeType === "external" &&
      (!externalDealerName || !externalVIN || !externalVehicleInfo.make)
    ) {
      console.error(
        "For external trade, provide external dealer name and a valid external VIN."
      );
      return;
    }
    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.error("No token found");
      return;
    }
    let body = {
      requesting_vin: selectedLocalVehicle.vin_number,
      requesting_stock_number: selectedLocalVehicle.stock_number,
      requesting_make: selectedLocalVehicle.make,
      requesting_model: selectedLocalVehicle.model,
      requesting_model_year: selectedLocalVehicle.model_year,
      requesting_sale_type: selectedLocalVehicle.sale_type,
      requesting_store: localStore,
      submitted_by: user?.first_name || "Unknown",
    };

    if (tradeType === "internal") {
      body = {
        ...body,
        requested_vin: selectedRemoteVehicle.vin_number,
        requested_stock_number: selectedRemoteVehicle.stock_number,
        requested_sale_type: selectedRemoteVehicle.sale_type,
        requested_store: remoteStore,
      };
    } else if (tradeType === "external") {
      body = {
        ...body,
        requested_vin: externalVIN,
        requested_stock_number: "", // not applicable
        requested_sale_type: "external",
        requested_store: externalDealerName,
        requested_make: externalVehicleInfo.make,
        requested_model: externalVehicleInfo.model,
        requested_model_year: externalVehicleInfo.model_year,
      };
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/request-trade`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        }
      );
      if (response.ok) {
        alert("Trade request sent!");
      } else {
        console.error("Failed to send trade request");
      }
    } catch (error) {
      console.error("Error sending trade request:", error);
    }
  };

  // -------------------------------------------------------------------
  // 19) Approve/Reject in Review mode
  // -------------------------------------------------------------------
  const handleApproveReview = async () => {
    if (!requestId) return;
    try {
      const token = localStorage.getItem("accessToken");
      const resp = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/approve-trade-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ request_id: requestId }),
        }
      );
      if (resp.ok) {
        alert("Trade approved!");
        setReviewLocalVehicle(null);
        setReviewRemoteVehicle(null);
      } else {
        console.error("Failed to approve trade");
      }
    } catch (err) {
      console.error("Error approving trade:", err);
    }
  };

  const handleRejectReview = async () => {
    if (!requestId) return;
    try {
      const token = localStorage.getItem("accessToken");
      const resp = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/reject-trade-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ request_id: requestId }),
        }
      );
      if (resp.ok) {
        alert("Trade rejected.");
        setReviewLocalVehicle(null);
        setReviewRemoteVehicle(null);
      } else {
        console.error("Failed to reject trade");
      }
    } catch (err) {
      console.error("Error rejecting trade:", err);
    }
  };

  // -------------------------------------------------------------------
  // 20) Render Review Mode Comparison
  // -------------------------------------------------------------------
  const renderReviewComparison = () => {
    if (!reviewLocalVehicle || !reviewRemoteVehicle) return null;
    const priceDiff = calcDiffReviewMode(
      reviewLocalVehicle.sale_price,
      reviewRemoteVehicle.sale_price
    );
    const costDiff = calcDiffReviewMode(
      reviewLocalVehicle.inventory_cost,
      reviewRemoteVehicle.inventory_cost
    );
    const daysDiff = calcDaysDiff(
      reviewLocalVehicle.stock_in_date,
      reviewRemoteVehicle.stock_in_date
    );
    const localImg = reviewLocalVehicle.inventory_images?.[0];
    const remoteImg = reviewRemoteVehicle.inventory_images?.[0];
    return (
      <div className="focused-comparison" style={{ marginBottom: "2rem" }}>
        <h3>Review Trade Request</h3>
        <div className="focused-comparison-row">
          <div className="focused-vehicle">
            <h4 className="vehicle-header">Offered Vehicle</h4>
            {localImg && (
              <div className="focused-image-container">
                <img src={localImg} alt="Offered local vehicle" />
              </div>
            )}
            <p><strong>Make:</strong> {reviewLocalVehicle.make}</p>
            <p><strong>Model:</strong> {reviewLocalVehicle.model}</p>
            <p><strong>Year:</strong> {reviewLocalVehicle.model_year}</p>
            <p><strong>Sale Price:</strong> {formatCurrency(reviewLocalVehicle.sale_price)}</p>
            <p><strong>Inventory Cost:</strong> {formatCurrency(reviewLocalVehicle.inventory_cost)}</p>
            <p><strong>VIN:</strong> {reviewLocalVehicle.vin_number}</p>
            <p><strong>Stock In Date:</strong> {reviewLocalVehicle.stock_in_date}</p>
          </div>
          <div className="focused-differences">
            <p><strong>Price Diff:</strong> {arrowIcon(priceDiff, true)}</p>
            <p><strong>Cost Diff:</strong> {arrowIcon(costDiff, true)}</p>
            <p><strong>Days Diff:</strong> {daysDiff > 0 ? `+${daysDiff}` : daysDiff}</p>
          </div>
          <div className="focused-vehicle">
            <h4 className="vehicle-header">Requested Vehicle</h4>
            {remoteImg && (
              <div className="focused-image-container">
                <img src={remoteImg} alt="Requested remote vehicle" />
              </div>
            )}
            <p><strong>Make:</strong> {reviewRemoteVehicle.make}</p>
            <p><strong>Model:</strong> {reviewRemoteVehicle.model}</p>
            <p><strong>Year:</strong> {reviewRemoteVehicle.model_year}</p>
            <p><strong>Sale Price:</strong> {formatCurrency(reviewRemoteVehicle.sale_price)}</p>
            <p><strong>Inventory Cost:</strong> {formatCurrency(reviewRemoteVehicle.inventory_cost)}</p>
            <p><strong>VIN:</strong> {reviewRemoteVehicle.vin_number}</p>
            <p><strong>Stock In Date:</strong> {reviewRemoteVehicle.stock_in_date}</p>
          </div>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <button onClick={handleApproveReview}>Approve</button>
          <button style={{ marginLeft: "1rem" }} onClick={handleRejectReview}>Reject</button>
        </div>
      </div>
    );
  };

  // -------------------------------------------------------------------
  // 21) Render Selected Comparison for Internal Trade
  // -------------------------------------------------------------------
  const renderSelectedComparison = () => {
    if (!selectedLocalVehicle || !selectedRemoteVehicle) return null;
    const priceDiff = calcDiff(
      selectedLocalVehicle.sale_price,
      selectedRemoteVehicle.sale_price
    );
    const costDiff = calcDiff(
      selectedLocalVehicle.inventory_cost,
      selectedRemoteVehicle.inventory_cost
    );
    const daysDiff = calcDaysDiff(
      selectedLocalVehicle.stock_in_date,
      selectedRemoteVehicle.stock_in_date
    );
    const localImg = selectedLocalVehicle.inventory_images?.[0];
    const remoteImg = selectedRemoteVehicle.inventory_images?.[0];
    return (
      <div className="focused-comparison" style={{ marginBottom: "2rem" }}>
        <h3>Selected Comparison</h3>
        <div className="focused-comparison-row">
          <div className="focused-vehicle">
            <h4 className="vehicle-header">Offered Vehicle</h4>
            {localImg && (
              <div className="focused-image-container">
                <img src={localImg} alt="Local vehicle" />
              </div>
            )}
            <p><strong>Make:</strong> {selectedLocalVehicle.make}</p>
            <p><strong>Model:</strong> {selectedLocalVehicle.model}</p>
            <p><strong>Year:</strong> {selectedLocalVehicle.model_year}</p>
            <p><strong>Sale Price:</strong> {formatCurrency(selectedLocalVehicle.sale_price)}</p>
            <p><strong>Inventory Cost:</strong> {formatCurrency(selectedLocalVehicle.inventory_cost)}</p>
          </div>
          <div className="focused-differences">
            <p><strong>Price Diff:</strong> {arrowIcon(priceDiff, true)}</p>
            <p><strong>Cost Diff:</strong> {arrowIcon(costDiff, true)}</p>
            <p><strong>Days Diff:</strong> {daysDiff > 0 ? `+${daysDiff}` : daysDiff}</p>
          </div>
          <div className="focused-vehicle">
            <h4 className="vehicle-header">Requested Vehicle</h4>
            {remoteImg && (
              <div className="focused-image-container">
                <img src={remoteImg} alt="Remote vehicle" />
              </div>
            )}
            <p><strong>Make:</strong> {selectedRemoteVehicle.make}</p>
            <p><strong>Model:</strong> {selectedRemoteVehicle.model}</p>
            <p><strong>Year:</strong> {selectedRemoteVehicle.model_year}</p>
            <p><strong>Sale Price:</strong> {formatCurrency(selectedRemoteVehicle.sale_price)}</p>
            <p><strong>Inventory Cost:</strong> {formatCurrency(selectedRemoteVehicle.inventory_cost)}</p>
          </div>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <button onClick={handleRequestTrade}>Request Trade</button>
          <button onClick={clearSelections} style={{ marginLeft: "0.5rem" }}>Clear Selections</button>
        </div>
      </div>
    );
  };

  // -------------------------------------------------------------------
  // 22) Render External Comparison (for external trade)
  // -------------------------------------------------------------------
  const renderExternalComparison = () => {
    if (!selectedLocalVehicle || !externalVehicleInfo.make) return null;
    return (
      <div className="focused-comparison" style={{ marginBottom: "2rem" }}>
        <h3>Selected Comparison</h3>
        <div className="focused-comparison-row">
          <div className="focused-vehicle">
            <h4 className="vehicle-header">Offered Vehicle</h4>
            {selectedLocalVehicle.inventory_images?.[0] && (
              <div className="focused-image-container">
                <img src={selectedLocalVehicle.inventory_images[0]} alt="Local vehicle" />
              </div>
            )}
            <p><strong>Make:</strong> {selectedLocalVehicle.make}</p>
            <p><strong>Model:</strong> {selectedLocalVehicle.model}</p>
            <p><strong>Year:</strong> {selectedLocalVehicle.model_year}</p>
            <p><strong>Sale Price:</strong> {formatCurrency(selectedLocalVehicle.sale_price)}</p>
            <p><strong>Inventory Cost:</strong> {formatCurrency(selectedLocalVehicle.inventory_cost)}</p>
          </div>
          <div className="focused-differences">
            <p>N/A</p>
          </div>
          <div className="focused-vehicle">
            <h4 className="vehicle-header">External Vehicle</h4>
            {externalVehicleInfo && (
              <>
                <p><strong>Dealer:</strong> {externalDealerName}</p>
                <p><strong>VIN:</strong> {externalVIN}</p>
                <p><strong>Make:</strong> {externalVehicleInfo.make}</p>
                <p><strong>Model:</strong> {externalVehicleInfo.model}</p>
                <p><strong>Year:</strong> {externalVehicleInfo.model_year}</p>
              </>
            )}
          </div>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <button onClick={handleRequestTrade}>Request Trade</button>
          <button onClick={clearSelections} style={{ marginLeft: "0.5rem" }}>Clear Selections</button>
        </div>
      </div>
    );
  };

  // -------------------------------------------------------------------
  // 23) Render Side-by-Side Table for Internal Trade (using paginated data)
  // -------------------------------------------------------------------
  const renderSideBySide = () => {
    const localArray = paginatedInternalLocal;
    const remoteArray = paginatedInternalRemote;
    const maxLen = Math.max(localArray.length, remoteArray.length);
    const rows = [];
    // Headers
    rows.push(
      <div className="trade-row headers-row" key="headers">
        <div className="trade-col local-vehicle header-col">
          <strong>Internal Inventory</strong>
        </div>
        <div className="trade-col difference-col header-col">
          <strong>Differences</strong>
        </div>
        <div className="trade-col remote-vehicle header-col">
          <strong>Comparable Inventory</strong>
        </div>
      </div>
    );
    for (let i = 0; i < maxLen; i++) {
      const local = localArray[i] || {};
      const remote = remoteArray[i] || {};

      const priceDiff = calcDiff(local.sale_price, remote.sale_price);
      const costDiff = calcDiff(local.inventory_cost, remote.inventory_cost);
      const daysDiff = calcDaysDiff(local.stock_in_date, remote.stock_in_date);

      const localSelected =
        selectedLocalVehicle && local.vin_number === selectedLocalVehicle.vin_number
          ? "selected-vehicle"
          : "";
      const remoteSelected =
        selectedRemoteVehicle && remote.vin_number === selectedRemoteVehicle.vin_number
          ? "selected-vehicle"
          : "";

      let localImageContent = null;
      if (local.inventory_images?.length > 0) {
        const idx = getLocalImgIndex(i);
        const url = local.inventory_images[idx];
        localImageContent = (
          <div className="images-container">
            <button onClick={() => handleLocalImgPrev(i, local.inventory_images.length)}>
              ◀
            </button>
            <img src={url} alt={`Local vehicle ${idx}`} />
            <button onClick={() => handleLocalImgNext(i, local.inventory_images.length)}>
              ▶
            </button>
          </div>
        );
      }

      let remoteImageContent = null;
      if (remote.inventory_images?.length > 0) {
        const idx = getRemoteImgIndex(i);
        const url = remote.inventory_images[idx];
        remoteImageContent = (
          <div className="images-container">
            <button onClick={() => handleRemoteImgPrev(i, remote.inventory_images.length)}>
              ◀
            </button>
            <img src={url} alt={`Remote vehicle ${idx}`} />
            <button onClick={() => handleRemoteImgNext(i, remote.inventory_images.length)}>
              ▶
            </button>
          </div>
        );
      }

      rows.push(
        <div className="trade-row" key={i}>
          <div className={`trade-col local-vehicle ${localSelected}`}>
            {localImageContent}
            <p><strong>Make:</strong> {local.make}</p>
            <p><strong>Model:</strong> {local.model}</p>
            <p><strong>Year:</strong> {local.model_year}</p>
            <p><strong>Sale Type:</strong> {local.sale_type}</p>
            <p><strong>Sale Price:</strong> {formatCurrency(local.sale_price)}</p>
            <p><strong>Inventory Cost:</strong> {formatCurrency(local.inventory_cost)}</p>
            <p><strong>Stock In Date:</strong> {local.stock_in_date}</p>
            {local.vin_number && <p><strong>VIN:</strong> {local.vin_number}</p>}
            {local.stock_number && <p><strong>Stock #:</strong> {local.stock_number}</p>}
            {local.vin_number && (
              <button onClick={() => handleSelectLocal(local)}>Select</button>
            )}
          </div>
          <div className="trade-col difference-col">
            <p><strong>Price Diff:</strong> {arrowIcon(priceDiff, true)}</p>
            <p><strong>Cost Diff:</strong> {arrowIcon(costDiff, true)}</p>
            <p><strong>Days Diff:</strong>{" "}
              {daysDiff ? (daysDiff > 0 ? `+${daysDiff}` : daysDiff) : daysDiff}
            </p>
          </div>
          <div className={`trade-col remote-vehicle ${remoteSelected}`}>
            {remoteImageContent}
            <p><strong>Make:</strong> {remote.make}</p>
            <p><strong>Model:</strong> {remote.model}</p>
            <p><strong>Year:</strong> {remote.model_year}</p>
            <p><strong>Sale Type:</strong> {remote.sale_type}</p>
            <p><strong>Sale Price:</strong> {formatCurrency(remote.sale_price)}</p>
            <p><strong>Inventory Cost:</strong> {formatCurrency(remote.inventory_cost)}</p>
            <p><strong>Stock In Date:</strong> {remote.stock_in_date}</p>
            {remote.vin_number && <p><strong>VIN:</strong> {remote.vin_number}</p>}
            {remote.stock_number && <p><strong>Stock #:</strong> {remote.stock_number}</p>}
            {remote.vin_number && (
              <button onClick={() => handleSelectRemote(remote)}>Select</button>
            )}
          </div>
        </div>
      );
    }
    return rows;
  };

  // -------------------------------------------------------------------
  // 24) Render Dynamic Filter Lists for dropdowns
  // -------------------------------------------------------------------
  const combinedAll = [...localInventory, ...remoteInventory];
  const filteredBySaleType = filterSaleType
    ? combinedAll.filter((v) => v.sale_type === filterSaleType)
    : combinedAll;
  const filteredByMake = filterMake
    ? filteredBySaleType.filter((v) => v.make === filterMake)
    : filteredBySaleType;
  const filteredByYear = filterYear
    ? filteredByMake.filter((v) => String(v.model_year) === filterYear)
    : filteredByMake;
  const makeList = Array.from(new Set(filteredBySaleType.map((v) => v.make).filter(Boolean))).sort();
  const yearList = Array.from(new Set(filteredByMake.map((v) => v.model_year).filter(Boolean))).sort((a, b) => a - b);
  const modelList = Array.from(new Set(filteredByYear.map((v) => v.model).filter(Boolean))).sort();
  const saleTypes = Array.from(new Set(combinedAll.map((v) => v.sale_type).filter(Boolean))).sort();

  // -------------------------------------------------------------------
  // 25) Render Store Dropdown Options
  // -------------------------------------------------------------------
  const renderStoreDropdownOptions = () => {
    if (!user?.dataset_mapping) return null;
    return user.dataset_mapping.map((storeObj) => (
      <option key={storeObj.datasetId} value={storeObj.datasetId}>
        {storeObj.rooftop}
      </option>
    ));
  };

  // -------------------------------------------------------------------
  // 26) Render Toggle-style Trade Type Selector
  // -------------------------------------------------------------------
  const renderTradeTypeSelector = () => {
    return (
      <div className="trade-type-toggle">
        <button
          className={tradeType === "internal" ? "active" : ""}
          onClick={() => setTradeType("internal")}
        >
          Internal Trade
        </button>
        <button
          className={tradeType === "external" ? "active" : ""}
          onClick={() => setTradeType("external")}
        >
          External Dealer Trade
        </button>
      </div>
    );
  };

  // -------------------------------------------------------------------
  // 27) Render External Fields (Right Side for External Trade)
  // -------------------------------------------------------------------
  const renderExternalFields = () => {
    if (tradeType !== "external") return null;
    return (
      <div className="external-fields-container">
        <h3>External Vehicle Info</h3>
        <div style={{ marginBottom: "1rem" }}>
          <label style={{ display: "block", marginBottom: "0.5rem" }}>
            External Dealer Name:
          </label>
          <input
            type="text"
            value={externalDealerName}
            onChange={(e) => setExternalDealerName(e.target.value)}
            placeholder="Enter dealership name"
            required
          />
        </div>
        <div style={{ marginBottom: "1rem" }}>
          <label style={{ display: "block", marginBottom: "0.5rem" }}>
            External Vehicle VIN:
          </label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="text"
              value={externalVIN}
              onChange={(e) => setExternalVIN(e.target.value)}
              placeholder="Enter VIN"
              required
            />
            <button onClick={handleExternalVINLookup} style={{ marginLeft: "0.5rem" }}>
              Lookup VIN
            </button>
          </div>
        </div>
        {externalVehicleInfo.make && (
          <div className="external-vehicle-info">
            <p><strong>Make:</strong> {externalVehicleInfo.make}</p>
            <p><strong>Model:</strong> {externalVehicleInfo.model}</p>
            <p><strong>Year:</strong> {externalVehicleInfo.model_year}</p>
          </div>
        )}
      </div>
    );
  };

  // -------------------------------------------------------------------
  // 28) Render External Local Inventory (Left Side for External Trade)
  // -------------------------------------------------------------------
  const renderExternalLocalInventory = () => {
    return filteredLocalWithSearch
      .slice((localPage - 1) * recordsPerPage, localPage * recordsPerPage)
      .map((vehicle, index) => {
        const selected =
          selectedLocalVehicle &&
          vehicle.vin_number === selectedLocalVehicle.vin_number
            ? "selected-vehicle"
            : "";
        let imageContent = null;
        if (vehicle.inventory_images?.length > 0) {
          const idx = getLocalImgIndex(index);
          const url = vehicle.inventory_images[idx];
          imageContent = (
            <div className="images-container">
              <button onClick={() => handleLocalImgPrev(index, vehicle.inventory_images.length)}>
                ◀
              </button>
              <img src={url} alt={`Local vehicle ${idx}`} />
              <button onClick={() => handleLocalImgNext(index, vehicle.inventory_images.length)}>
                ▶
              </button>
            </div>
          );
        }
        return (
          <div
            key={vehicle.vin_number}
            className={`trade-row ${selected}`}
            onClick={() => handleSelectLocal(vehicle)}
            style={{ cursor: "pointer" }}
          >
            <div className="trade-col local-vehicle">
              {imageContent}
              <p><strong>Make:</strong> {vehicle.make}</p>
              <p><strong>Model:</strong> {vehicle.model}</p>
              <p><strong>Year:</strong> {vehicle.model_year}</p>
              <p><strong>Sale Price:</strong> {formatCurrency(vehicle.sale_price)}</p>
              <p><strong>Inventory Cost:</strong> {formatCurrency(vehicle.inventory_cost)}</p>
              <p><strong>Stock In Date:</strong> {vehicle.stock_in_date}</p>
              {vehicle.vin_number && <p><strong>VIN:</strong> {vehicle.vin_number}</p>}
              {vehicle.stock_number && <p><strong>Stock #:</strong> {vehicle.stock_number}</p>}
            </div>
          </div>
        );
      });
  };

  // -------------------------------------------------------------------
  // 29) Handle Local Inventory Search for External Trade
  // -------------------------------------------------------------------
  const handleLocalSearch = () => {
    setLocalSearchQuery(localSearchInput);
    setLocalPage(1);
  };

  // -------------------------------------------------------------------
  // 30) Handle Internal Inventory Search for Internal Trade
  // -------------------------------------------------------------------
  const handleInternalSearch = () => {
    setInternalSearchQuery(internalSearchInput);
    setLocalPage(1);
  };

  // -------------------------------------------------------------------
  // 31) Render
  // -------------------------------------------------------------------
  // For internal trade, compute a combined total page count
  const totalPagesInternal = Math.max(
    Math.ceil(filteredInternalLocal.length / recordsPerPage),
    Math.ceil(filteredInternalRemote.length / recordsPerPage)
  );
  // Create a dummy array so that our pagination function computes total pages correctly.
  const dummyArrayForInternal = new Array(totalPagesInternal * recordsPerPage).fill(0);

  return (
    <div className="inventory-trade-form">
      <h2>Inventory Trade Form</h2>

      {/* Trade Type Toggle */}
      {renderTradeTypeSelector()}

      {/* Review Mode Comparison */}
      {requestId && renderReviewComparison()}

      {/* Internal Trade: Selected Comparison */}
      {tradeType === "internal" && renderSelectedComparison()}

      {/* External Trade: Selected Comparison */}
      {tradeType === "external" && renderExternalComparison()}

      {/* Store Dropdowns for Internal Trade */}
      {tradeType === "internal" && (
        <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
          <div>
            <label>
              Local Store:
              <select
                style={{ marginLeft: 8 }}
                value={localStore}
                onChange={(e) => setLocalStore(e.target.value)}
              >
                <option value="">-- Select Local Store --</option>
                {renderStoreDropdownOptions()}
              </select>
            </label>
          </div>
          <div>
            <label>
              Compare/Trade with:
              <select
                style={{ marginLeft: 8 }}
                value={remoteStore}
                onChange={(e) => setRemoteStore(e.target.value)}
              >
                <option value="">-- Select a Remote Store --</option>
                {renderStoreDropdownOptions()}
              </select>
            </label>
          </div>
        </div>
      )}

      {/* Dropdown Filters */}
      <div className="filters-container">
        <label>
          Sale Type:
          <select
            value={filterSaleType}
            onChange={(e) => setFilterSaleType(e.target.value)}
          >
            <option value="">All</option>
            {saleTypes.map((st) => (
              <option key={st} value={st}>
                {st}
              </option>
            ))}
          </select>
        </label>

        <label>
          Make:
          <select
            value={filterMake}
            onChange={(e) => setFilterMake(e.target.value)}
          >
            <option value="">All</option>
            {makeList.map((mk) => (
              <option key={mk} value={mk}>
                {mk}
              </option>
            ))}
          </select>
        </label>

        <label>
          Year:
          <select
            value={filterYear}
            onChange={(e) => setFilterYear(e.target.value)}
          >
            <option value="">All</option>
            {yearList.map((yr) => (
              <option key={yr} value={String(yr)}>
                {yr}
              </option>
            ))}
          </select>
        </label>

        <label>
          Model:
          <select
            value={filterModel}
            onChange={(e) => setFilterModel(e.target.value)}
          >
            <option value="">All</option>
            {modelList.map((md) => (
              <option key={md} value={md}>
                {md}
              </option>
            ))}
          </select>
        </label>

        <button
          onClick={() => {
            setFilterSaleType("");
            setFilterMake("");
            setFilterModel("");
            setFilterYear("");
          }}
        >
          Clear Filters
        </button>
      </div>

      {/* Internal Trade: Inventory Search (VIN/Stock #) */}
      {tradeType === "internal" && (
        <div className="internal-inventory-filter" style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
          <label style={{ marginRight: "0.5rem" }}>
            Search Inventory:
          </label>
          <input
            type="text"
            value={internalSearchInput}
            onChange={(e) => setInternalSearchInput(e.target.value)}
            placeholder="Enter VIN or Stock Number"
            style={{ padding: "0.5rem" }}
          />
          <button onClick={handleInternalSearch} style={{ marginLeft: "0.5rem", padding: "0.5rem 1rem" }}>
            Search
          </button>
        </div>
      )}

      {/* External Trade: Inventory Search (VIN/Stock #) */}
      {tradeType === "external" && (
        <div className="external-inventory-filter" style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
          <label style={{ marginRight: "0.5rem" }}>
            Search Inventory:
          </label>
          <input
            type="text"
            value={localSearchInput}
            onChange={(e) => setLocalSearchInput(e.target.value)}
            placeholder="Enter VIN or Stock Number"
            style={{ padding: "0.5rem" }}
          />
          <button onClick={handleLocalSearch} style={{ marginLeft: "0.5rem", padding: "0.5rem 1rem" }}>
            Search
          </button>
        </div>
      )}

      {isLoading && <p>Loading inventories...</p>}

      {/* Internal Trade: Render Side-by-Side Inventory with Single Pagination */}
      {tradeType === "internal" && localStore && remoteStore && (
        <>
          <div className="trade-compare-container">{renderSideBySide()}</div>
          <div className="pagination-wrapper">
            {renderNumberedPagination(dummyArrayForInternal, localPage, setLocalPage)}
          </div>
        </>
      )}

      {/* External Trade: Render two columns – left: local inventory; right: external fields */}
      {tradeType === "external" && localStore && (
        <>
          <div className="external-trade-container" style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
            <div className="trade-col external-local-inventory" style={{ flex: 1 }}>
              {renderExternalLocalInventory()}
            </div>
            <div className="trade-col external-vehicle" style={{ flex: 1 }}>
              {renderExternalFields()}
            </div>
          </div>
          {renderNumberedPagination(filteredLocalWithSearch, localPage, setLocalPage)}
        </>
      )}
    </div>
  );
}

export default InventoryTradeForm;
