// FPReconPage.js
import React, { useState, useEffect } from "react";
import FPReconReport from "./FPReconReport";
import "./FPReconPage.css";

const FPReconPage = () => {
  // State for month selection (YYYY-MM format)
  const [selectedMonth, setSelectedMonth] = useState(() => {
    const now = new Date();
    return now.toISOString().slice(0, 7);
  });
  // List of all files from the backend
  const [files, setFiles] = useState([]);
  // Files filtered by month and by type
  const [adpFiles, setAdpFiles] = useState([]);
  const [suntrustFiles, setSuntrustFiles] = useState([]);
  // User selections for ADP and Suntrust files
  const [selectedAdpFile, setSelectedAdpFile] = useState("");
  const [selectedSuntrustFile, setSelectedSuntrustFile] = useState("");
  // Report data returned from the backend
  const [reportData, setReportData] = useState(null);
  // Loading and error states for generating report
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const token = sessionStorage.getItem("accessToken");
  const backendUrl = process.env.REACT_APP_BACKEND_URL || "";

  // Fetch the list of files for the FP_Recon report type.
  const fetchFiles = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/gcs/list-files/FP_Recon`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch files");
      }
      const data = await response.json();
      setFiles(data.files);
    } catch (err) {
      console.error("Error fetching files:", err);
    }
  };

  useEffect(() => {
    if (token) {
      fetchFiles();
    }
  }, [token]);

  // Filter files when file list or selected month changes.
  useEffect(() => {
    const filtered = files.filter((file) =>
      file.toLowerCase().includes(`_${selectedMonth}_`)
    );

    const adp = filtered.filter((file) =>
      file.toLowerCase().includes("fp adp")
    );
    const suntrust = filtered.filter((file) =>
      file.toLowerCase().includes("suntrust")
    );
    setAdpFiles(adp);
    setSuntrustFiles(suntrust);
    setSelectedAdpFile(adp.length > 0 ? adp[0] : "");
    setSelectedSuntrustFile(suntrust.length > 0 ? suntrust[0] : "");
  }, [files, selectedMonth]);

  // Handler to generate the FP Recon report using fetch.
  const handleGenerateReport = async () => {
    if (!selectedAdpFile || !selectedSuntrustFile) {
      alert("Please select both ADP and Suntrust files.");
      return;
    }
    setLoading(true);
    setError("");
    try {
      const response = await fetch(`${backendUrl}/api/fprecon/process`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          adpFileName: selectedAdpFile,
          suntrustFileName: selectedSuntrustFile,
          reportType: "FP_Recon",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to generate FP Recon report");
      }

      const data = await response.json();
      setReportData(data);
    } catch (err) {
      console.error("Error generating report:", err);
      setError(err.message);
      alert(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fp-recon-page">
      {/* <h1>FP Recon Report</h1> */}
      <div className="fp-recon-controls">
        <div className="control-inline">
          <div className="narrow-selector-container">
            <label htmlFor="monthSelect">Select Month:</label>
            <input
              type="month"
              id="monthSelect"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
            />
          </div>
          <div className="narrow-selector-container">
            <label htmlFor="adpSelect">Select ADP File:</label>
            <select
              id="adpSelect"
              value={selectedAdpFile}
              onChange={(e) => setSelectedAdpFile(e.target.value)}
            >
              {adpFiles.length > 0 ? (
                adpFiles.map((file, idx) => (
                  <option key={idx} value={file}>
                    {file}
                  </option>
                ))
              ) : (
                <option value="">No ADP files available</option>
              )}
            </select>
          </div>
          <div className="narrow-selector-container">
            <label htmlFor="suntrustSelect">Select Suntrust File:</label>
            <select
              id="suntrustSelect"
              value={selectedSuntrustFile}
              onChange={(e) => setSelectedSuntrustFile(e.target.value)}
            >
              {suntrustFiles.length > 0 ? (
                suntrustFiles.map((file, idx) => (
                  <option key={idx} value={file}>
                    {file}
                  </option>
                ))
              ) : (
                <option value="">No Suntrust files available</option>
              )}
            </select>
          </div>
        </div>
        <div className="control-group center-button">
          <button onClick={handleGenerateReport}>
            {loading ? "Generating..." : "Generate FP Recon Report"}
          </button>
        </div>
      </div>
      <hr />
      {error && <div className="error-message">{error}</div>}
      {reportData ? (
        // Pass selectedMonth as a prop so FPReconReport.jsx can use it for the file name.
        <FPReconReport reportData={reportData} selectedMonth={selectedMonth} />
      ) : (
        <div>Report not generated yet.</div>
      )}
    </div>
  );
};

export default FPReconPage;
