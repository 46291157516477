import React from 'react';

const HiyaThere = () => {
  return (
    <div>
      <h1>Hiya there!</h1>
    </div>
  );
};

export default HiyaThere;



// import React, { useState, useEffect } from 'react';
// import './FixedLeaders.css'; // Ensure your CSS file is linked

// const FixedLeaders = () => {
//   const [leaders, setLeaders] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchFixedLeaders = async () => {
//       try {
//         // Placeholder API call, replace with your own API endpoint
//         const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/fixed-leaders`);
//         if (!response.ok) {
//           throw new Error("Failed to fetch data");
//         }
//         const data = await response.json();
//         setLeaders(data);
//         setLoading(false);
//       } catch (error) {
//         setError(error.message);
//         setLoading(false);
//       }
//     };

//     fetchFixedLeaders();
//   }, []);

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error}</p>;

//   return (
//     <div className="fixed-leaders-container">
//       <h2>Fixed Operations Leaderboard</h2>
//       <table className="fixed-leaders-table">
//         <thead>
//           <tr>
//             <th>Rank</th>
//             <th>Name</th>
//             <th>Performance Metric</th>
//           </tr>
//         </thead>
//         <tbody>
//           {leaders.map((leader, index) => (
//             <tr key={leader.name}>
//               <td>{index + 1}</td>
//               <td>{leader.name}</td>
//               <td>{leader.performance_metric}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default FixedLeaders;
