// CompPlanVisual.jsx

import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// import './CompPlanVisual.css'; // Create this CSS file for styling

const CompPlanVisual = () => {
    // Sample data: Replace this with real earnings data
    const data = [
        { month: 'Jan', salesManager: 5000, teamLeader: 3000 },
        { month: 'Feb', salesManager: 6000, teamLeader: 3500 },
        { month: 'Mar', salesManager: 5500, teamLeader: 3200 },
        { month: 'Apr', salesManager: 7000, teamLeader: 4000 },
        { month: 'May', salesManager: 6500, teamLeader: 3800 },
        { month: 'Jun', salesManager: 7200, teamLeader: 4200 },
        { month: 'Jul', salesManager: 8000, teamLeader: 4500 },
        { month: 'Aug', salesManager: 7500, teamLeader: 4300 },
        { month: 'Sep', salesManager: 8200, teamLeader: 4600 },
        { month: 'Oct', salesManager: 9000, teamLeader: 5000 },
        { month: 'Nov', salesManager: 8500, teamLeader: 4800 },
        { month: 'Dec', salesManager: 9500, teamLeader: 5200 },
    ];

    return (
        <div className="comp-plan-visual-container">
            <h2>Earnings Over Time</h2>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart
                    data={data}
                    margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="salesManager" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="teamLeader" stroke="#007bff" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default CompPlanVisual;
