// client/src/context/RooftopContext.js

import React, { createContext, useState } from 'react';

export const RooftopContext = createContext();

export const RooftopProvider = ({ children }) => {
  const [currentRooftop, setCurrentRooftop] = useState('');
  const [currentDatasetId, setCurrentDatasetId] = useState('');

  return (
    <RooftopContext.Provider
      value={{
        currentRooftop,
        setCurrentRooftop,
        currentDatasetId,
        setCurrentDatasetId,
      }}
    >
      {children}
    </RooftopContext.Provider>
  );
};
