import React, { useState, useEffect, useRef } from 'react';
import './PersonnelData.css';

const PersonnelData = () => {
  const [personnelData, setPersonnelData] = useState([]);
  const [newRecord, setNewRecord] = useState({
    employee_id: '',
    employee_name_first: '',
    employee_name_last: '',
    role: '',
    title: '',
    department: '',
    status: 'Active',
  });
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  // Reference to the "Edit Record" section so we can scroll into view.
  const editSectionRef = useRef(null);

  // Fetch personnel data on mount
  useEffect(() => {
    const fetchPersonnelData = async () => {
      try {
        const token = sessionStorage.getItem('accessToken');
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/personnel-data`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch personnel data');
        }

        const data = await response.json();
        if (Array.isArray(data)) {
          setPersonnelData(data);
        } else {
          console.error('API returned non-array data:', data);
          setPersonnelData([]);
        }

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setPersonnelData([]);
        setLoading(false);
      }
    };

    fetchPersonnelData();
  }, []);

  // Automatically set newRecord.employee_id to the next available integer
  // whenever personnelData changes (e.g. after fetching or adding a record).
  useEffect(() => {
    if (personnelData && personnelData.length > 0) {
      const maxId = personnelData.reduce((acc, item) => {
        // Safely parse employee_id as integer
        const parsed = parseInt(item.employee_id, 10);
        return !isNaN(parsed) && parsed > acc ? parsed : acc;
      }, 0);
      // Only set if newRecord.employee_id is empty or less than next
      setNewRecord((prev) => ({
        ...prev,
        employee_id: (maxId + 1).toString(),
      }));
    } else {
      // If no data, default new ID to 1
      setNewRecord((prev) => ({
        ...prev,
        employee_id: '1',
      }));
    }
  }, [personnelData]);

  // Sorting logic
  const handleSort = (columnKey) => {
    let direction = 'ascending';
    if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key: columnKey, direction });

    const sortedData = [...personnelData].sort((a, b) => {
      if (a[columnKey] < b[columnKey]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[columnKey] > b[columnKey]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setPersonnelData(sortedData);
  };

  // Distinct roles and titles for the datalists
  const distinctRoles = Array.from(
    new Set(personnelData.map((p) => p.role).filter(Boolean))
  );
  const distinctTitles = Array.from(
    new Set(personnelData.map((p) => p.title).filter(Boolean))
  );

  // Edit record
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setSelectedRecord({
      ...selectedRecord,
      [name]: value,
    });
  };

  // New record
  const handleNewRecordChange = (e) => {
    const { name, value } = e.target;
    setNewRecord({
      ...newRecord,
      [name]: value,
    });
  };

  // Called when user clicks "Edit"
  const handleEditClick = (record) => {
    setSelectedRecord(record);
    // Scroll the edit section into view
    setTimeout(() => {
      editSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  };

  // Update existing record in the DB
  const handleUpdate = async () => {
    try {
      const token = sessionStorage.getItem('accessToken');
      const updatedRecord = {
        ...selectedRecord,
        updated_at: new Date().toISOString(),
      };

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/update-personnel-record`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ updatedRecord }),
      });

      if (!response.ok) {
        throw new Error('Failed to update record');
      }

      alert('Record updated successfully!');
      setSelectedRecord(null); // Clear selected record

      // Optionally, re-fetch or update local state
      // ...
    } catch (err) {
      alert(`Error updating record: ${err.message}`);
    }
  };

  // Add new record
  const handleAddRecord = async () => {
    // Double-check for duplicates, though we're auto-setting next ID
    if (personnelData.some((record) => record.employee_id === newRecord.employee_id)) {
      alert('Employee ID already exists. Please use a unique ID.');
      return;
    }

    try {
      const token = sessionStorage.getItem('accessToken');
      const newRecordWithTimestamp = {
        ...newRecord,
        updated_at: new Date().toISOString(),
      };

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/add-personnel-record`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ newRecord: newRecordWithTimestamp }),
      });

      if (!response.ok) {
        throw new Error('Failed to add new record');
      }

      alert('New record added successfully!');

      // If the backend returns updated personnel data, you can set it. Otherwise just push:
      // In this example, the server might only return { message: 'New record added successfully' }, not an updated dataset
      // So we can do a quick local append or re-fetch:
      setPersonnelData((prevData) => [...prevData, newRecordWithTimestamp]);

      // Reset form
      setNewRecord({
        employee_id: '',
        employee_name_first: '',
        employee_name_last: '',
        role: '',
        title: '',
        department: '',
        status: 'Active',
      });
    } catch (err) {
      alert(`Error adding record: ${err.message}`);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="personnel-data-container">
      <h2>Personnel Data</h2>

      {/* Personnel Table */}
      <table className="personnel-table">
        <thead>
          <tr>
            <th onClick={() => handleSort('employee_id')}>
              Employee ID {sortConfig.key === 'employee_id' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('employee_name_first')}>
              First Name {sortConfig.key === 'employee_name_first' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('employee_name_last')}>
              Last Name {sortConfig.key === 'employee_name_last' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('role')}>
              Role {sortConfig.key === 'role' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('title')}>
              Title {sortConfig.key === 'title' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('department')}>
              Department {sortConfig.key === 'department' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('status')}>
              Status {sortConfig.key === 'status' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('updated_at')}>
              Last Updated {sortConfig.key === 'updated_at' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(personnelData) && personnelData.length > 0 ? (
            personnelData.map((record) => (
              <tr key={record.employee_id}>
                <td>{record.employee_id}</td>
                <td>{record.employee_name_first}</td>
                <td>{record.employee_name_last}</td>
                <td>{record.role}</td>
                <td>{record.title}</td>
                <td>{record.department}</td>
                <td>{record.status}</td>
                <td>{record.updated_at?.value || record.updated_at}</td>
                <td>
                  <button onClick={() => handleEditClick(record)}>Edit</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9">No personnel data available</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Edit selected record */}
      {selectedRecord && (
        <div className="update-section" ref={editSectionRef}>
          <h3>Edit Record</h3>
          <label>
            First Name:
            <input
              type="text"
              name="employee_name_first"
              value={selectedRecord.employee_name_first}
              onChange={handleFieldChange}
            />
          </label>
          <label>
            Last Name:
            <input
              type="text"
              name="employee_name_last"
              value={selectedRecord.employee_name_last}
              onChange={handleFieldChange}
            />
          </label>

          {/* Role with a datalist to allow existing or new values */}
          <label>
            Role:
            <input
              list="roleOptions"
              name="role"
              value={selectedRecord.role}
              onChange={handleFieldChange}
            />
            <datalist id="roleOptions">
              {distinctRoles.map((role) => (
                <option key={role} value={role} />
              ))}
            </datalist>
          </label>

          {/* Title with a datalist */}
          <label>
            Title:
            <input
              list="titleOptions"
              name="title"
              value={selectedRecord.title}
              onChange={handleFieldChange}
            />
            <datalist id="titleOptions">
              {distinctTitles.map((title) => (
                <option key={title} value={title} />
              ))}
            </datalist>
          </label>

          <label>
            Department:
            <select
              name="department"
              value={selectedRecord.department}
              onChange={handleFieldChange}
            >
              <option value="" disabled>Select Department</option>
              <option value="Collision">Collision</option>
              <option value="Customer Service">Customer Service</option>
              <option value="Parts">Parts</option>
              <option value="Sales">Sales</option>
              <option value="Service">Service</option>
              <option value="Other">Other</option>
            </select>
          </label>

          <label>
            Status:
            <input
              type="text"
              name="status"
              value={selectedRecord.status}
              onChange={handleFieldChange}
            />
          </label>

          <button onClick={handleUpdate}>Update</button>
        </div>
      )}

      {/* Add new record */}
      <div className="add-section">
        <h3>Add New Record</h3>
        <label>
          Employee ID:
          <input
            type="number"
            name="employee_id"
            value={newRecord.employee_id}
            onChange={handleNewRecordChange}
            disabled  // prevent user from overriding, if desired
          />
        </label>
        <label>
          First Name:
          <input
            type="text"
            name="employee_name_first"
            value={newRecord.employee_name_first}
            onChange={handleNewRecordChange}
          />
        </label>
        <label>
          Last Name:
          <input
            type="text"
            name="employee_name_last"
            value={newRecord.employee_name_last}
            onChange={handleNewRecordChange}
          />
        </label>

        {/* New record's Role with datalist */}
        <label>
          Role:
          <input
            list="newRoleOptions"
            name="role"
            value={newRecord.role}
            onChange={handleNewRecordChange}
          />
          <datalist id="newRoleOptions">
            {distinctRoles.map((role) => (
              <option key={role} value={role} />
            ))}
          </datalist>
        </label>

        {/* New record's Title with datalist */}
        <label>
          Title:
          <input
            list="newTitleOptions"
            name="title"
            value={newRecord.title}
            onChange={handleNewRecordChange}
          />
          <datalist id="newTitleOptions">
            {distinctTitles.map((title) => (
              <option key={title} value={title} />
            ))}
          </datalist>
        </label>

        <label>
          Department:
          <select
            name="department"
            value={newRecord.department}
            onChange={handleNewRecordChange}
          >
            <option value="" disabled>Select Department</option>
            <option value="Collision">Collision</option>
            <option value="Customer Service">Customer Service</option>
            <option value="Parts">Parts</option>
            <option value="Sales">Sales</option>
            <option value="Service">Service</option>
            <option value="Other">Other</option>
          </select>
        </label>
        <label>
          Status:
          <input
            type="text"
            name="status"
            value={newRecord.status}
            onChange={handleNewRecordChange}
          />
        </label>
        <button onClick={handleAddRecord}>Add Record</button>
      </div>
    </div>
  );
};

export default PersonnelData;
