// src/components/ParticleBackground.js
import React, { useRef, useEffect } from 'react';

const ParticleBackground = ({ animate, fillContainer = false }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Use parent dimensions if fillContainer is true; otherwise use window dimensions.
    const width = fillContainer
      ? canvas.parentElement.offsetWidth
      : window.innerWidth;
    const height = fillContainer
      ? canvas.parentElement.offsetHeight
      : window.innerHeight;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    // ------------- Utility Functions -------------
    function getDistance(p1, p2) {
      return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
    }

    function Circle(pos, rad, color) {
      this.pos = pos;
      this.radius = rad;
      this.color = color;
      this.active = 0;
      this.draw = function (ctx) {
        if (!this.active) return;
        ctx.beginPath();
        ctx.arc(this.pos.x, this.pos.y, this.radius, 0, 2 * Math.PI, false);
        ctx.fillStyle = `rgba(156,217,249,${this.active})`;
        ctx.fill();
      };
    }

    // ------------- Create Points -------------
    let points = [];
    const target = { x: width / 2, y: height / 2 };
    let animationFrameId;

    for (let x = 0; x < width; x += width / 20) {
      for (let y = 0; y < height; y += height / 20) {
        const px = x + Math.random() * (width / 20);
        const py = y + Math.random() * (height / 20);
        points.push({ x: px, originX: px, y: py, originY: py, active: 0 });
      }
    }

    // For each point, find up to 5 closest points
    points.forEach((p1) => {
      let closest = [];
      points.forEach((p2) => {
        if (p1 === p2) return;
        if (closest.length < 5) {
          closest.push(p2);
        } else {
          for (let k = 0; k < 5; k++) {
            if (getDistance(p1, p2) < getDistance(p1, closest[k])) {
              closest[k] = p2;
              break;
            }
          }
        }
      });
      p1.closest = closest;
      p1.circle = new Circle(p1, 2 + Math.random() * 2, 'rgba(255,255,255,0.3)');
    });

    // ------------- Animation Loop -------------
    const animatePoints = () => {
      if (!animate) return;
      ctx.clearRect(0, 0, width, height);
      points.forEach(p => {
        const d = getDistance(target, p);
        if (d < 4000) {
          p.active = 0.3;
          p.circle.active = 0.6;
        } else if (d < 20000) {
          p.active = 0.1;
          p.circle.active = 0.3;
        } else if (d < 40000) {
          p.active = 0.02;
          p.circle.active = 0.1;
        } else {
          p.active = 0;
          p.circle.active = 0;
        }
        drawLines(p);
        p.circle.draw(ctx);
      });
      animationFrameId = requestAnimationFrame(animatePoints);
    };

    const drawLines = (p) => {
      if (!p.active) return;
      p.closest.forEach(cp => {
        ctx.beginPath();
        ctx.moveTo(p.x, p.y);
        ctx.lineTo(cp.x, cp.y);
        ctx.strokeStyle = `rgba(156,217,249,${p.active})`;
        ctx.stroke();
      });
    };

    if (animate) {
      animatePoints();
    }

    // ------------- Event Listeners -------------
    const onMouseMove = (e) => {
      target.x = e.clientX;
      target.y = e.clientY;
    };
    window.addEventListener('mousemove', onMouseMove);

    const handleResize = () => {
      const newWidth = fillContainer
        ? canvas.parentElement.offsetWidth
        : window.innerWidth;
      const newHeight = fillContainer
        ? canvas.parentElement.offsetHeight
        : window.innerHeight;
      canvas.width = newWidth;
      canvas.height = newHeight;
    };
    window.addEventListener('resize', handleResize);

    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('resize', handleResize);
    };
  }, [animate, fillContainer]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: fillContainer ? 'absolute' : 'fixed',
        top: 0,
        left: 0,
        width: fillContainer ? '100%' : '100vw',
        height: fillContainer ? '100%' : '100vh',
        pointerEvents: 'none',
        zIndex: 0,
      }}
    />
  );
};

export default ParticleBackground;
