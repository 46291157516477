// src/pages/PerformancePlanData.js

import React, { useState, useRef } from 'react';
import './PerformancePlanData.css'; // Ensure this file exists for styling
import { v4 as uuidv4 } from 'uuid'; // For generating unique IDs

const PerformancePlanData = () => {
  const currentYear = new Date().getFullYear();
  const formRef = useRef(null);

  // State for managing performance plan entries – start with an empty array.
  const [entries, setEntries] = useState([]);

  // State for feedback messages
  const [message, setMessage] = useState('');

  // State variables for fetching existing data
  const [fetchMonth, setFetchMonth] = useState('');
  const [fetchYear, setFetchYear] = useState(currentYear.toString());
  const [saleType, setSaleType] = useState('all');

  // State to toggle the visibility of the fetch section (hidden by default)
  const [showFetchSection, setShowFetchSection] = useState(false);

  // State to store fetched data for display in the table
  const [fetchedData, setFetchedData] = useState([]);

  // State flag to indicate that the user has pressed the fetch button.
  const [hasFetched, setHasFetched] = useState(false);

  // Predefined options for dropdowns
  const classOptions = [
    { value: '', label: 'Select Class' },
    { value: 'actual', label: 'Actual' },
    { value: 'objective', label: 'Objective' },
  ];

  const segmentOptions = [
    { value: '', label: 'Select Segment' },
    { value: 'sales', label: 'Sales' },
    { value: 'service', label: 'Service' },
  ];

  const itemOptions = [
    { value: '', label: 'Select Item' },
    { value: 'new units sold', label: 'New Units Sold' },
    { value: 'used units sold', label: 'Used Units Sold' },
    { value: 'new back gross profit', label: 'New Back Gross Profit' },
    { value: 'new front gross profit', label: 'New Front Gross Profit' },
    { value: 'used back gross profit', label: 'Used Back Gross Profit' },
    { value: 'used front gross profit', label: 'Used Front Gross Profit' },
    { value: 'commissionable gross', label: 'Commissionable Gross' },
    { value: 'net profit', label: 'Net Profit' },
    { value: 'reputation score', label: 'Reputation.com Score' },
    { value: 'sales efficiency', label: 'Sales Efficiency' },
    { value: 'one source penetration', label: 'One Source Penetration' },
    { value: 'esa penetration', label: 'ESA Penetration' },
    { value: 'maintenance agreement penetration', label: 'Maintenance Agreement Penetration' },
    { value: 'ancillary products penetration', label: 'Ancillary Products Penetration' },
    { value: 'new front gross average', label: 'New Front-End Gross Average' },
    { value: 'used front gross average', label: 'Used Front-End Gross Average' },
    { value: 'accessory sales', label: 'Accessory Sales' },
    { value: 'finance pru average', label: 'Finance Per Retail Unit Average' },
    { value: 'fleet count', label: 'Fleet Count' },
  ];

  // Keep "Select Item" at the top and sort the rest alphabetically.
  const sortedItemOptions = [
    itemOptions[0],
    ...itemOptions.slice(1).sort((a, b) => a.label.localeCompare(b.label)),
  ];

  const monthOptions = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];

  const yearOptions = Array.from({ length: 10 }, (_, i) => ({
    value: (currentYear - i).toString(),
    label: (currentYear - i).toString(),
  }));

  // Build expected records.
  // For items that can have both actual and objective values, add two expected records.
  // For the rest, add a single expected record.
  const expectedRecords = [];
  sortedItemOptions.forEach((option) => {
    if (!option.value) return;
    // Exclude these items from being displayed in the fetched table.
    const hiddenItems = [
      'new back gross profit',
      'new front gross profit',
      'used back gross profit',
      'used front gross profit',
    ];
    if (hiddenItems.includes(option.value.toLowerCase())) return;

    if (
      option.value.toLowerCase() === 'new units sold' ||
      option.value.toLowerCase() === 'used units sold'
    ) {
      expectedRecords.push({ item: option.value, class: 'actual' });
      // For objective, default segment is "sales"
      expectedRecords.push({ item: option.value, class: 'objective', segment: 'sales' });
    } else {
      expectedRecords.push({ item: option.value });
    }
  });

  // File import variables – the file-import items.
  const fileImportVariables = [
    'commissionable gross',
    'used units sold [actual]',
    'new front gross average',
    'used front gross average',
    'finance pru average',
    'esa penetration',
    'maintenance agreement penetration',
    'ancillary products penetration',
  ];

  // Alias mapping: remap numerator variants to expected names.
  const aliasMapping = {
    'maintenance agreement penetration numerator': 'maintenance agreement penetration',
    'ancillary products penetration numerator': 'ancillary products penetration',
    'esa penetration numerator': 'esa penetration',
    'finance pru average numerator': 'finance pru average',
    'finance pro average numerator': 'finance pru average',
  };

  const percentageItems = ['one source penetration', 'sales efficiency'];
  const currencyItems = [
    'accessory sales',
    'new front gross average',
    'used front gross average',
    'commissionable gross',
    'net profit',
  ];

  const formatMetric = (entry) => {
    const item = entry.item.toLowerCase();
    if (item === 'reputation score') {
      return entry.metric.toString().toLowerCase() === 'yes' ? 'Yes' : 'No';
    } else if (percentageItems.includes(item)) {
      const num = parseFloat(entry.metric);
      if (isNaN(num)) return entry.metric;
      return (num * 100).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }) + '%';
    } else if (currencyItems.includes(item)) {
      const num = parseFloat(entry.metric);
      if (isNaN(num)) return entry.metric;
      return num.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      });
    } else {
      return entry.metric;
    }
  };

  // Build displayed records by combining expectedRecords with fetchedData.
  // For file-import variables, if a matching fetched record is found, do not include that expected record.
  const displayedRecords =
    hasFetched && fetchMonth && fetchYear
      ? expectedRecords.reduce((acc, expected) => {
          const found = fetchedData.find((entry) => {
            let fetchedItem = entry.item.toLowerCase();
            if (aliasMapping[fetchedItem]) {
              fetchedItem = aliasMapping[fetchedItem];
            }
            const expectedItem = expected.item.toLowerCase();
            if (expected.class) {
              return (
                fetchedItem === expectedItem &&
                entry.class.toLowerCase() === expected.class
              );
            } else {
              return fetchedItem === expectedItem;
            }
          });
          // For file-import variables, if found, don't add to the display.
          if (
            fileImportVariables.includes(expected.item.toLowerCase()) &&
            found
          ) {
            return acc;
          }
          if (found) {
            acc.push({ ...found, status: 'Submitted' });
          } else {
            const status = fileImportVariables.includes(expected.item.toLowerCase())
              ? 'Missing - Please import Financial Statement file'
              : 'Missing';
            acc.push({
              localId: uuidv4(),
              id: '',
              class: expected.class || '',
              // Use expected.segment if provided; otherwise, empty.
              segment: expected.segment || '',
              item: expected.item,
              metric: '',
              month: fetchMonth,
              year: fetchYear,
              status: status,
            });
          }
          return acc;
        }, [])
      : [];

  // Count missing file-import variables.
  const missingFinancialCount = displayedRecords.filter(
    (record) =>
      fileImportVariables.includes(record.item.toLowerCase()) &&
      record.status.startsWith('Missing')
  ).length;

  // Handler for form field changes.
  const handleChange = (localId, e) => {
    const { name, value } = e.target;
    setEntries((prevEntries) =>
      prevEntries.map((entry) =>
        entry.localId === localId
          ? entry.item.toLowerCase() === 'reputation score' && name === 'metric'
            ? { ...entry, [name]: value.toLowerCase() }
            : { ...entry, [name]: value }
          : entry
      )
    );
  };

  // Add a new empty entry.
  const addEntry = () => {
    setEntries((prev) => [
      ...prev,
      {
        localId: uuidv4(),
        id: '',
        class: '',
        segment: '',
        item: '',
        metric: '',
        month: '01',
        year: currentYear.toString(),
      },
    ]);
  };

  // Remove an entry.
  const removeEntry = (localId) => {
    setEntries((prev) => prev.filter((entry) => entry.localId !== localId));
  };

  // Clear the form.
  const clearForm = () => {
    setEntries([]);
    setFetchedData([]);
    setHasFetched(false);
    setMessage('Form has been cleared.');
  };

  // Fetch performance data.
  const fetchPerformanceData = async () => {
    if (!fetchMonth || !fetchYear) {
      setMessage('Please select both month and year to fetch data.');
      return;
    }
    try {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        setMessage('User not authenticated. Please log in.');
        return;
      }
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/performance-plan?month=${fetchMonth}&year=${fetchYear}&saleType=${saleType}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch performance data.');
      }
      const result = await response.json();
      const transformedEntries = result.map((item) => {
        let transformedMetric;
        let itemLower = item.item.toLowerCase();
        if (aliasMapping[itemLower]) {
          itemLower = aliasMapping[itemLower];
        }
        if (itemLower === 'reputation score') {
          transformedMetric = parseFloat(item.metric) === 1 ? 'yes' : 'no';
        } else if (percentageItems.includes(itemLower)) {
          transformedMetric = parseFloat(item.metric);
        } else {
          transformedMetric = item.metric || '';
        }
        return {
          localId: uuidv4(),
          id: item.id || '',
          class: item.class || '',
          segment: item.segment || '',
          item: itemLower,
          metric: transformedMetric,
          month: fetchMonth,
          year: fetchYear.toString(),
        };
      });
      setFetchedData(transformedEntries);
      setHasFetched(true);
      setMessage(
        `Fetched ${transformedEntries.length} entries for ${monthOptions.find(
          (m) => m.value === fetchMonth
        )?.label}/${fetchYear}.`
      );
    } catch (error) {
      console.error('Error fetching performance data:', error);
      setMessage(`Error: ${error.message}`);
    }
  };

  // When a record is selected from the fetched table, append it to the form if not already present.
  const handleSelectRecord = (record) => {
    // Check if the record is already in entries (match on item and class if applicable)
    const alreadyExists = entries.some(
      (entry) =>
        entry.item.toLowerCase() === record.item.toLowerCase() &&
        (!record.class || entry.class.toLowerCase() === record.class.toLowerCase())
    );
    if (alreadyExists) {
      setMessage(`Record for ${record.item} (${record.class || 'N/A'}) is already added.`);
      return;
    }
    const newEntry = {
      localId: uuidv4(),
      id: record.id || '',
      class: record.class || '',
      // If class is objective and segment is not provided, default segment to 'sales'
      segment: record.segment || (record.class.toLowerCase() === 'objective' ? 'sales' : ''),
      item: record.item || '',
      metric: record.metric || '',
      month: record.month || '01',
      year: record.year.toString(),
    };
    setEntries((prev) => [...prev, newEntry]);
    setMessage(`Selected ${record.status} record for item: ${record.item}`);
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // When adding missing data, append missing entries to the form while avoiding duplicates.
  const handleAddMissingData = () => {
    const missingRecords = displayedRecords.filter((record) =>
      record.status.startsWith('Missing')
    );
    setEntries((prev) => {
      const existingItems = prev.map((e) => e.item.toLowerCase());
      const newMissing = missingRecords
        .filter((record) => !existingItems.includes(record.item.toLowerCase()))
        .map((record) => ({
          localId: uuidv4(),
          id: '',
          // Use the record's class if available; if class is objective, set segment to 'sales'
          class: record.class || 'actual',
          segment: record.class && record.class.toLowerCase() === 'objective' ? 'sales' : 'sales',
          item: record.item,
          metric: '',
          month: record.month,
          year: record.year.toString(),
        }));
      return [...prev, ...newMissing];
    });
    setMessage('Missing data entries added.');
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Handle form submission.
  const handleSubmit = async (e) => {
    e.preventDefault();
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      const missingFields = [];
      if (!entry.class) missingFields.push('Class');
      if (!entry.segment) missingFields.push('Segment');
      if (!entry.item) missingFields.push('Item');
      if (entry.metric === '' || entry.metric === null || entry.metric === undefined)
        missingFields.push('Metric');
      if (!entry.month) missingFields.push('Month');
      if (!entry.year) missingFields.push('Year');
      if (missingFields.length > 0) {
        setMessage(
          `Please fill in the following fields for Entry ${i + 1}: ${missingFields.join(', ')}`
        );
        return;
      }
    }
    try {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        setMessage('User not authenticated. Please log in.');
        return;
      }
      const entriesToInsert = [];
      const entriesToUpdate = [];
      entries.forEach((entry) => {
        let metricValue = entry.metric;
        const itemValue = entry.item.toLowerCase();
        if (itemValue === 'reputation score') {
          if (typeof metricValue === 'string') {
            const lowerMetric = metricValue.toLowerCase();
            if (lowerMetric === 'yes' || lowerMetric === 'y') {
              metricValue = 1;
            } else if (lowerMetric === 'no' || lowerMetric === 'n') {
              metricValue = 0;
            } else {
              metricValue = null;
            }
          }
        } else if (percentageItems.includes(itemValue)) {
          const parsedMetric = parseFloat(metricValue);
          if (isNaN(parsedMetric)) {
            metricValue = null;
          } else {
            metricValue = parseFloat((parsedMetric / 100).toFixed(2));
          }
        } else {
          metricValue = parseFloat(metricValue);
          if (isNaN(metricValue)) {
            metricValue = null;
          }
        }
        if (metricValue === null) {
          setMessage(`Invalid metric value for item: ${entry.item}`);
          throw new Error(`Invalid metric value for item: ${entry.item}`);
        }
        const formattedEntry = {
          class: entry.class,
          segment: entry.segment,
          item: entry.item,
          metric: metricValue,
          expected_date: `${entry.year}-${entry.month}-01`,
        };
        if (entry.id && entry.id !== '') {
          entriesToUpdate.push({ id: entry.id, ...formattedEntry });
        } else {
          entriesToInsert.push(formattedEntry);
        }
      });
      const allPromises = [];
      if (entriesToInsert.length > 0) {
        allPromises.push(
          fetch(`${process.env.REACT_APP_BACKEND_URL}/api/perf-plan`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(entriesToInsert),
          }).then((response) => response.json())
        );
      }
      entriesToUpdate.forEach((entryToUpdate) => {
        allPromises.push(
          fetch(`${process.env.REACT_APP_BACKEND_URL}/api/perf-plan/${entryToUpdate.id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
              class: entryToUpdate.class,
              segment: entryToUpdate.segment,
              item: entryToUpdate.item,
              metric: entryToUpdate.metric,
              expected_date: entryToUpdate.expected_date,
            }),
          }).then((response) => response.json())
        );
      });
      if (allPromises.length === 0) {
        setMessage('No changes to submit.');
        return;
      }
      const results = await Promise.all(allPromises);
      let successCount = 0;
      let errorMessages = [];
      results.forEach((result) => {
        if (result.message) {
          successCount += 1;
        } else if (result.error) {
          errorMessages.push(result.error);
        }
      });
      if (successCount > 0) {
        setMessage(`Successfully processed ${successCount} entries.`);
        // <---- Rerun fetchPerformanceData to refresh the table
        if (fetchMonth && fetchYear) {
          fetchPerformanceData();
        }
      }
  
      if (errorMessages.length > 0) {
        setMessage((prev) =>
          prev
            ? `${prev} Errors: ${errorMessages.join('; ')}`
            : `Errors: ${errorMessages.join('; ')}`
        );
      }
      setEntries([]);
    } catch (error) {
      console.error('Error submitting data:', error);
      if (!message) {
        setMessage('An error occurred while submitting data.');
      }
    }
  };

  return (
    <div className="performance-plan-container">
      {/* Toggle Button for Fetch Data Section */}
      <button
        type="button"
        onClick={() => setShowFetchSection(!showFetchSection)}
        className="toggle-fetch-button"
      >
        {showFetchSection ? 'Hide Fetch Section' : 'Show Search'}
      </button>

      {/* Fetch Existing Data Section */}
      {showFetchSection && (
        <div className="fetch-data-section">
          <h2>Retrieve Existing Performance Plan Data</h2>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="fetch-month">Month:</label>
              <select
                id="fetch-month"
                value={fetchMonth}
                onChange={(e) => setFetchMonth(e.target.value)}
              >
                <option value="">Select Month</option>
                {monthOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="fetch-year">Year:</label>
              <select
                id="fetch-year"
                value={fetchYear}
                onChange={(e) => setFetchYear(e.target.value)}
              >
                <option value="">Select Year</option>
                {yearOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="saleType">Sale Type:</label>
              <select
                id="saleType"
                value={saleType}
                onChange={(e) => setSaleType(e.target.value)}
              >
                <option value="all">All</option>
                <option value="new">New</option>
                <option value="used">Used</option>
              </select>
            </div>
          </div>
          <div className="fetch-buttons">
            <button type="button" onClick={fetchPerformanceData} className="get-button">
              Fetch Performance Data
            </button>
            <button type="button" onClick={clearForm} className="clear-button">
              Clear Form
            </button>
          </div>
        </div>
      )}

      {/* Display Fetched Data Table */}
      {hasFetched && fetchMonth && fetchYear && (
        <div className="fetched-data-table">
          <h2>
            Performance Plan Data for {monthOptions.find((m) => m.value === fetchMonth)?.label}/{fetchYear}
          </h2>
          <table>
            <thead>
              <tr>
                <th>Class</th>
                <th>Segment</th>
                <th>Item</th>
                <th>Metric</th>
                <th>Month</th>
                <th>Year</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {displayedRecords.map((record) => (
                <tr
                  key={record.localId}
                  onClick={() => handleSelectRecord(record)}
                  className="table-row"
                >
                  <td>{record.class || '-'}</td>
                  <td>{record.segment || '-'}</td>
                  <td>{record.item}</td>
                  <td>{record.metric ? formatMetric(record) : '-'}</td>
                  <td>{monthOptions.find((m) => m.value === record.month)?.label || record.month}</td>
                  <td>{record.year}</td>
                  <td>
                    {record.status.startsWith('Submitted') ? (
                      <span className="status-submitted">✔️</span>
                    ) : (
                      <span className="status-missing">❌</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <p className="note">
            * Click on a row to add the record to the form for editing. For missing records, Class and Segment default to Actual/Sales.
          </p>
          {/* Add Missing Data Button */}
          <button type="button" onClick={handleAddMissingData} className="add-missing-button">
            Add Missing Data
          </button>
          {/* Financial Statement Prompt */}
          {missingFinancialCount > 0 && (
            <div className="import-prompt">
              <p>
                Please submit the Financial Statement file to import the missing file data
                variables.
              </p>
            </div>
          )}
        </div>
      )}

      {/* Submission Form (Always Visible) */}
      <div ref={formRef}>
        <form onSubmit={handleSubmit} className="performance-plan-form">
          <h2>Submit Performance Plan Entries</h2>
          {entries.map((entry, index) => (
            <div key={entry.localId} className="entry-group">
              {entry.id && <input type="hidden" name="id" value={entry.id} />}
              <h3>Entry {index + 1}</h3>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor={`class-${entry.localId}`}>Class:</label>
                  <select
                    id={`class-${entry.localId}`}
                    name="class"
                    value={entry.class}
                    onChange={(e) => handleChange(entry.localId, e)}
                    required
                  >
                    {classOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor={`segment-${entry.localId}`}>Segment:</label>
                  <select
                    id={`segment-${entry.localId}`}
                    name="segment"
                    value={entry.segment}
                    onChange={(e) => handleChange(entry.localId, e)}
                    required
                  >
                    {segmentOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor={`item-${entry.localId}`}>Item:</label>
                  <select
                    id={`item-${entry.localId}`}
                    name="item"
                    value={entry.item}
                    onChange={(e) => handleChange(entry.localId, e)}
                    required
                  >
                    {sortedItemOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor={`metric-${entry.localId}`}>Metric:</label>
                  {entry.item.toLowerCase() === 'reputation score' ? (
                    <select
                      id={`metric-${entry.localId}`}
                      name="metric"
                      value={
                        entry.metric === 'yes'
                          ? 'yes'
                          : entry.metric === 'no'
                          ? 'no'
                          : ''
                      }
                      onChange={(e) => handleChange(entry.localId, e)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  ) : (
                    <input
                      type="number"
                      id={`metric-${entry.localId}`}
                      name="metric"
                      value={entry.metric}
                      onChange={(e) => handleChange(entry.localId, e)}
                      required
                      placeholder={
                        percentageItems.includes(entry.item.toLowerCase())
                          ? 'Enter as percentage (e.g., 84)'
                          : 'e.g., 120.00'
                      }
                      step="0.01"
                      min="0"
                    />
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor={`month-${entry.localId}`}>Month:</label>
                  <select
                    id={`month-${entry.localId}`}
                    name="month"
                    value={entry.month}
                    onChange={(e) => handleChange(entry.localId, e)}
                    required
                  >
                    {monthOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor={`year-${entry.localId}`}>Year:</label>
                  <select
                    id={`year-${entry.localId}`}
                    name="year"
                    value={entry.year}
                    onChange={(e) => handleChange(entry.localId, e)}
                    required
                  >
                    <option value="">Select Year</option>
                    {yearOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {entries.length > 1 && (
                <button
                  type="button"
                  onClick={() => removeEntry(entry.localId)}
                  className="remove-button"
                >
                  Remove Entry
                </button>
              )}
              <hr />
            </div>
          ))}
          <div className="button-row">
            <button type="button" onClick={addEntry} className="add-button">
              Create New Entry
            </button>
            <button type="submit" className="submit-button">
              Submit Entries
            </button>
            </div>
        </form>
      </div>
      {message && <p className="message">{message}</p>}
    </div>
  );
};

export default PerformancePlanData;
