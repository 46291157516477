import React, { useState, useEffect } from "react";
import "./InventoryForm.css";

const InventoryForm = () => {
  const [formData, setFormData] = useState({
    stock_in_date: '',
    stock_number: '',
    vin_number: '',
    sale_type: '',
    marketed_status: '',
    inventory_cost: '',
    sale_price: '',
    internet_price: '',
    make: '',
    model: '',
    model_year: '',
    mileage: '',
    vehicle_type: '',
    plant_country: '',
    fuel_type: '',
    electrification_level: '',
    submitted_at: '',
    status: ''
  });

  const [fetchedData, setFetchedData] = useState(null);
  const [modelYears, setModelYears] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [searchId, setSearchId] = useState('');
  const [searchType, setSearchType] = useState('stock_number');
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [showFetchedData, setShowFetchedData] = useState(false);

  // Full list of vehicle makes (alphabetically sorted)
  // Note: No filtering by sale_type anymore
  const vehicleMakes = [
    "Audi", "BMW", "Buick", "Cadillac", "Chevrolet", "Chrysler", "Dodge", "Ford",
    "GMC", "Honda", "Hyundai", "Jeep", "Kia", "Lexus", "Mazda", "Mercedes-Benz",
    "Nissan", "Subaru", "Toyota", "Volkswagen"
  ].sort();

  // ------------------------------------------
  // 1) Searching your DB by stock_number or VIN
  // ------------------------------------------
  const handleSearch = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/fetch-record-by-id?${searchType}=${searchId}`;
      console.log('Requesting:', url);
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setFetchedData(data[0] || {});
        setIsDataFetched(true);
        setShowFetchedData(true);
      } else {
        console.error("Failed to fetch data by ID");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // ------------------------------------------
  // 2) Decode VIN via vPIC (NHTSA) API
  // ------------------------------------------
  const decodeVin = async (vin) => {
    try {
      const response = await fetch(
        `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinValuesExtended/${vin}?format=json`
      );
      const data = await response.json();

      if (data.Results && data.Results.length > 0) {
        const result = data.Results[0];
        
        // Convert `Make` (e.g. "TOYOTA") to "Toyota" so it matches your dropdown list
        const capitalize = (str) => {
          if (!str) return "";
          // A simple approach to capitalize only the first letter:
          // return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
          // Or, if you want to handle multi-word brand names (e.g., "MERCEDEs-bENZ"), you could get fancier.
          return str[0].toUpperCase() + str.substring(1).toLowerCase();
        };

        const capitalizedMake = capitalize(result.Make);

        setFormData((prev) => ({
          ...prev,
          make: capitalizedMake || "",
          model: result.Model || "",
          model_year: result.ModelYear || "",
          vehicle_type: result.VehicleType || "",
          plant_country: result.PlantCountry || "",
          fuel_type: result.FuelTypePrimary || "",
          electrification_level: result.EVDriveUnit || "",
        }));
      }
    } catch (error) {
      console.error("Error decoding VIN:", error);
    }
  };

  // ------------------------------------------
  // 3) Currency / mileage formatting
  // ------------------------------------------
  const formatCurrency = (value) => {
    if (!value && value !== 0) return 'N/A';
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  const formatMileage = (value) => {
    if (!value && value !== 0) return 'N/A';
    return new Intl.NumberFormat('en-US').format(value);
  };

  // ------------------------------------------
  // 4) Model year dropdown
  // ------------------------------------------
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 42 }, (_, i) => currentYear + 2 - i);
    setModelYears(years);
  }, []);

  // ------------------------------------------
  // 5) Handle VIN changes
  //    - If user clears VIN => reset
  //    - If user sets VIN => decode onBlur
  // ------------------------------------------
  const handleVinChange = (e) => {
    const newVin = e.target.value;
    setFormData((prev) => ({
      ...prev,
      vin_number: newVin
    }));

    // If user empties VIN, reset the dependent fields
    if (!newVin) {
      setFormData((prev) => ({
        ...prev,
        make: "",
        model: "",
        model_year: "",
        vehicle_type: "",
        plant_country: "",
        fuel_type: "",
        electrification_level: ""
      }));
    }
  };

  // onBlur => decode if >= 17 chars
  const handleVinBlur = () => {
    if (formData.vin_number.length >= 17) {
      decodeVin(formData.vin_number);
    }
  };

  // Generic change for other fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  // ------------------------------------------
  // 6) Submit the form
  // ------------------------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('accessToken');
    if (!token) {
      console.error('No token found');
      return;
    }

    const currentTimestamp = new Date().toISOString();

    const updatedFormData = {
      ...formData,
      submitted_at: currentTimestamp,
      status: "available"
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/submit-inventory`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ formData: updatedFormData }),
      });

      if (response.ok) {
        console.log("Data inserted successfully");
        // Reset the form
        setFormData({
          stock_in_date: '',
          stock_number: '',
          vin_number: '',
          sale_type: '',
          marketed_status: '',
          inventory_cost: '',
          sale_price: '',
          internet_price: '',
          make: '',
          model: '',
          model_year: '',
          mileage: '',
          vehicle_type: '',
          plant_country: '',
          fuel_type: '',
          electrification_level: '',
          submitted_at: '',
          status: ''
        });
        setIsDataFetched(false);
      } else {
        console.error("Failed to insert data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // ------------------------------------------
  // 7) Populate form from fetchedData
  // ------------------------------------------
  const populateForm = () => {
    if (fetchedData) {
      setFormData((prev) => ({
        ...prev,
        stock_in_date: fetchedData.stock_in_date?.value || '',
        stock_number: fetchedData.stock_number || '',
        vin_number: fetchedData.vin_number || '',
        sale_type: fetchedData.sale_type || '',
        marketed_status: fetchedData.marketed_status || '',
        inventory_cost: fetchedData.inventory_cost || '',
        sale_price: fetchedData.sale_price || '',
        internet_price: fetchedData.internet_price || '',
        make: fetchedData.make || '',
        model: fetchedData.model || '',
        model_year: fetchedData.model_year || '',
        mileage: fetchedData.mileage || '',
        // If your DB also stores these hidden fields, populate them if needed:
        // vehicle_type: fetchedData.vehicle_type || '',
        // plant_country: fetchedData.plant_country || '',
        // fuel_type: fetchedData.fuel_type || '',
        // electrification_level: fetchedData.electrification_level || '',
      }));
      setIsDataFetched(false);
    }
  };

  // ------------------------------------------
  // Render
  // ------------------------------------------
  return (
    <div className="inventory-form-container">
      <button className="centered-button" onClick={() => setShowSearch(!showSearch)}>
        {showSearch ? "Hide Search" : "Show Search"}
      </button>

      {showSearch && (
        <div className="search-section centered">
          <h3>Search Record by ID</h3>
          <select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
            <option value="stock_number">Stock Number</option>
            <option value="vin_number">VIN</option>
          </select>
          <input
            type="text"
            placeholder={`Enter ${searchType}`}
            value={searchId}
            onChange={(e) => setSearchId(e.target.value)}
          />
          <button className="centered-button" onClick={handleSearch}>Search</button>
        </div>
      )}

      <div className="form-data-section">
        {showFetchedData && (
          <div className="fetched-data centered">
            <h3>Fetched Data:</h3>
            <p>Date Stocked In: {fetchedData.stock_in_date?.value || 'N/A'}</p>
            <p>Stock Number: {fetchedData.stock_number || 'N/A'}</p>
            <p>VIN: {fetchedData.vin_number || 'N/A'}</p>
            <p>Sale Type: {fetchedData.sale_type || 'N/A'}</p>
            <p>Marketed Status: {fetchedData.marketed_status || 'N/A'}</p>
            <p>Cost: {formatCurrency(fetchedData.inventory_cost)}</p>
            <p>Sale Price: {formatCurrency(fetchedData.sale_price)}</p>
            <p>Internet Price: {formatCurrency(fetchedData.internet_price)}</p>
            <p>Make: {fetchedData.make || 'N/A'}</p>
            <p>Model: {fetchedData.model || 'N/A'}</p>
            <p>Model Year: {fetchedData.model_year || 'N/A'}</p>
            <p>Mileage: {formatMileage(fetchedData.mileage)}</p>
            <button className="centered-button" onClick={populateForm}>
              Populate Form with Fetched Data
            </button>
            <button className="centered-button" onClick={() => setShowFetchedData(false)}>
              Hide Fetched Data
            </button>
          </div>
        )}

        <form onSubmit={handleSubmit} className="inventory-form">
          <div className="form-header-container">
            <h2 className="form-header">Inventory</h2>
            <h3 className="form-header">add or update</h3>
          </div>

          <label>
            Date Stocked In:
            <input
              type="date"
              name="stock_in_date"
              value={formData.stock_in_date}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Stock Number:
            <input
              type="text"
              name="stock_number"
              value={formData.stock_number}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            VIN:
            <input
              type="text"
              name="vin_number"
              value={formData.vin_number}
              onChange={handleVinChange}
              onBlur={handleVinBlur}
              required
            />
          </label>

          <label>
            Sale Type:
            <select
              name="sale_type"
              value={formData.sale_type}
              onChange={handleChange}
              required
            >
              <option value="" disabled>Select New or Used</option>
              <option value="New">New</option>
              <option value="Used">Used</option>
            </select>
          </label>

          <label>
            Marketed Status:
            <select
              name="marketed_status"
              value={formData.marketed_status}
              onChange={handleChange}
            >
              <option value="">Select Status</option>
              <option value="Retail">Retail</option>
              <option value="Commercial">Commercial</option>
              <option value="Wholesale">Wholesale</option>
            </select>
          </label>

          <label>
            Cost:
            <input
              type="number"
              name="inventory_cost"
              value={formData.inventory_cost}
              onChange={handleChange}
            />
          </label>

          <label>
            Sale Price:
            <input
              type="number"
              name="sale_price"
              value={formData.sale_price}
              onChange={handleChange}
            />
          </label>

          <label>
            Internet Price:
            <input
              type="number"
              name="internet_price"
              value={formData.internet_price}
              onChange={handleChange}
            />
          </label>

          <label>
            Vehicle Make:
            <select
              name="make"
              value={formData.make}
              onChange={handleChange}
            >
              <option value="" disabled>Select Make</option>
              {vehicleMakes.map((mk) => (
                <option key={mk} value={mk}>
                  {mk}
                </option>
              ))}
            </select>
          </label>

          <label>
            Vehicle Model:
            <input
              type="text"
              name="model"
              value={formData.model}
              onChange={handleChange}
            />
          </label>

          <label>
            Model Year:
            <select
              name="model_year"
              value={formData.model_year}
              onChange={handleChange}
            >
              <option value="" disabled>Select Model Year</option>
              {modelYears.map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </label>

          <label>
            Mileage:
            <input
              type="number"
              name="mileage"
              value={formData.mileage}
              onChange={handleChange}
            />
          </label>

          {/* Hidden fields (not displayed) but still stored in formData & sent to backend:
              vehicle_type, plant_country, fuel_type, electrification_level
          */}

          <button type="submit" className="centered-button">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default InventoryForm;
