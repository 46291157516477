import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";

const ImportData = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // New state: Allows the user to manually override the expected_date.
  // Defaults to one month before current month.
  const getExpectedDate = () => {
    const now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth(); // 0-indexed; if January (0), previous month is December of previous year
    if (month === 0) {
      year -= 1;
      month = 12;
    }
    return `${year}-${month.toString().padStart(2, "0")}-01`;
  };
  const [customExpectedDate, setCustomExpectedDate] = useState(getExpectedDate());

  // Helper function: round a number to two decimal places.
  const roundToTwo = (num) => parseFloat(Number(num).toFixed(2));

  // Define onDrop function before using it
  const onDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const selectedFile = acceptedFiles[0];
      setFile(selectedFile);
      setMessage(`File selected: ${selectedFile.name}`);
    }
  };

  // React-Dropzone using a simple comma-separated string for accept
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".csv,.xlsx,.xls,.xlsm",
    multiple: false,
  });

  // Fallback for manual file selection
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      setMessage(`File selected: ${selectedFile.name}`);
    }
  };

  // Function to process the Excel file and perform calculations
  const processExcelFile = async (file) => {
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: "array" });

      // Extract values from relevant worksheets
      const page2 = workbook.Sheets["Page 2"] || {};
      const page4 = workbook.Sheets["Page 4"] || {};
      const page5 = workbook.Sheets["Page 5"] || {};
      const page6 = workbook.Sheets["Page 6"] || {};
      const page7 = workbook.Sheets["Page 7"] || {};

      // Commissionable Gross = 'Page 2'!J5 + 'Page 2'!L5 + 'Page 4'!E31
      const cellJ5 = page2["J5"] ? page2["J5"].v : 0;
      const cellL5 = page2["L5"] ? page2["L5"].v : 0;
      const cellE31 = page4["E31"] ? page4["E31"].v : 0;
      const commissionableGross = roundToTwo(
        parseFloat(cellJ5) + parseFloat(cellL5) + parseFloat(cellE31)
      );

      // Used Units Sold = 'Page 6'!C66
      const cellC66 = page6["C66"] ? page6["C66"].v : 0;
      const usedUnitsSold = cellC66;

      // total Units Sold = 'Page 7'!J50
      const cellJ50 = page7["J50"] ? page7["J50"].v : 0;
      const totalUnitsSold = cellJ50;

      // New Front Gross Average = ('Page 6'!E14 + 'Page 6'!E33) / 'Page 6'!C37....now it is 'Page 5!G66'
      // const cellE14 = page6["E14"] ? parseFloat(page6["E14"].v) : 0;
      // const cellE33 = page6["E33"] ? parseFloat(page6["E33"].v) : 0;
      // const cellC37 = page6["C37"] ? parseFloat(page6["C37"].v) : 1;
      // const newFrontGrossAverage = roundToTwo((cellE14 + cellE33) / cellC37);
      const newFrontGrossAverage = page5["G66"]
        ? roundToTwo(parseFloat(page5["G66"].v))
        : 0;

      // Used Front Gross Average = 'Page 6'!F66
      const usedFrontGrossAverage = page6["F66"]
        ? roundToTwo(parseFloat(page6["F66"].v))
        : 0;

      // Finance Pru Average:
      const financePruNom = page7["K50"] ? parseFloat(page7["K50"].v) : 0;
      const financePruAdd = page7["E42"] ? parseFloat(page7["E42"].v) : 0;
      const financePruAverageNom = roundToTwo(financePruNom + financePruAdd);

      // ESA Penetration = 'Page 7'!C4 + 'Page 7'!C17 + 'Page 7'!C30
      const esaC4 = page7["C4"] ? parseFloat(page7["C4"].v) : 0;
      const esaC17 = page7["C17"] ? parseFloat(page7["C17"].v) : 0;
      const esaC30 = page7["C30"] ? parseFloat(page7["C30"].v) : 0;
      const esaPenetration = roundToTwo(esaC4 + esaC17 + esaC30);

      // Maintenance Agreement Penetration = 'Page 7'!C5 + 'Page 7'!C18 + 'Page 7'!C31
      const maintenanceC5 = page7["C5"] ? parseFloat(page7["C5"].v) : 0;
      const maintenanceC18 = page7["C18"] ? parseFloat(page7["C18"].v) : 0;
      const maintenanceC31 = page7["C31"] ? parseFloat(page7["C31"].v) : 0;
      const maintenancePenetration = roundToTwo(
        maintenanceC5 + maintenanceC18 + maintenanceC31
      );

      // Ancillary Products Penetration = sum of specified cells on Page 7
      const ancillaryCells = [
        "C8", "C9", "C10", "C11", "C12", "C14",
        "C21", "C22", "C23", "C24", "C25", "C27",
        "C34", "C35", "C36", "C37", "C39"
      ];
      const ancillaryPenetration = roundToTwo(
        ancillaryCells.reduce((sum, cell) => {
          return sum + (page7[cell] ? parseFloat(page7[cell].v) : 0);
        }, 0)
      );

      // ------------------- Build Data Objects -------------------
      const expectedDate = customExpectedDate;
      const nowISOString = new Date().toISOString();

      const calculatedResults = [
        {
          class: "actual",
          segment: "sales",
          item: "commissionable gross",
          metric: commissionableGross,
          expected_date: expectedDate,
          entered_date: nowISOString,
          id: uuidv4(),
        },
        {
          class: "actual",
          segment: "sales",
          item: "used units sold",
          metric: usedUnitsSold,
          expected_date: expectedDate,
          entered_date: nowISOString,
          id: uuidv4(),
        },
        {
          class: "actual",
          segment: "sales",
          item: "total units sold",
          metric: totalUnitsSold,
          expected_date: expectedDate,
          entered_date: nowISOString,
          id: uuidv4(),
        },
        {
          class: "actual",
          segment: "sales",
          item: "new front gross average",
          metric: newFrontGrossAverage,
          expected_date: expectedDate,
          entered_date: nowISOString,
          id: uuidv4(),
        },
        {
          class: "actual",
          segment: "sales",
          item: "used front gross average",
          metric: usedFrontGrossAverage,
          expected_date: expectedDate,
          entered_date: nowISOString,
          id: uuidv4(),
        },
        {
          class: "actual",
          segment: "sales",
          item: "finance pru average numerator",
          metric: financePruAverageNom,
          expected_date: expectedDate,
          entered_date: nowISOString,
          id: uuidv4(),
        },
        {
          class: "actual",
          segment: "sales",
          item: "esa penetration numerator",
          metric: esaPenetration,
          expected_date: expectedDate,
          entered_date: nowISOString,
          id: uuidv4(),
        },
        {
          class: "actual",
          segment: "sales",
          item: "maintenance agreement penetration numerator",
          metric: maintenancePenetration,
          expected_date: expectedDate,
          entered_date: nowISOString,
          id: uuidv4(),
        },
        {
          class: "actual",
          segment: "sales",
          item: "ancillary products penetration numerator",
          metric: ancillaryPenetration,
          expected_date: expectedDate,
          entered_date: nowISOString,
          id: uuidv4(),
        },
      ];

      return calculatedResults;
    } catch (error) {
      console.error("Error processing Excel file:", error);
      throw error;
    }
  };

  // Upload function: process the file and send data to backend
  const handleUpload = async () => {
    if (!file) {
      setMessage("Please select a file first.");
      return;
    }

    setIsLoading(true);
    setMessage("Processing file...");

    try {
      const token = sessionStorage.getItem("accessToken");

      // Process the Excel file first
      const calculatedResults = await processExcelFile(file);

      // Duplicate check: Fetch existing performance plan records for the expected date
      const duplicateResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/perf-plan?expected_date=${customExpectedDate}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let duplicateData = [];
      if (duplicateResponse.ok) {
        duplicateData = await duplicateResponse.json();
      }

      // Filter duplicate data to the line items present in the calculated results
      const fileLineItems = calculatedResults.map((entry) => entry.item.toLowerCase());
      const duplicates = duplicateData.filter(
        (record) => fileLineItems.includes(record.item.toLowerCase())
      );

      // If duplicates found, prompt the user for overwrite confirmation
      if (duplicates.length > 0) {
        const duplicateItems = duplicates.map((d) => d.item).join(", ");
        const confirmOverwrite = window.confirm(
          `Records for the following line items already exist for ${customExpectedDate}: ${duplicateItems}. Press OK to overwrite, or Cancel to abort the upload.`
        );
        if (!confirmOverwrite) {
          setMessage("Upload canceled due to duplicate data.");
          setIsLoading(false);
          return;
        }
      }

      // Submit calculatedResults to backend
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/perf-plan`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(calculatedResults),
        }
      );

      if (response.ok) {
        const result = await response.json();
        setMessage(
          `File processed and data imported successfully! ${result.message}`
        );
      } else {
        const errorText = await response.text();
        setMessage(`Error: ${errorText}`);
      }
    } catch (error) {
      console.error("Upload Error:", error);
      setMessage(`Upload failed: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="import-data-container">
      <h2>Upload Financial Statement, Inventory, Sales, or Trade Files</h2>
      {/* Drag-and-drop area */}
      <div
        {...getRootProps()}
        style={{
          border: "2px dashed #ccc",
          padding: "30px",
          marginBottom: "20px",
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        <input {...getInputProps()} />
        {isDragActive
          ? "Drop the file here ..."
          : "Drag & drop an Excel file here, or click to select one"}
      </div>

      {/* Fallback file selection */}
      <div style={{ marginBottom: "20px" }}>
        <label htmlFor="dataFile">
          <strong>Or select file:</strong>{" "}
        </label>
        <input
          id="dataFile"
          type="file"
          onChange={handleFileChange}
          accept=".csv,.xlsx,.xls,.xlsm"
        />
      </div>

      {/* Expected Date override input */}
      <div style={{ marginBottom: "20px" }}>
        <label htmlFor="expectedDate">
          <strong>Expected Date:</strong>{" "}
        </label>
        <input
          id="expectedDate"
          type="date"
          value={customExpectedDate}
          onChange={(e) => setCustomExpectedDate(e.target.value)}
        />
        <p style={{ fontSize: "0.9em", color: "#666" }}>
          (Set this date manually. Defaults to previous month.)
        </p>
      </div>

      {/* Display selected file */}
      {file && (
        <p>
          <strong>File Selected:</strong> {file.name}
        </p>
      )}

      {/* Upload Button */}
      <button onClick={handleUpload} disabled={isLoading}>
        {isLoading ? "Processing and Uploading..." : "Import to BigQuery"}
      </button>

      {/* Response Message */}
      {message && (
        <p
          style={{
            marginTop: "20px",
            color: message.startsWith("Error") ? "red" : "green",
          }}
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default ImportData;
