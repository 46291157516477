// src/pages/NotAuthorizedPage.js

import React from 'react';
import { Link } from 'react-router-dom';
import './NotAuthorizedPage.css'; // Optional CSS for styling

const NotAuthorizedPage = () => {
  return (
    <div className="not-authorized-page">
      <h2>Access Denied</h2>
      <p>You do not have permission to view this page.</p>
      <Link to="/">Return to Home</Link>
    </div>
  );
};

export default NotAuthorizedPage;
