// CompPlan.js

import React, { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import CompPlanVisual from './CompPlanVisual';
import CollapsibleSection from '../../Assets/CollapsibleSection';
import './CompPlan.css';

const CompPlan = () => {
  const currentYear = new Date().getFullYear();

  // -----------------------------
  //   State & Setup
  // -----------------------------
  const [inputs, setInputs] = useState({
    basePaySalesManager: '',
    basePayTeamLeader: '',
    reputationScore: 'YES',
    salesEfficiency: '',
    oneSourcePenetration: '',
    esaPenetration: '',
    maintenancePenetration: '',
    ancillaryPenetration: '',
    commissionableGross: '',
    netProfit: '',
    newObjective: '',
    usedObjective: '',
    newFrontEndGross: '',
    usedFrontEndGross: '',
    accessorySales: '',
    financePerUnit: '' // Finance Per Retail Unit Average
  });

  const [weights, setWeights] = useState({
    newCarGrossWeight: '40',
    usedCarGrossWeight: '40',
    accessoriesWeight: '20',
    fpruWeight: '70',
    esaWeight: '15',
    maintenanceWeight: '10',
    ancillaryWeight: '5'
  });

  const [apiMetrics, setApiMetrics] = useState({});
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showVisual, setShowVisual] = useState(true);

  // Month/Year dropdown options
  const monthOptions = [
    { value: '', label: 'Select Month' },
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];

  const currentYearNum = new Date().getFullYear();
  const yearOptions = Array.from({ length: 10 }, (_, i) => ({
    value: (currentYearNum - i).toString(),
    label: (currentYearNum - i).toString(),
  }));

  // -----------------------------
  //   Fetch & Update Data
  // -----------------------------
  const fetchCompPlanData = async () => {
    if (!selectedMonth || !selectedYear) {
      setMessage('Please select both month and year.');
      return;
    }
    setLoading(true);
    setMessage('Fetching data...');
    try {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        setMessage('User not authenticated. Please log in.');
        setLoading(false);
        return;
      }
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/comp-plan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          month: selectedMonth,
          year: selectedYear
        })
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch Comp Plan data.');
      }
      const data = await response.json();
      if (data.metrics) {
        setApiMetrics(data.metrics);
        updateInputFields(data.metrics);
        setIsDataFetched(true);
      } else {
        setMessage('No data found for the selected period.');
      }
    } catch (error) {
      console.error('Error fetching Comp Plan data:', error);
      setMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const updateInputFields = (metrics) => {
    let note = '';
    if (
      !metrics.fleetCount ||
      parseFloat(metrics.fleetCount) === 0
    ) {
      note = " Note: Fleet Count is missing in Data → Performance Plan → Fleet Count.";
    }

    // If user hasn’t typed anything for financePerUnit / ESA / etc,
    // we use fallback from the backend or ratio from Numerator & totalUnitsSold
    const computedFinancePerUnit =
      inputs.financePerUnit !== ""
        ? inputs.financePerUnit
        : parseFloat(metrics.financePruAverage) !== 0
        ? metrics.financePruAverage
        : (metrics.financePruAverageNumerator &&
           metrics.totalUnitsSold &&
           metrics.fleetCount &&
           parseFloat(metrics.totalUnitsSold) - parseFloat(metrics.fleetCount) !== 0)
          ? (
              parseFloat(metrics.financePruAverageNumerator) /
              (parseFloat(metrics.totalUnitsSold) - parseFloat(metrics.fleetCount))
            ).toFixed(2)
          : "";

    const computedEsaPenetration =
      inputs.esaPenetration !== ""
        ? inputs.esaPenetration
        : parseFloat(metrics.esaPenetration) !== 0
        ? (parseFloat(metrics.esaPenetration) * 100).toFixed(2)
        : (metrics.esaPenetrationNumerator &&
           metrics.totalUnitsSold &&
           metrics.fleetCount &&
           parseFloat(metrics.totalUnitsSold) - parseFloat(metrics.fleetCount) !== 0)
          ? (
              (parseFloat(metrics.esaPenetrationNumerator) /
              (parseFloat(metrics.totalUnitsSold) - parseFloat(metrics.fleetCount))) * 100
            ).toFixed(2)
          : "";

    const computedMaintenancePenetration =
      inputs.maintenancePenetration !== ""
        ? inputs.maintenancePenetration
        : parseFloat(metrics.maintenanceAgreementPenetration) !== 0
        ? (parseFloat(metrics.maintenanceAgreementPenetration) * 100).toFixed(2)
        : (metrics.maintenanceAgreementPenetrationNumerator &&
           metrics.totalUnitsSold &&
           metrics.fleetCount &&
           parseFloat(metrics.totalUnitsSold) - parseFloat(metrics.fleetCount) !== 0)
          ? (
              (parseFloat(metrics.maintenanceAgreementPenetrationNumerator) /
              (parseFloat(metrics.totalUnitsSold) - parseFloat(metrics.fleetCount))) * 100
            ).toFixed(2)
          : "";

    const computedAncillaryPenetration =
      inputs.ancillaryPenetration !== ""
        ? inputs.ancillaryPenetration
        : parseFloat(metrics.ancillaryProductsPenetration) !== 0
        ? (parseFloat(metrics.ancillaryProductsPenetration) * 100).toFixed(2)
        : (metrics.ancillaryProductsPenetrationNumerator &&
           metrics.totalUnitsSold &&
           metrics.fleetCount &&
           parseFloat(metrics.totalUnitsSold) - parseFloat(metrics.fleetCount) !== 0)
          ? (
              (parseFloat(metrics.ancillaryProductsPenetrationNumerator) /
              (parseFloat(metrics.totalUnitsSold) - parseFloat(metrics.fleetCount))) * 100
            ).toFixed(2)
          : "";

    setInputs((prev) => ({
      ...prev,
      commissionableGross: metrics.commissionableGross,
      netProfit: metrics.netProfit,
      newObjective: metrics.newUnitsSoldObjective
        ? ((metrics.newUnitsSoldActual / metrics.newUnitsSoldObjective) * 100).toFixed(2)
        : '',
      usedObjective: metrics.usedUnitsSoldObjective
        ? ((metrics.usedUnitsSoldActual / metrics.usedUnitsSoldObjective) * 100).toFixed(2)
        : '',
      reputationScore: metrics.reputationScore || 'NO',
      salesEfficiency: metrics.salesEfficiency ? (metrics.salesEfficiency * 100) : '',
      oneSourcePenetration: metrics.oneSourcePenetration ? (metrics.oneSourcePenetration * 100) : '',
      financePerUnit: computedFinancePerUnit,
      esaPenetration: computedEsaPenetration,
      maintenancePenetration: computedMaintenancePenetration,
      ancillaryPenetration: computedAncillaryPenetration,
      newFrontEndGross: metrics.newFrontGrossAverage,
      usedFrontEndGross: metrics.usedFrontGrossAverage,
      accessorySales: metrics.accessorySales,
    }));

    setMessage("Data fetched successfully." + note);
  };

  // -----------------------------
  //   Handlers & Helpers
  // -----------------------------
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleNumberInputChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleWeightInputChange = (name, value) => {
    setWeights((prev) => ({ ...prev, [name]: value }));
  };

  const formatCurrency = (value) => {
    if (isNaN(value)) return '$0.00';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  };

  // -----------------------------
  //   Commissionable Pool Calculations
  // -----------------------------
  const calculateSalesManagerCommission = () => {
    const commissionableGross = parseFloat(inputs.commissionableGross) || 0;
    return (commissionableGross * 0.0144).toFixed(2);
  };

  const calculateTeamLeaderCommission = () => {
    const commissionableGross = parseFloat(inputs.commissionableGross) || 0;
    return (commissionableGross * 0.0123).toFixed(2);
  };

  const calculateSalesManagerNetProfit = () => {
    const newObjective = parseFloat(inputs.newObjective);
    const netProfitInput = parseFloat(inputs.netProfit);
    if (isNaN(newObjective) || isNaN(netProfitInput)) return '0.00';
    // If newObjective < 1, multiply net profit by 0.0085 * -0.25
    return newObjective < 1
      ? ((netProfitInput * 0.0085) * -0.25).toFixed(2)
      : (netProfitInput * 0.0085).toFixed(2);
  };

  const calculateSalesManagerNewObjective = () => {
    const newObjectiveInput = parseFloat(inputs.newObjective);
    if (inputs.newObjective === '') return '0.00';
    return newObjectiveInput < 110 ? (-1000).toFixed(2) : '0.00';
  };

  const calculateTeamLeaderNewObjective = () => {
    const newObjectiveInput = parseFloat(inputs.newObjective);
    if (inputs.newObjective === '') return '0.00';
    if (newObjectiveInput < 1) return (-1500).toFixed(2);
    if (newObjectiveInput < 110) return (-1000).toFixed(2);
    return '0.00';
  };

  const calculateSalesManagerUsedObjective = () => {
    const usedObjectiveInput = parseFloat(inputs.usedObjective);
    if (inputs.usedObjective === '') return '0.00';
    return usedObjectiveInput >= 100 ? (500).toFixed(2) : '0.00';
  };

  const calculateTeamLeaderUsedObjective = () => {
    const usedObjectiveInput = parseFloat(inputs.usedObjective);
    if (inputs.usedObjective === '') return '0.00';
    return usedObjectiveInput >= 100 ? (250).toFixed(2) : '0.00';
  };

  const calculateReputationScore = () => {
    return inputs.reputationScore === 'NO' ? -1000 : 0;
  };

  const calculateSalesEfficiency = () => {
    if (inputs.salesEfficiency === '') return 0;
    const efficiency = parseFloat(inputs.salesEfficiency);
    return isNaN(efficiency) ? 0 : efficiency < 105 ? -500 : 0;
  };

  const calculateOneSourcePenetration = () => {
    if (inputs.oneSourcePenetration === '') return 0;
    const penetration = parseFloat(inputs.oneSourcePenetration);
    return isNaN(penetration) ? 0 : penetration < 85 ? -500 : 0;
  };

  /**
   * Returns the "Total Compensation" for each role including Net Profit for Sales Manager
   */
  const calculateTotalCompensation = () => {
    const salesManagerComm = parseFloat(calculateSalesManagerCommission()) || 0;
    const managerNetProfit = parseFloat(calculateSalesManagerNetProfit()) || 0;
    const managerNewObj = parseFloat(calculateSalesManagerNewObjective()) || 0;
    const managerUsedObj = parseFloat(calculateSalesManagerUsedObjective()) || 0;
    const repScore = parseFloat(calculateReputationScore()) || 0;
    const efficiency = parseFloat(calculateSalesEfficiency()) || 0;
    const oneSource = parseFloat(calculateOneSourcePenetration()) || 0;

    const teamLeaderComm = parseFloat(calculateTeamLeaderCommission()) || 0;
    const teamLeaderNewObj = parseFloat(calculateTeamLeaderNewObjective()) || 0;
    const teamLeaderUsedObj = parseFloat(calculateTeamLeaderUsedObjective()) || 0;

    const salesManagerTotal =
      salesManagerComm +
      managerNetProfit +
      managerNewObj +
      managerUsedObj +
      repScore +
      efficiency +
      oneSource;

    const teamLeaderTotal =
      teamLeaderComm +
      teamLeaderNewObj +
      teamLeaderUsedObj +
      repScore +
      efficiency +
      oneSource;

    return {
      salesManager: salesManagerTotal,
      teamLeader: teamLeaderTotal,
    };
  };

  // ---------------------------------------
  // Weighted Payout: we want to EXCLUDE Net Profit for the Manager
  // ---------------------------------------
  const totalCompensation = calculateTotalCompensation();

  // Manager's "pool" for Weighted Payout (i.e. exclude net profit portion)
  const managerPool =
    totalCompensation.salesManager - parseFloat(calculateSalesManagerNetProfit()) || 0;

  // Team Leader uses their entire total, because they never had netProfit in the first place
  const teamLeaderPool = totalCompensation.teamLeader;

  // Weighted Payout Calculation Functions (use managerPool for SM)
  const calculateNewFrontEndGrossAverageSalesManager = () => {
    const newFrontEndGrossInput = parseFloat(inputs.newFrontEndGross) || 0;
    const newCarGrossWeight = parseFloat(weights.newCarGrossWeight) / 100 || 0;

    if (newFrontEndGrossInput < 0) return managerPool * 0.5 * newCarGrossWeight * 0.6;
    if (newFrontEndGrossInput < 1000) return managerPool * 0.5 * newCarGrossWeight * 0.7;
    if (newFrontEndGrossInput < 1500) return managerPool * 0.5 * newCarGrossWeight * 0.8;
    if (newFrontEndGrossInput < 2000) return managerPool * 0.5 * newCarGrossWeight * 0.9;
    return managerPool * 0.5 * newCarGrossWeight;
  };

  const calculateNewFrontEndGrossAverageTeamLeader = () => {
    const newFrontEndGrossInput = parseFloat(inputs.newFrontEndGross) || 0;
    const newCarGrossWeight = parseFloat(weights.newCarGrossWeight) / 100 || 0;

    if (newFrontEndGrossInput < 0) return teamLeaderPool * 0.5 * newCarGrossWeight * 0.6;
    if (newFrontEndGrossInput < 1000) return teamLeaderPool * 0.5 * newCarGrossWeight * 0.7;
    if (newFrontEndGrossInput < 1500) return teamLeaderPool * 0.5 * newCarGrossWeight * 0.8;
    if (newFrontEndGrossInput < 2000) return teamLeaderPool * 0.5 * newCarGrossWeight * 0.9;
    return teamLeaderPool * 0.5 * newCarGrossWeight;
  };

  const calculateUsedFrontEndGrossAverageSalesManager = () => {
    const usedFrontEndGrossInput = parseFloat(inputs.usedFrontEndGross) || 0;
    const usedCarGrossWeight = parseFloat(weights.usedCarGrossWeight) / 100 || 0;

    if (usedFrontEndGrossInput < 1450) return managerPool * 0.5 * usedCarGrossWeight * 0.6;
    if (usedFrontEndGrossInput < 1550) return managerPool * 0.5 * usedCarGrossWeight * 0.7;
    if (usedFrontEndGrossInput < 1650) return managerPool * 0.5 * usedCarGrossWeight * 0.8;
    if (usedFrontEndGrossInput < 1750) return managerPool * 0.5 * usedCarGrossWeight * 0.9;
    return managerPool * 0.5 * usedCarGrossWeight;
  };

  const calculateUsedFrontEndGrossAverageTeamLeader = () => {
    const usedFrontEndGrossInput = parseFloat(inputs.usedFrontEndGross) || 0;
    const usedCarGrossWeight = parseFloat(weights.usedCarGrossWeight) / 100 || 0;

    if (usedFrontEndGrossInput < 1450) return teamLeaderPool * 0.5 * usedCarGrossWeight * 0.6;
    if (usedFrontEndGrossInput < 1550) return teamLeaderPool * 0.5 * usedCarGrossWeight * 0.7;
    if (usedFrontEndGrossInput < 1650) return teamLeaderPool * 0.5 * usedCarGrossWeight * 0.8;
    if (usedFrontEndGrossInput < 1750) return teamLeaderPool * 0.5 * usedCarGrossWeight * 0.9;
    return teamLeaderPool * 0.5 * usedCarGrossWeight;
  };

  const calculateAccessorySalesAverageSalesManager = () => {
    const accessorySalesInput = parseFloat(inputs.accessorySales) || 0;
    const accessoriesWeight = parseFloat(weights.accessoriesWeight) / 100 || 0;

    if (accessorySalesInput < 38000) return managerPool * 0.5 * accessoriesWeight * 0.6;
    if (accessorySalesInput < 45000) return managerPool * 0.5 * accessoriesWeight * 0.7;
    if (accessorySalesInput < 53000) return managerPool * 0.5 * accessoriesWeight * 0.8;
    if (accessorySalesInput < 60000) return managerPool * 0.5 * accessoriesWeight * 0.9;
    return managerPool * 0.5 * accessoriesWeight;
  };

  const calculateAccessorySalesAverageTeamLeader = () => {
    const accessorySalesInput = parseFloat(inputs.accessorySales) || 0;
    const accessoriesWeight = parseFloat(weights.accessoriesWeight) / 100 || 0;

    if (accessorySalesInput < 38000) return teamLeaderPool * 0.5 * accessoriesWeight * 0.6;
    if (accessorySalesInput < 45000) return teamLeaderPool * 0.5 * accessoriesWeight * 0.7;
    if (accessorySalesInput < 53000) return teamLeaderPool * 0.5 * accessoriesWeight * 0.8;
    if (accessorySalesInput < 60000) return teamLeaderPool * 0.5 * accessoriesWeight * 0.9;
    return teamLeaderPool * 0.5 * accessoriesWeight;
  };

  const calculateFinancePerRetailUnitAverageSalesManager = () => {
    const financePerUnitInput = parseFloat(inputs.financePerUnit) || 0;
    const fpruWeight = parseFloat(weights.fpruWeight) / 100 || 0;

    if (financePerUnitInput < 1484) return managerPool * 0.5 * fpruWeight * 0.6;
    if (financePerUnitInput < 1584) return managerPool * 0.5 * fpruWeight * 0.7;
    if (financePerUnitInput < 1684) return managerPool * 0.5 * fpruWeight * 0.8;
    if (financePerUnitInput < 1784) return managerPool * 0.5 * fpruWeight * 0.9;
    return managerPool * 0.5 * fpruWeight;
  };

  const calculateFinancePerRetailUnitAverageTeamLeader = () => {
    const financePerUnitInput = parseFloat(inputs.financePerUnit) || 0;
    const fpruWeight = parseFloat(weights.fpruWeight) / 100 || 0;

    if (financePerUnitInput < 1484) return teamLeaderPool * 0.5 * fpruWeight * 0.6;
    if (financePerUnitInput < 1584) return teamLeaderPool * 0.5 * fpruWeight * 0.7;
    if (financePerUnitInput < 1684) return teamLeaderPool * 0.5 * fpruWeight * 0.8;
    if (financePerUnitInput < 1784) return teamLeaderPool * 0.5 * fpruWeight * 0.9;
    return teamLeaderPool * 0.5 * fpruWeight;
  };

  const calculateEsaPenetrationSalesManager = () => {
    const esaPenetrationInput = parseFloat(inputs.esaPenetration) / 100 || 0;
    const esaWeight = parseFloat(weights.esaWeight) / 100 || 0;

    if (esaPenetrationInput < 0.65) return managerPool * 0.5 * esaWeight * 0.5;
    if (esaPenetrationInput < 0.66) return managerPool * 0.5 * esaWeight * 0.8;
    if (esaPenetrationInput < 0.67) return managerPool * 0.5 * esaWeight * 0.9;
    return managerPool * 0.5 * esaWeight;
  };

  const calculateEsaPenetrationTeamLeader = () => {
    const esaPenetrationInput = parseFloat(inputs.esaPenetration) / 100 || 0;
    const esaWeight = parseFloat(weights.esaWeight) / 100 || 0;

    if (esaPenetrationInput < 0.65) return teamLeaderPool * 0.5 * esaWeight * 0.5;
    if (esaPenetrationInput < 0.66) return teamLeaderPool * 0.5 * esaWeight * 0.8;
    if (esaPenetrationInput < 0.67) return teamLeaderPool * 0.5 * esaWeight * 0.9;
    return teamLeaderPool * 0.5 * esaWeight;
  };

  const calculateMaintenancePenetrationSalesManager = () => {
    const maintenancePenetrationInput = parseFloat(inputs.maintenancePenetration) / 100 || 0;
    const maintenanceWeight = parseFloat(weights.maintenanceWeight) / 100 || 0;

    if (maintenancePenetrationInput < 0.57) return managerPool * 0.5 * maintenanceWeight * 0.6;
    if (maintenancePenetrationInput < 0.59) return managerPool * 0.5 * maintenanceWeight * 0.8;
    return managerPool * 0.5 * maintenanceWeight;
  };

  const calculateMaintenancePenetrationTeamLeader = () => {
    const maintenancePenetrationInput = parseFloat(inputs.maintenancePenetration) / 100 || 0;
    const maintenanceWeight = parseFloat(weights.maintenanceWeight) / 100 || 0;

    if (maintenancePenetrationInput < 0.57) return teamLeaderPool * 0.5 * maintenanceWeight * 0.6;
    if (maintenancePenetrationInput < 0.59) return teamLeaderPool * 0.5 * maintenanceWeight * 0.8;
    return teamLeaderPool * 0.5 * maintenanceWeight;
  };

  const calculateAncillaryPenetrationSalesManager = () => {
    const ancillaryPenetrationInput = parseFloat(inputs.ancillaryPenetration) / 100 || 0;
    const ancillaryWeight = parseFloat(weights.ancillaryWeight) / 100 || 0;

    if (ancillaryPenetrationInput < 0.52) return managerPool * 0.5 * ancillaryWeight * 0.6;
    if (ancillaryPenetrationInput < 0.54) return managerPool * 0.5 * ancillaryWeight * 0.8;
    return managerPool * 0.5 * ancillaryWeight;
  };

  const calculateAncillaryPenetrationTeamLeader = () => {
    const ancillaryPenetrationInput = parseFloat(inputs.ancillaryPenetration) / 100 || 0;
    const ancillaryWeight = parseFloat(weights.ancillaryWeight) / 100 || 0;

    if (ancillaryPenetrationInput < 0.52) return teamLeaderPool * 0.5 * ancillaryWeight * 0.6;
    if (ancillaryPenetrationInput < 0.54) return teamLeaderPool * 0.5 * ancillaryWeight * 0.8;
    return teamLeaderPool * 0.5 * ancillaryWeight;
  };

  // Sum each Weighted Payout row
  const newFrontEndGrossAverageSalesManager = calculateNewFrontEndGrossAverageSalesManager();
  const newFrontEndGrossAverageTeamLeader = calculateNewFrontEndGrossAverageTeamLeader();
  const usedFrontEndGrossAverageSalesManager = calculateUsedFrontEndGrossAverageSalesManager();
  const usedFrontEndGrossAverageTeamLeader = calculateUsedFrontEndGrossAverageTeamLeader();
  const accessorySalesAverageSalesManager = calculateAccessorySalesAverageSalesManager();
  const accessorySalesAverageTeamLeader = calculateAccessorySalesAverageTeamLeader();
  const financePerRetailUnitAverageSalesManager = calculateFinancePerRetailUnitAverageSalesManager();
  const financePerRetailUnitAverageTeamLeader = calculateFinancePerRetailUnitAverageTeamLeader();
  const esaPenetrationSalesManager = calculateEsaPenetrationSalesManager();
  const esaPenetrationTeamLeader = calculateEsaPenetrationTeamLeader();
  const maintenancePenetrationSalesManager = calculateMaintenancePenetrationSalesManager();
  const maintenancePenetrationTeamLeader = calculateMaintenancePenetrationTeamLeader();
  const ancillaryPenetrationSalesManager = calculateAncillaryPenetrationSalesManager();
  const ancillaryPenetrationTeamLeader = calculateAncillaryPenetrationTeamLeader();

  // Summaries for Weighted Payout
  const commissionPoolWeightedSumSalesManager =
    (parseFloat(newFrontEndGrossAverageSalesManager) || 0) +
    (parseFloat(usedFrontEndGrossAverageSalesManager) || 0) +
    (parseFloat(accessorySalesAverageSalesManager) || 0) +
    (parseFloat(financePerRetailUnitAverageSalesManager) || 0) +
    (parseFloat(esaPenetrationSalesManager) || 0) +
    (parseFloat(maintenancePenetrationSalesManager) || 0) +
    (parseFloat(ancillaryPenetrationSalesManager) || 0);

  const commissionPoolWeightedSumTeamLeader =
    (parseFloat(newFrontEndGrossAverageTeamLeader) || 0) +
    (parseFloat(usedFrontEndGrossAverageTeamLeader) || 0) +
    (parseFloat(accessorySalesAverageTeamLeader) || 0) +
    (parseFloat(financePerRetailUnitAverageTeamLeader) || 0) +
    (parseFloat(esaPenetrationTeamLeader) || 0) +
    (parseFloat(maintenancePenetrationTeamLeader) || 0) +
    (parseFloat(ancillaryPenetrationTeamLeader) || 0);

  // -----------------------------
  //   JSX Return
  // -----------------------------
  return (
    <div className="comp-plan-container">
      {/* Earnings Over Time */}
      <CollapsibleSection title="Earnings Over Time" defaultCollapsed={false}>
        {showVisual && <CompPlanVisual />}
      </CollapsibleSection>

      {/* Select Month & Year */}
      <CollapsibleSection title="Select Month and Year" defaultCollapsed={false}>
        <div className="selection-section">
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="select-month">Month:</label>
              <select
                id="select-month"
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                className="input-cell"
              >
                {monthOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="select-year">Year:</label>
              <select
                id="select-year"
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                className="input-cell"
              >
                {yearOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="button-group">
            <button onClick={fetchCompPlanData} className="fetch-data-button" disabled={loading}>
              Fetch Data
            </button>
            <button
              onClick={() => {
                setInputs({
                  basePaySalesManager: '',
                  basePayTeamLeader: '',
                  reputationScore: 'YES',
                  salesEfficiency: '',
                  oneSourcePenetration: '',
                  esaPenetration: '',
                  maintenancePenetration: '',
                  ancillaryPenetration: '',
                  commissionableGross: '',
                  netProfit: '',
                  newObjective: '',
                  usedObjective: '',
                  newFrontEndGross: '',
                  usedFrontEndGross: '',
                  accessorySales: '',
                  financePerUnit: ''
                });
                setWeights({
                  newCarGrossWeight: '40',
                  usedCarGrossWeight: '40',
                  accessoriesWeight: '20',
                  fpruWeight: '70',
                  esaWeight: '15',
                  maintenanceWeight: '10',
                  ancillaryWeight: '5'
                });
                setIsDataFetched(false);
                setMessage('Form has been cleared.');
              }}
              className="clear-data-button"
              disabled={loading}
            >
              Clear Data
            </button>
          </div>
        </div>
      </CollapsibleSection>

      {/* Loading Indicator */}
      {loading && <div className="loading-spinner">Loading...</div>}
      {/* Feedback Message */}
      {message && <p className="message">{message}</p>}

      {/* Base Pay */}
      <CollapsibleSection title="Base Pay" defaultCollapsed={false}>
        <table className="comp-plan-table">
          <thead>
            <tr>
              <th>Variable</th>
              <th>Input ($)</th>
              <th>Sales Manager ($)</th>
              <th>Team Leader ($)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Base Pay</td>
              <td>
  <div style={{ display: 'flex', gap: '1rem' }}>
    <NumericFormat
      thousandSeparator
      prefix="$"
      className={`input-cell ${isDataFetched ? 'override' : ''}`}
      placeholder="Sales Manager"
      value={inputs.basePaySalesManager}
      onValueChange={(values) => {
        handleNumberInputChange('basePaySalesManager', values.value);
      }}
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
    />
    <NumericFormat
      thousandSeparator
      prefix="$"
      className={`input-cell ${isDataFetched ? 'override' : ''}`}
      placeholder="Team Leader"
      value={inputs.basePayTeamLeader}
      onValueChange={(values) => {
        handleNumberInputChange('basePayTeamLeader', values.value);
      }}
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
    />
  </div>
</td>
              <td>{formatCurrency(parseFloat(inputs.basePaySalesManager) || 0)}</td>
              <td>{formatCurrency(parseFloat(inputs.basePayTeamLeader) || 0)}</td>
            </tr>
          </tbody>
        </table>
      </CollapsibleSection>

      {/* Weight Inputs */}
      <CollapsibleSection title="Weight Inputs" defaultCollapsed>
        <table className="comp-plan-table">
          <thead>
            <tr>
              <th>Variable</th>
              <th>Weight (%)</th>
            </tr>
          </thead>
          <tbody>
            {[
              { label: 'New Car Gross Average Weight', name: 'newCarGrossWeight', placeholder: 'e.g. 40%' },
              { label: 'Used Car Gross Average Weight', name: 'usedCarGrossWeight', placeholder: 'e.g. 40%' },
              { label: 'Accessories Weight (%)', name: 'accessoriesWeight', placeholder: 'e.g. 20%' },
              { label: 'Finance Per Retail Unit (FPRU) Weight (%)', name: 'fpruWeight', placeholder: 'e.g. 70%' },
              { label: 'ESA Penetration Weight (%)', name: 'esaWeight', placeholder: 'e.g. 15%' },
              { label: 'Maintenance Agreement Penetration Weight (%)', name: 'maintenanceWeight', placeholder: 'e.g. 10%' },
              { label: 'Ancillary Products Penetration Weight (%)', name: 'ancillaryWeight', placeholder: 'e.g. 5%' },
            ].map(({ label, name, placeholder }) => (
              <tr key={name}>
                <td>{label}</td>
                <td>
                  <NumericFormat
                    thousandSeparator={false}
                    suffix="%"
                    className={`input-cell ${isDataFetched ? 'override' : ''}`}
                    placeholder={placeholder}
                    value={weights[name]}
                    onValueChange={(values) => {
                      handleWeightInputChange(name, values.value);
                    }}
                    decimalScale={2}
                    fixedDecimalScale
                    allowNegative={false}
                    isAllowed={({ floatValue }) => floatValue >= 0 && floatValue <= 100}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </CollapsibleSection>

      {/* Commissionable Pool Section */}
      <CollapsibleSection title="Commissionable Pool" defaultCollapsed={false}>
        {/* 
          We show the manager total minus netProfit, because we only want to include netProfit
          in the final total compensation. 
        */}
        <h2 className="commission-header">
          <div className="totals-row">
            <span className="section-totals">
              Total Sales Manager:{' '}
              {formatCurrency(
                totalCompensation.salesManager - parseFloat(calculateSalesManagerNetProfit()) || 0
              )}
            </span>
            <span className="section-totals totals-separator">
              Total Team Leader: {formatCurrency(totalCompensation.teamLeader)}
            </span>
          </div>
        </h2>

        <table className="comp-plan-table">
          <thead>
            <tr>
              <th>Variable</th>
              <th>Input ($)</th>
              <th>Sales Manager ($)</th>
              <th>Team Leader ($)</th>
            </tr>
          </thead>
          <tbody>
            {[
              {
                label: 'Commissionable Gross ($)',
                name: 'commissionableGross',
                placeholder: 'e.g. $100,000.00',
                calcSM: calculateSalesManagerCommission,
                calcTL: calculateTeamLeaderCommission
              },
              {
                label: 'Net Profit ($)',
                name: 'netProfit',
                placeholder: 'e.g. $20,000.00',
                calcSM: calculateSalesManagerNetProfit
              },
              {
                label: 'New Car Objective (%)',
                name: 'newObjective',
                placeholder: 'e.g. 102.5%',
                calcSM: calculateSalesManagerNewObjective,
                calcTL: calculateTeamLeaderNewObjective
              },
              {
                label: 'Used Car Objective (%)',
                name: 'usedObjective',
                placeholder: 'e.g. 102.5%',
                calcSM: calculateSalesManagerUsedObjective,
                calcTL: calculateTeamLeaderUsedObjective
              },
              {
                label: 'Reputation.com Score',
                name: 'reputationScore',
                type: 'select',
                options: ['YES', 'NO'],
                calcSM: calculateReputationScore,
                calcTL: calculateReputationScore
              },
              {
                label: 'Sales Efficiency (%)',
                name: 'salesEfficiency',
                placeholder: 'e.g. 104%',
                calcSM: calculateSalesEfficiency,
                calcTL: calculateSalesEfficiency
              },
              {
                label: 'One Source Penetration (%)',
                name: 'oneSourcePenetration',
                placeholder: 'e.g. 84.2%',
                calcSM: calculateOneSourcePenetration,
                calcTL: calculateOneSourcePenetration
              },
            ].map((row) => (
              <tr key={row.name}>
                <td>{row.label}</td>
                <td>
                  {row.type === 'select' ? (
                    <select
                      name={row.name}
                      className={`input-cell ${isDataFetched ? 'override' : ''}`}
                      value={inputs[row.name]}
                      onChange={handleInputChange}
                    >
                      {row.options.map((opt) => (
                        <option key={opt} value={opt}>
                          {opt}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <NumericFormat
                      thousandSeparator={
                        row.name === 'netProfit' || row.name === 'commissionableGross'
                      }
                      prefix={
                        row.name === 'netProfit' || row.name === 'commissionableGross'
                          ? '$'
                          : ''
                      }
                      className={`input-cell ${isDataFetched ? 'override' : ''}`}
                      placeholder={row.placeholder}
                      value={inputs[row.name]}
                      onValueChange={(values) => {
                        handleNumberInputChange(row.name, values.value);
                      }}
                      decimalScale={2}
                      fixedDecimalScale
                      allowNegative={false}
                      isAllowed={
                        row.name.includes('Objective')
                          ? ({ floatValue }) => floatValue >= 0 && floatValue <= 200
                          : undefined
                      }
                    />
                  )}
                </td>
                <td>{formatCurrency(parseFloat(row.calcSM()) || 0)}</td>
                <td>
                  {row.calcTL ? formatCurrency(parseFloat(row.calcTL()) || 0) : ''}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </CollapsibleSection>

      {/* Commission Pool Weighted Pay Out */}
      <CollapsibleSection title="Commission Pool Weighted Pay Out" defaultCollapsed={false}>
        {/* We do NOT subtract netProfit here, because we excluded it from managerPool already. */}
        <h2 className="commission-header">
          <div className="totals-row">
            <span className="section-totals">
              Total Sales Manager: {formatCurrency(commissionPoolWeightedSumSalesManager)}
            </span>
            <span className="section-totals totals-separator">
              Total Team Leader: {formatCurrency(commissionPoolWeightedSumTeamLeader)}
            </span>
          </div>
        </h2>

        <table className="comp-plan-table">
          <thead>
            <tr>
              <th>Variable</th>
              <th>Input ($ or %)</th>
              <th>Sales Manager ($)</th>
              <th>Team Leader ($)</th>
            </tr>
          </thead>
          <tbody>
            {[
              {
                label: 'New Front-End Gross Average ($)',
                name: 'newFrontEndGross',
                placeholder: 'e.g. $1,077.41',
                calcSM: calculateNewFrontEndGrossAverageSalesManager,
                calcTL: calculateNewFrontEndGrossAverageTeamLeader,
              },
              {
                label: 'Used Front-End Gross Average ($)',
                name: 'usedFrontEndGross',
                placeholder: 'e.g. $1,451.11',
                calcSM: calculateUsedFrontEndGrossAverageSalesManager,
                calcTL: calculateUsedFrontEndGrossAverageTeamLeader,
              },
              {
                label: 'Accessory Sales ($)',
                name: 'accessorySales',
                placeholder: 'e.g. $62,575.13',
                calcSM: calculateAccessorySalesAverageSalesManager,
                calcTL: calculateAccessorySalesAverageTeamLeader,
              },
              {
                label: 'Finance Per Retail Unit Average ($)',
                name: 'financePerUnit',
                placeholder: 'e.g. $1,763.53',
                calcSM: calculateFinancePerRetailUnitAverageSalesManager,
                calcTL: calculateFinancePerRetailUnitAverageTeamLeader,
              },
              {
                label: 'ESA Penetration (%)',
                name: 'esaPenetration',
                placeholder: 'e.g. 66.66%',
                calcSM: calculateEsaPenetrationSalesManager,
                calcTL: calculateEsaPenetrationTeamLeader,
              },
              {
                label: 'Maintenance Agreement Penetration (%)',
                name: 'maintenancePenetration',
                placeholder: 'e.g. 50.60%',
                calcSM: calculateMaintenancePenetrationSalesManager,
                calcTL: calculateMaintenancePenetrationTeamLeader,
              },
              {
                label: 'Ancillary Products Penetration (%)',
                name: 'ancillaryPenetration',
                placeholder: 'e.g. 47.27%',
                calcSM: calculateAncillaryPenetrationSalesManager,
                calcTL: calculateAncillaryPenetrationTeamLeader,
              },
            ].map((row) => (
              <tr key={row.name}>
                <td>{row.label}</td>
                <td>
                  <NumericFormat
                    thousandSeparator={
                      // We want currency formatting for these four:
                      row.name === 'accessorySales' ||
                      row.name === 'financePerUnit' ||
                      row.name === 'newFrontEndGross' ||
                      row.name === 'usedFrontEndGross'
                    }
                    prefix={
                      row.name === 'accessorySales' ||
                      row.name === 'financePerUnit' ||
                      row.name === 'newFrontEndGross' ||
                      row.name === 'usedFrontEndGross'
                        ? '$'
                        : ''
                    }
                    className={`input-cell ${isDataFetched ? 'override' : ''}`}
                    placeholder={row.placeholder}
                    value={inputs[row.name]}
                    onValueChange={(values) => {
                      handleNumberInputChange(row.name, values.value);
                    }}
                    decimalScale={2}
                    fixedDecimalScale
                    allowNegative={false}
                    isAllowed={
                      row.name.includes('Penetration')
                        ? ({ floatValue }) => floatValue >= 0 && floatValue <= 100
                        : undefined
                    }
                  />
                </td>
                <td>{formatCurrency(parseFloat(row.calcSM()) || 0)}</td>
                <td>{formatCurrency(parseFloat(row.calcTL()) || 0)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </CollapsibleSection>

      {/* Total Compensation (Final) */}
      <CollapsibleSection title="Total Compensation" defaultCollapsed={false}>
        <table className="comp-plan-table">
          <thead>
            <tr>
              <th>Variable</th>
              <th>Sales Manager ($)</th>
              <th>Team Leader ($)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total Compensation</td>
              {/*
                Because we said net profit belongs only in final total for the manager,
                we add it back here:
                  Weighted Payout + Net Profit portion
                The Weighted Payout doesn't have it, so we add it explicitly now.
              */}
              <td>
                {formatCurrency(
                  commissionPoolWeightedSumSalesManager +
                    parseFloat(calculateSalesManagerNetProfit() || '0')
                )}
              </td>
              <td>{formatCurrency(commissionPoolWeightedSumTeamLeader)}</td>
            </tr>
          </tbody>
        </table>
      </CollapsibleSection>
    </div>
  );
};

export default CompPlan;
