// CollapsibleSection.jsx

import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Import icons
import './CollapsibleSection.css';

const CollapsibleSection = ({ title, children, defaultCollapsed = false }) => {
    const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            toggleCollapse();
        }
    };

    return (
        <div className="collapsible-section">
            <div
                className="collapsible-header"
                onClick={toggleCollapse}
                onKeyPress={handleKeyPress}
                role="button"
                tabIndex={0}
                aria-expanded={!isCollapsed}
            >
                <span>{title}</span>
                <span className={`collapsible-icon ${isCollapsed ? 'collapsed' : 'expanded'}`}>
                    {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
                </span>
            </div>
            <Collapse isOpened={!isCollapsed}>
                <div className="collapsible-content">
                    {children}
                </div>
            </Collapse>
        </div>
    );
};

export default CollapsibleSection;
