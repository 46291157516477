// HomePage.js

import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
} from "react";
import { RooftopContext } from "../context/RooftopContext";
import "./HomePage.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import {
  format,
  parseISO,
  isBefore,
  isAfter,
  startOfDay,
  isSameDay,
  isEqual,
  differenceInDays,
  addMonths,
} from "date-fns";
import Modal from "react-modal";
import { Tooltip } from "react-tooltip";
import interactionPlugin from "@fullcalendar/interaction";
import LoadingAnimation from "../components/LoadingAnimation";
import { FaCarAlt, FaMoneyBillAlt, FaWarehouse } from "react-icons/fa";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto"; // Automatically register Chart.js components

Modal.setAppElement("#root");

const HomePage = ({ user }) => {
  const { currentRooftop } = useContext(RooftopContext);

  const [metrics, setMetrics] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [loadingMetrics, setLoadingMetrics] = useState(true);
  const [loadingTasks, setLoadingTasks] = useState(true);
  const [errorMetrics, setErrorMetrics] = useState(null);
  const [errorTasks, setErrorTasks] = useState(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [financeContracts, setFinanceContracts] = useState([]);

  const initialTaskState = {
    taskId: "",
    title: "",
    description: "",
    owner: "",
    task_due: "",
    status: "Active",
    sub_status: "Ready",
    priority_level: "Low",
    department: "",
  };

  const [currentTask, setCurrentTask] = useState(initialTaskState);
  const [personnel, setPersonnel] = useState([]);

  const [sortConfig, setSortConfig] = useState({
    key: "dueDate",
    direction: "ascending",
  });

  const [selectedDate, setSelectedDate] = useState(null);

  // New state for tabs
  const [activeTab, setActiveTab] = useState("upcomingTasks");

  // New states for inventory data
  const [inventory, setInventory] = useState([]);
  const [filteredInventory, setFilteredInventory] = useState([]);
  const [saleType, setSaleType] = useState("New"); // Default to 'New'

  // Tooltip state variables
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const tooltipTimeout = useRef(null);

  useEffect(() => {
    fetchMetrics();
    fetchTasks();
    fetchPersonnelData();
    fetchInventory();
    fetchFinanceContracts();

    return () => {
      clearTimeout(tooltipTimeout.current);
    };
  }, [currentRooftop]);

  const fetchMetrics = async () => {
    try {
      const token = sessionStorage.getItem("accessToken");
      const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/overview-metrics?rooftop=${currentRooftop}`;

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch metrics: ${response.status} ${response.statusText}`
        );
      }

      const data = await response.json();
      setMetrics(data);
      setLoadingMetrics(false);
    } catch (error) {
      console.error("Error fetching metrics:", error);
      setErrorMetrics(error.message);
      setLoadingMetrics(false);
    }
  };

  const fetchTasks = async () => {
    try {
      const token = sessionStorage.getItem("accessToken");
      const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/tasks?rooftop=${currentRooftop}`;

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch tasks: ${response.status} ${response.statusText}`
        );
      }

      let data = await response.json();

      if (!Array.isArray(data)) {
        data = [];
      }

      if (data.length === 0) {
        setTasks([]);
        setLoadingTasks(false);
        return;
      }

      const today = startOfDay(new Date());

      data = data
        .map((task) => {
          if (!task) return null;

          const dueDateString = task.task_due ? task.task_due.toString() : null;
          const createdDateString = task.task_created
            ? task.task_created.toString()
            : null;
          const editDateString = task.edit_datetime
            ? task.edit_datetime.toString()
            : null;

          let dueDate = null;
          let createdDate = null;
          let editDate = null;

          if (dueDateString) {
            try {
              dueDate = parseISO(dueDateString);
              if (isNaN(dueDate)) {
                throw new Error("Invalid Date");
              }
            } catch (error) {
              console.error(
                `Invalid due date for task ID ${task.task_id}:`,
                dueDateString
              );
            }
          }

          if (createdDateString) {
            try {
              createdDate = parseISO(createdDateString);
              if (isNaN(createdDate)) {
                throw new Error("Invalid Date");
              }
            } catch (error) {
              console.error(
                `Invalid created date for task ID ${task.task_id}:`,
                createdDateString
              );
            }
          }

          if (editDateString) {
            try {
              editDate = parseISO(editDateString);
              if (isNaN(editDate)) {
                throw new Error("Invalid Date");
              }
            } catch (error) {
              console.error(
                `Invalid edit date for task ID ${task.task_id}:`,
                editDateString
              );
            }
          }

          let department = task.segment?.toLowerCase() || "Other";

          let status = task.status;
          if (dueDate && isBefore(dueDate, today) && status !== "Completed") {
            status = "Overdue";
          }

          return {
            taskId: task.task_id || "",
            title: task.task_title || "",
            description: task.task_description || "",
            owner: task.task_owner || "",
            createdDate: createdDate || null,
            dueDate: dueDate || null,
            editDate: editDate || null,
            status: status || "",
            sub_status: task.sub_status || "",
            priority_level: task.priority_level || "",
            department: department || "",
          };
        })
        .filter((task) => task !== null);

      setTasks(data);
      setLoadingTasks(false);
    } catch (error) {
      console.error("Error fetching tasks:", error);
      setErrorTasks(error.message);
      setLoadingTasks(false);
    }
  };

  const fetchPersonnelData = useCallback(async () => {
    if (!user || !user.token) {
      console.error("User or token not available");
      return;
    }

    const token = sessionStorage.getItem("accessToken");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/personnel?rooftop=${currentRooftop}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();

        const personnelOptions = data.map(
          (person) =>
            `${person.employee_name_first} ${person.employee_name_last}`
        );
        setPersonnel(personnelOptions);
      } else {
        console.error("Failed to fetch personnel data");
      }
    } catch (error) {
      console.error("Error fetching personnel data:", error);
    }
  }, [user, currentRooftop]);

  // Function to fetch inventory data
  const fetchInventory = async () => {
    try {
      const token = sessionStorage.getItem("accessToken");
      const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/available-inventory?rooftop=${currentRooftop}`;
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch inventory: ${response.status} ${response.statusText}`
        );
      }

      const data = await response.json();

      const currentDate = new Date();

      const inventoryWithAge = data.map((item) => {
        let stockInDate = null;

        // Handle stock_in_date as an object with a 'value' property
        if (
          item.stock_in_date &&
          typeof item.stock_in_date.value === "string"
        ) {
          stockInDate = parseISO(item.stock_in_date.value);
        } else if (typeof item.stock_in_date === "string") {
          stockInDate = parseISO(item.stock_in_date);
        }

        // If stock_in_date is invalid or null, log a warning and set age to null
        if (!stockInDate || isNaN(stockInDate)) {
          console.warn(`Invalid stock_in_date for item ${item.stock_number}`);
          return { ...item, age: null };
        }

        // Calculate the age in days
        const age = differenceInDays(currentDate, stockInDate);
        return { ...item, age };
      });

      // Filter out items with invalid ages
      setInventory(inventoryWithAge.filter((item) => item.age !== null));
      filterInventory("New", inventoryWithAge); // Default to "New"
    } catch (error) {
      console.error("Error fetching inventory:", error);
    }
  };

  const fetchFinanceContracts = async () => {
    try {
      const token = sessionStorage.getItem("accessToken");
      const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/finance-contracts?rooftop=${currentRooftop}`;

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch finance contracts: ${response.status} ${response.statusText}`
        );
      }

      let data = await response.json();

      const currentDate = new Date();

      data = data.map((contract) => {
        // Handle deal_date as an object with a 'value' property
        let dealDate = null;

        if (
          contract.deal_date &&
          typeof contract.deal_date.value === "string"
        ) {
          dealDate = parseISO(contract.deal_date.value);
        } else if (typeof contract.deal_date === "string") {
          dealDate = parseISO(contract.deal_date);
        }

        const termOut = contract.term_out ? parseInt(contract.term_out) : 0;

        if (!dealDate || isNaN(termOut) || termOut === 0) {
          return null;
        }

        // Calculate payout date by adding termOut months to dealDate
        const payoutDate = addMonths(dealDate, termOut);

        // Calculate total contract duration in days
        const totalDuration = differenceInDays(payoutDate, dealDate);

        // Calculate remaining duration in days
        const remainingDuration = differenceInDays(payoutDate, currentDate);

        // Calculate remaining term percentage
        const remainingPercentage = Math.max(
          0,
          (remainingDuration / totalDuration) * 100
        );

        // Convert interest rate to percentage
        const interestRate = contract.rate_out
          ? (parseFloat(contract.rate_out) * 100).toFixed(2)
          : null;

        // Handle pay_out
        let payOut = 0;
        if (contract.pay_out) {
          if (typeof contract.pay_out === "object" && contract.pay_out.value) {
            payOut = parseFloat(contract.pay_out.value);
          } else {
            payOut = parseFloat(contract.pay_out);
          }
        }

        // Handle pay_in
        let payIn = 0;
        if (contract.pay_in) {
          if (typeof contract.pay_in === "object" && contract.pay_in.value) {
            payIn = parseFloat(contract.pay_in.value);
          } else {
            payIn = parseFloat(contract.pay_in);
          }
        }

        // Calculate payout delta
        const payDelta = payOut - payIn;

        // Handle rate_in
        const rateIn = contract.rate_in
          ? parseFloat(contract.rate_in) * 100
          : null;

        // Calculate rate delta
        const rateDelta = interestRate - rateIn;

        return {
          ...contract,
          dealDate,
          payoutDate,
          remainingPercentage,
          interestRate,
          payOut,
          payIn,
          payDelta,
          rateIn,
          rateDelta,
          // Include additional fields for tooltip
          deal_number: contract.deal_number,
          down_payment: contract.down_payment,
          reserve: contract.reserve,
          lender: contract.lender,
          purchased_products: contract.purchased_products,
        };
      });

      // Filter out invalid contracts and those already matured
      data = data.filter(
        (contract) =>
          contract &&
          contract.remainingPercentage > 0 &&
          contract.term_out > 0 &&
          !isNaN(contract.remainingPercentage)
      );

      // Sort by remainingPercentage descending (highest percentage first)
      data.sort((a, b) => a.remainingPercentage - b.remainingPercentage);

      // Take top 5 contracts
      const topContracts = data.slice(0, 5);

      setFinanceContracts(topContracts);
    } catch (error) {
      console.error("Error fetching finance contracts:", error);
    }
  };

  // Function to filter inventory by sale_type and get top 5 oldest
  const filterInventory = (type, inventoryData = inventory) => {
    const filtered = inventoryData
      .filter((item) => item.sale_type === type)
      .sort((a, b) => b.age - a.age)
      .slice(0, 5);

    setFilteredInventory(filtered);
    setSaleType(type); // Update current sale type
  };

  const currentMonth = format(new Date(), "MMMM yyyy");

  const openModal = (task = null) => {
    if (task) {
      setCurrentTask({
        ...task,
        taskId: task.taskId,
        task_due: task.dueDate ? format(task.dueDate, "yyyy-MM-dd") : "",
      });
      setIsEditing(true);
    } else {
      setCurrentTask(initialTaskState);
      setIsEditing(false);
    }
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setCurrentTask(initialTaskState);
    setIsEditing(false);
    setModalIsOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setCurrentTask((prevTask) => {
      let updatedTask = { ...prevTask, [name]: value };

      if (name === "status") {
        if (value === "Completed") {
          updatedTask.sub_status = "Completed";
        } else if (updatedTask.sub_status === "Completed") {
          updatedTask.sub_status = "Ready";
        }
      }

      if (
        (name === "task_due" || name === "status") &&
        updatedTask.task_due !== ""
      ) {
        const today = startOfDay(new Date());
        const dueDate = updatedTask.task_due
          ? parseISO(updatedTask.task_due)
          : null;

        if (
          dueDate &&
          isBefore(startOfDay(dueDate), today) &&
          updatedTask.status !== "Completed"
        ) {
          updatedTask.status = "Overdue";
        } else if (updatedTask.status === "Overdue") {
          updatedTask.status = "Active";
        }
      }

      return updatedTask;
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const token = sessionStorage.getItem("accessToken");
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/tasks${
      isEditing ? "/" + currentTask.taskId : ""
    }`;

    const method = isEditing ? "PUT" : "POST";
    const body = {
      task_title: currentTask.title,
      task_description: currentTask.description,
      task_owner: currentTask.owner,
      task_due: currentTask.task_due,
      status: currentTask.status || "Active",
      sub_status: currentTask.sub_status || "Ready",
      priority_level: currentTask.priority_level || "Low",
      segment: currentTask.department,
    };

    try {
      const response = await fetch(apiUrl, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `Failed to ${isEditing ? "update" : "add"} task`
        );
      }

      fetchTasks();
      closeModal();
    } catch (error) {
      console.error("Error:", error);
      alert(error.message);
    }
  };

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  const handleDateClick = (arg) => {
    const clickedDate = startOfDay(arg.date);
    setSelectedDate(clickedDate);
  };

  // Filter tasks based on active tab and selected date, then sort
  const filteredTasks = React.useMemo(() => {
    let filtered = tasks;

    if (selectedDate) {
      filtered = filtered.filter(
        (task) =>
          task.dueDate && isSameDay(startOfDay(task.dueDate), selectedDate)
      );
    }

    if (activeTab === "recentActivities") {
      // Filter tasks with a valid editDate and sort by editDate descending
      filtered = filtered
        .filter((task) => task.editDate)
        .sort((a, b) => new Date(b.editDate) - new Date(a.editDate))
        .slice(0, 10); // Limit to the 10 most recent activities
    } else if (activeTab === "completedTasks") {
      filtered = filtered
        .filter((task) => task.status === "Completed")
        .slice(0, 10);
    } else {
      // Upcoming tasks
      const today = startOfDay(new Date());
      filtered = filtered
        .filter(
          (task) =>
            task.dueDate &&
            (isAfter(task.dueDate, today) || isEqual(task.dueDate, today)) &&
            task.status !== "Completed"
        )
        .slice(0, 10);
    }

    // Now sort the filtered tasks using sortConfig
    filtered.sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Handle dates
      if (["dueDate", "createdDate", "editDate"].includes(sortConfig.key)) {
        aValue = aValue ? new Date(aValue) : new Date(0);
        bValue = bValue ? new Date(bValue) : new Date(0);
      } else {
        // Handle strings
        aValue = aValue ? aValue.toString().toLowerCase() : "";
        bValue = bValue ? bValue.toString().toLowerCase() : "";
      }

      if (aValue < bValue) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    return filtered;
  }, [tasks, sortConfig, activeTab, selectedDate]);

  const getPriorityColor = (priority) => {
    switch (priority.toLowerCase()) {
      case "urgent":
        return "red";
      case "high":
        return "orange";
      case "low":
        return "green";
      default:
        return "blue";
    }
  };

  // Event handlers for the tooltip
  const handleMouseEnter = (contract, event) => {
    tooltipTimeout.current = setTimeout(() => {
      setTooltipData({
        deal_number: contract.deal_number,
        down_payment: contract.down_payment,
        reserve: contract.reserve,
        lender: contract.lender,
        purchased_products: contract.purchased_products,
      });
      setTooltipPosition({ x: event.clientX, y: event.clientY });
      setIsTooltipVisible(true);
    }, 500); // 500ms delay
  };

  const handleMouseLeave = () => {
    clearTimeout(tooltipTimeout.current);
    setIsTooltipVisible(false);
    setTooltipData(null);
  };

  const getPriorityLabel = (priority) => {
    return priority.charAt(0).toUpperCase() + priority.slice(1);
  };

  const handleDayCellContent = (arg) => {
    const tasksForDay = tasks.filter(
      (task) =>
        task.dueDate &&
        format(task.dueDate, "yyyy-MM-dd") === format(arg.date, "yyyy-MM-dd") &&
        task.status !== "Completed" // Exclude completed tasks
    );

    return (
      <div className="day-cell-content">
        <div className="fc-day-number">{arg.dayNumberText}</div>
        <div className="dots-container">
          {tasksForDay.map((task) => (
            <div
              key={task.taskId}
              className="event-dot"
              style={{ backgroundColor: getPriorityColor(task.priority_level) }}
            ></div>
          ))}
        </div>
      </div>
    );
  };

  if (loadingMetrics || loadingTasks) return <LoadingAnimation />;
  if (errorMetrics) return <p>Error: {errorMetrics}</p>;
  if (errorTasks) return <p>Error: {errorTasks}</p>;

  return (
    <div className="home-page">
      {/* <h2>Dealership Metrics for {currentMonth}</h2> */}
      <div className="kpis">
        <div className="kpi-item">
          <div className="kpi-icon-wrapper">
            <FaCarAlt className="kpi-icon" />
          </div>
          <div className="kpi-text">
            <h3>Total Sales</h3>
            <p>{metrics?.total_sales}</p>
          </div>
        </div>
        <div className="kpi-item">
          <div className="kpi-icon-wrapper">
            <FaMoneyBillAlt className="kpi-icon" />
          </div>
          <div className="kpi-text">
            <h3>Total Gross Revenue</h3>
            <p>${Number(metrics?.total_gross).toLocaleString()}</p>
          </div>
        </div>
        <div className="kpi-item">
          <div className="kpi-icon-wrapper">
            <FaWarehouse className="kpi-icon" />
          </div>
          <div className="kpi-text">
            <h3>Total Inventory</h3>
            <p>{metrics?.total_inventory}</p>
          </div>
        </div>
      </div>
      <div className="main-content">
        {tasks && tasks.length > 0 && (
          <div className="calendar-container">
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              events={tasks
                .map((task) => ({
                  title: task.title,
                  date: task.dueDate
                    ? format(task.dueDate, "yyyy-MM-dd")
                    : null,
                  backgroundColor: getPriorityColor(task.priority_level),
                  borderColor: getPriorityColor(task.priority_level),
                  extendedProps: {
                    priority_level: task.priority_level,
                    description: task.description,
                  },
                }))
                .filter((event) => event.date !== null)}
              dateClick={handleDateClick}
              height="auto"
              dayCellContent={handleDayCellContent}
              showNonCurrentDates={false}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "",
              }}
            />
            {/* Calendar Legend */}
            <div className="calendar-legend">
              <span>
                <span className="legend-dot urgent"></span>Urgent
              </span>
              <span>
                <span className="legend-dot high"></span>High
              </span>
              <span>
                <span className="legend-dot low"></span>Low
              </span>
            </div>
          </div>
        )}

        <div className="tasks-container">
          {/* Tabs and Buttons on the Same Line */}
          <div className="tabs-and-buttons">
            <div className="tabs">
              <button
                className={activeTab === "upcomingTasks" ? "active" : ""}
                onClick={() => setActiveTab("upcomingTasks")}
              >
                Upcoming Tasks
              </button>
              <button
                className={activeTab === "recentActivities" ? "active" : ""}
                onClick={() => setActiveTab("recentActivities")}
              >
                Recent Activities
              </button>
              <button
                className={activeTab === "completedTasks" ? "active" : ""}
                onClick={() => setActiveTab("completedTasks")}
              >
                Completed Tasks
              </button>
            </div>
            <div className="add-task-button">
              {selectedDate && (
                <button onClick={() => setSelectedDate(null)}>
                  Show All Tasks
                </button>
              )}
              <button onClick={() => openModal()}>Add Task</button>
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <th
                  onClick={() => handleSort("title")}
                  className={
                    sortConfig.key === "title"
                      ? sortConfig.direction === "ascending"
                        ? "sort-asc"
                        : "sort-desc"
                      : ""
                  }
                >
                  Title
                </th>
                <th
                  onClick={() => handleSort("owner")}
                  className={
                    sortConfig.key === "owner"
                      ? sortConfig.direction === "ascending"
                        ? "sort-asc"
                        : "sort-desc"
                      : ""
                  }
                >
                  Task Owner
                </th>
                <th
                  onClick={() => handleSort("dueDate")}
                  className={
                    sortConfig.key === "dueDate"
                      ? sortConfig.direction === "ascending"
                        ? "sort-asc"
                        : "sort-desc"
                      : ""
                  }
                >
                  Due Date
                </th>
                <th
                  onClick={() => handleSort("status")}
                  className={
                    sortConfig.key === "status"
                      ? sortConfig.direction === "ascending"
                        ? "sort-asc"
                        : "sort-desc"
                      : ""
                  }
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredTasks && filteredTasks.length > 0 ? (
                filteredTasks.map((task) => (
                  <React.Fragment key={task.taskId}>
                    <tr
                      onClick={() => openModal(task)}
                      data-tooltip-id="task-tooltip" // Matches the Tooltip's ID
                      data-tooltip-html={`
            <div><strong>Sub Status:</strong> ${task.sub_status}</div>
            <div><strong>Description:</strong><br/>${task.description}</div>
            <div><strong>Priority:</strong> <span class="priority-${task.priority_level.toLowerCase()}">${getPriorityLabel(
                        task.priority_level
                      )}</span></div>
          `}
                    >
                      <td>{task.title}</td>
                      <td>{task.owner}</td>
                      <td>
                        {task.dueDate
                          ? format(task.dueDate, "MM/dd/yyyy")
                          : "N/A"}
                      </td>
                      <td>
                        <span
                          className={`status-badge status-${task.status.toLowerCase()}`}
                        >
                          {task.status}
                        </span>
                      </td>
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No tasks available.</td>
                </tr>
              )}
            </tbody>
          </table>
          <Tooltip
            id="task-tooltip"
            place="top"
            effect="solid"
            html={true}
            getContent={(dataTip) => {
              // Find the element being hovered
              const element = document.querySelector(`[data-tip="${dataTip}"]`);
              if (element) {
                const subStatus = element.getAttribute("data-sub-status");
                const description = element.getAttribute("data-description");
                const priority = element.getAttribute("data-priority");
                return `
        <div><strong>Sub Status:</strong> ${subStatus}</div>
        <div><strong>Description:</strong><br>${description}</div>
        <div><strong>Priority:</strong> <span class="priority-${priority.toLowerCase()}">${getPriorityLabel(
                  priority
                )}</span></div>
      `;
              }
              return "No data available.";
            }}
          />
        </div>
      </div>

      {/* Modal for Adding/Editing Tasks */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel={isEditing ? "Edit Task" : "Add Task"}
        className="modal"
        overlayClassName="overlay"
      >
        <h2>{isEditing ? "Edit Task" : "Add Task"}</h2>
        <form onSubmit={handleFormSubmit}>
          <label>
            Title:
            <input
              type="text"
              name="title"
              value={currentTask?.title || ""}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Description:
            <textarea
              name="description"
              value={currentTask?.description || ""}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Task Owner:
            <select
              name="owner"
              value={currentTask?.owner || ""}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Owner</option>
              {personnel.map((person, index) => (
                <option key={index} value={person}>
                  {person}
                </option>
              ))}
            </select>
          </label>
          <label>
            Due Date:
            <input
              type="date"
              name="task_due"
              value={currentTask?.task_due || ""}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Status:
            <select
              name="status"
              value={currentTask?.status || "Active"}
              onChange={handleInputChange}
              required
            >
              <option value="Active">Active</option>
              <option value="Pending">Pending</option>
              <option value="Cancelled">Cancelled</option>
              <option value="Completed">Completed</option>
              <option value="Overdue">Overdue</option>
            </select>
          </label>
          <label>
            Sub Status:
            <select
              name="sub_status"
              value={currentTask?.sub_status || "Ready"}
              onChange={handleInputChange}
              required
              disabled={currentTask.status === "Completed"}
            >
              <option value="Accepted">Accepted</option>
              <option value="In Progress">In Progress</option>
              <option value="Ready">Ready</option>
              <option value="Approved">Approved</option>
              <option value="Blocked">Blocked</option>
              {currentTask.status === "Completed" && (
                <option value="Completed">Completed</option>
              )}
            </select>
          </label>
          <label>
            Segment:
            <select
              name="department"
              value={currentTask?.department || ""}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Segment</option>
              <option value="Sales">Sales</option>
              <option value="Customer Service">Customer Service</option>
              <option value="Service">Service</option>
              <option value="Parts">Parts</option>
              <option value="Collision">Collision</option>
              <option value="Other">Other</option>
            </select>
          </label>
          <label>
            Priority Level:
            <select
              name="priority_level"
              value={currentTask?.priority_level || "Low"}
              onChange={handleInputChange}
              required
            >
              <option value="Urgent">Urgent</option>
              <option value="High">High</option>
              <option value="Low">Low</option>
            </select>
          </label>
          <div className="modal-buttons">
            <button type="submit">
              {isEditing ? "Update Task" : "Add Task"}
            </button>
            <button type="button" onClick={closeModal}>
              Cancel
            </button>
          </div>
        </form>
      </Modal>
      {/* New Aging Inventory Section */}
      <div className="aging-inventory">
        <div className="aging-inventory-header">
          <h2>Aging Inventory</h2>
          <div className="filter-buttons">
            <button
              className={saleType === "New" ? "active" : ""}
              onClick={() => filterInventory("New")}
            >
              New
            </button>
            <button
              className={saleType === "Used" ? "active" : ""}
              onClick={() => filterInventory("Used")}
            >
              Used
            </button>
          </div>
        </div>
        <div className="inventory-list">
          {filteredInventory.map((item) => {
            const maxAge = 180; // Maximum age represented on the bar
            const carPosition = Math.min((item.age / maxAge) * 100, 100); // Calculate car position as a percentage

            return (
              <div key={item.stock_number} className="inventory-item">
                <div className="inventory-details">
                  <strong>{item.stock_number}</strong> - {item.make}{" "}
                  {item.model}
                </div>
                <div className="age-bar-container">
                  <div className="age-bar">
                    <div className="segment segment-30"></div>
                    <div className="segment segment-60"></div>
                    <div className="segment segment-90"></div>
                    <div className="segment segment-180"></div>
                    <FaCarAlt
                      className="car-icon"
                      style={{ left: `${carPosition}%` }}
                    />
                  </div>
                </div>
                <span className="car-age">{item.age} days</span>
              </div>
            );
          })}
        </div>
      </div>
      {/* Finance Contracts Section */}
      <div className="finance-contracts">
        <h2>Top 5 Finance Contracts Nearing End of Term</h2>
        <table className="finance-contracts-table">
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Monthly Pay</th>
              <th>Interest Rate</th>
              <th>Term Remaining</th>
            </tr>
          </thead>
          <tbody>
            {financeContracts.length > 0 ? (
              financeContracts.map((contract, index) => (
                <tr
                  key={index}
                  onMouseEnter={(e) => handleMouseEnter(contract, e)}
                  onMouseLeave={handleMouseLeave}
                >
                  <td>
                    {contract.customer_name_first} {contract.customer_name_last}
                  </td>
                  <td>
                    $
                    {contract.payOut.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    <span
                      className={`delta ${
                        contract.payDelta >= 0 ? "increase" : "decrease"
                      }`}
                    >
                      {contract.payDelta >= 0 ? "▲" : "▼"} $
                      {Math.abs(contract.payDelta).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </td>
                  <td>
                    {contract.interestRate}%
                    <span
                      className={`delta ${
                        contract.rateDelta >= 0 ? "increase" : "decrease"
                      }`}
                    >
                      {contract.rateDelta >= 0 ? "▲" : "▼"}{" "}
                      {Math.abs(contract.rateDelta.toFixed(2))}%
                    </span>
                  </td>
                  <td>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="term-doughnut-container">
                        <Doughnut
                          data={{
                            datasets: [
                              {
                                data: [
                                  100 - contract.remainingPercentage,
                                  contract.remainingPercentage,
                                ],
                                backgroundColor: ["#007bff", "#e0e0e0"],
                                borderWidth: 0,
                                borderRadius: [10, 0],
                                hoverOffset: 0,
                              },
                            ],
                          }}
                          options={{
                            cutout: "70%",
                            plugins: {
                              tooltip: { enabled: false },
                              legend: { display: false },
                            },
                          }}
                        />
                        <div className="term-doughnut-label">
                          {contract.remainingPercentage.toFixed(1)}%
                        </div>
                      </div>
                      <span className="remaining-days">
                        {differenceInDays(contract.payoutDate, new Date())} days
                        left
                      </span>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No finance contracts available.</td>
              </tr>
            )}
          </tbody>
        </table>
        {/* Tooltip for Finance Contracts */}
        {isTooltipVisible && tooltipData && (
          <div
            className="finance-tooltip"
            style={{
              top: tooltipPosition.y + 10,
              left: tooltipPosition.x + 10,
            }}
          >
            <p>
              <strong>Deal Number:</strong> {tooltipData.deal_number}
            </p>
            <p>
              <strong>Down Payment:</strong> $
              {parseFloat(tooltipData.down_payment || 0).toLocaleString()}
            </p>
            <p>
              <strong>Reserve:</strong> $
              {parseFloat(tooltipData.reserve || 0).toLocaleString()}
            </p>
            <p>
              <strong>Lender:</strong> {tooltipData.lender}
            </p>
            <p>
              <strong>Products:</strong>{" "}
              {tooltipData.purchased_products || "N/A"}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePage;
