import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RooftopContext } from "../context/RooftopContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faFileAlt,
  faDatabase,
  faChevronLeft,
  faChevronRight,
  faListOl,
  faTrophy,
  faUserShield,
  faMoon,   // For Dark Mode
  faSun,    // For Light Mode
  faBuilding,
  faBell,
  faMoneyBill,   // The notification bell
} from "@fortawesome/free-solid-svg-icons";
import "./MainLayout.css";
import logoLong from "./logo_long.png";
import logoTall from "./logo_tall.png"; // For mobile
// import ParticleBackground from "../Assets/ParticleBackground";

const MainLayout = ({ children, onLogout, user }) => {
  const { currentRooftop, setCurrentRooftop, setCurrentDatasetId } =
    useContext(RooftopContext);
  const navigate = useNavigate();

  // ------------------------ Main Layout State ------------------------
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isRooftopPopoverOpen, setRooftopPopoverOpen] = useState(false);

  // ------------------------ Trade Request Notifications ------------------------
  const [isNotificationsOpen, setNotificationsOpen] = useState(false);
  const [tradeRequests, setTradeRequests] = useState([]);
  const [loadingRequests, setLoadingRequests] = useState(false);

  // ------------------------ Load/Save Theme from localStorage ------------------------
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setIsDarkMode(savedTheme === "dark");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  // ------------------------ Sidebar Responsiveness ------------------------
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSidebarVisible(false);
      } else {
        setSidebarVisible(true);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Collapse sidebar if mobile
  const handleLinkClick = () => {
    if (window.innerWidth < 768) {
      setSidebarVisible(false);
    }
    setDropdownOpen(false);
  };

  // ------------------------ Rooftop Popover Handling ------------------------
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isRooftopPopoverOpen) {
        const popoverElement = document.querySelector(".rooftop-popover");
        const rooftopButtonElement = document.querySelector(".rooftop-button");

        if (
          popoverElement &&
          !popoverElement.contains(event.target) &&
          rooftopButtonElement &&
          !rooftopButtonElement.contains(event.target)
        ) {
          setRooftopPopoverOpen(false);
        }
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [isRooftopPopoverOpen]);

  // ------------------------ Rooftop Change Handler ------------------------
  const handleRooftopChange = async (newRooftop) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/select-rooftop`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({ rooftop: newRooftop }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setCurrentRooftop(newRooftop);
        setCurrentDatasetId(data.datasetId);
      } else {
        console.error("Failed to update rooftop");
        const errorData = await response.json();
        setErrorMessage(errorData.error || "Failed to update rooftop");
      }
    } catch (error) {
      console.error("Error updating rooftop:", error);
      setErrorMessage("Error updating rooftop");
    }
  };

  // ------------------------ Sidebar Toggle ------------------------
  const toggleSidebar = () => {
    const newSidebarState = !isSidebarVisible;
    setSidebarVisible(newSidebarState);
    if (!newSidebarState) {
      setExpandedGroup(null);
    }
  };

  const handleGroupToggle = (group) => {
    if (!isSidebarVisible) {
      setSidebarVisible(true);
    }
    setExpandedGroup(expandedGroup === group ? null : group);
    setSelectedSection(group);
  };

  // ------------------------ Account Dropdown ------------------------
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  // ------------------------ Theme Toggle ------------------------
  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  // ------------------------ Fetch Trade Requests on Mount (for Red Dot) ------------------------
  useEffect(() => {
    const fetchOnMount = async () => {
      try {
        setLoadingRequests(true);
        const token = sessionStorage.getItem("accessToken");
        if (!token) return;

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/trade-requests`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setTradeRequests(data);
        } else {
          console.error("Auto-fetch trade requests failed");
        }
      } catch (error) {
        console.error("Error auto-fetching trade requests:", error);
      } finally {
        setLoadingRequests(false);
      }
    };
    fetchOnMount();
  }, []);

  // ------------------------ Manual Notification Bell Toggle ------------------------
  const fetchTradeRequests = async () => {
    try {
      setLoadingRequests(true);
      const token = sessionStorage.getItem("accessToken");
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/trade-requests`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setTradeRequests(data);
      } else {
        console.error("Failed to fetch trade requests");
      }
    } catch (error) {
      console.error("Error fetching trade requests:", error);
    } finally {
      setLoadingRequests(false);
    }
  };

  const toggleNotifications = async () => {
    const newValue = !isNotificationsOpen;
    setNotificationsOpen(newValue);

    if (newValue) {
      await fetchTradeRequests();
    }
  };

  // ------------------------ Approve / Reject / Review ------------------------
  const handleApproveTrade = async (requestId) => {
    try {
      const token = sessionStorage.getItem("accessToken");
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/approve-trade-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ request_id: requestId }),
        }
      );
      if (response.ok) {
        alert("Trade approved!");
        setTradeRequests((prev) =>
          prev.filter((r) => r.request_id !== requestId)
        );
      } else {
        console.error("Failed to approve trade");
      }
    } catch (error) {
      console.error("Error approving trade:", error);
    }
  };

  const handleRejectTrade = async (requestId) => {
    try {
      const token = sessionStorage.getItem("accessToken");
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/reject-trade-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ request_id: requestId }),
        }
      );
      if (response.ok) {
        alert("Trade rejected.");
        setTradeRequests((prev) =>
          prev.filter((r) => r.request_id !== requestId)
        );
      } else {
        console.error("Failed to reject trade");
      }
    } catch (error) {
      console.error("Error rejecting trade:", error);
    }
  };

  const handleReviewTrade = (requestId) => {
    // Navigate to InventoryTradeForm with request_id in the URL
    navigate(`/forms/inventoryTrade?request_id=${requestId}`);
    // Optionally close notifications
    setNotificationsOpen(false);
  };

  // ------------------------ Render Layout ------------------------
  return (
    <div className={`main-layout ${isDarkMode ? "dark-mode" : ""}`}>
      {errorMessage && <div className="error-message">{errorMessage}</div>}

      {/* Header Section */}
      <header className="header">
      {/* <ParticleBackground animate={true} fillContainer={true} /> */}
        <div className="header-left">
          <Link to="/">
            {/* Two images, one for wide screens, one for mobile */}
            <img src={logoLong} alt="Logo" className="logo-long" />
            <img src={logoTall} alt="Logo" className="logo-tall" />
          </Link>
          <h1 className="title">
            {currentRooftop || "OptiSight Software"}
          </h1>
        </div>

        <div className="header-right">
          {/* Notification Bell */}
          <div className="notification-bell-container">
            <FontAwesomeIcon
              icon={faBell}
              className="notification-bell-icon"
              onClick={toggleNotifications}
            />
            {/* If we have tradeRequests, show a red badge */}
            {tradeRequests.length > 0 && (
              <span className="notification-bell-badge">
                {tradeRequests.length}
              </span>
            )}

            {isNotificationsOpen && (
              <div className="notification-dropdown">
                <h4>Trade Requests</h4>
                {loadingRequests && <p>Loading requests...</p>}
                {!loadingRequests && tradeRequests.length === 0 && (
                  <p>No trade requests.</p>
                )}

                {/* 
                  For each "req", we now reference the new columns:
                  requested_vin, requested_sale_type, requesting_store, etc.
                */}
                {!loadingRequests &&
                  tradeRequests.map((req) => (
                    <div key={req.request_id} className="notification-item">
                      {/* Header with Title & Review Button */}
                      <div className="notification-item-header">
                        <span className="trade-request-title">
                          {req.requested_sale_type} Trade Request
                        </span>
                        <button
                          className="review-btn"
                          onClick={() => handleReviewTrade(req.request_id)}
                        >
                          Review
                        </button>
                      </div>

                      {/* Body area: show relevant fields */}
                      <p>
                        <strong>Requested VIN:</strong> {req.requested_vin}
                        <br />
                        <strong>Stock #:</strong> {req.requested_stock_number}
                        <br />
                        <strong>Sale Type:</strong> {req.requested_sale_type}
                        <br />
                        <strong>Store:</strong> {req.requesting_store}
                        <br />
                        <strong>Vehicle:</strong>{" "}
                        {req.requesting_make} {req.requesting_model} (
                        {req.requesting_model_year})
                        <br />
                        <strong>Submitted by:</strong> {req.submitted_by}
                      </p>

                      {/* Approve / Reject */}
                      <div className="notification-actions">
                        <button
                          className="approve-btn"
                          onClick={() => handleApproveTrade(req.request_id)}
                        >
                          Approve
                        </button>
                        <button
                          className="reject-btn"
                          onClick={() => handleRejectTrade(req.request_id)}
                        >
                          Reject
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>

          {/* Account Dropdown */}
          <div className="account-dropdown">
            <div
              onClick={toggleDropdown}
              className="account-link"
              aria-haspopup="true"
              aria-expanded={isDropdownOpen}
            >
              <span className="account-name">
                {user?.firstName || "User"}'s
              </span>
              <span className="account-text">Account</span>
            </div>
            {isDropdownOpen && (
              <div className="dropdown-content">
                <Link to="/account-details" onClick={closeDropdown}>
                  Account Details
                </Link>
                <button
                  onClick={() => {
                    onLogout();
                    closeDropdown();
                  }}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </header>

      {/* Main Layout Content */}
      <div className="layout-content">
        {/* Sidebar Navigation */}
        <div className={`side-nav ${isSidebarVisible ? "" : "collapsed"}`}>
          {/* Sidebar Toggle Button */}
          <button
            className="toggle-button"
            onClick={toggleSidebar}
            aria-label="Toggle Sidebar"
          >
            <FontAwesomeIcon
              icon={isSidebarVisible ? faChevronLeft : faChevronRight}
            />
          </button>

          <ul>
            {/* Rooftop Menu */}
            <li
              onClick={() => setRooftopPopoverOpen(!isRooftopPopoverOpen)}
              className={`${isRooftopPopoverOpen ? "expanded" : ""} ${
                selectedSection === "Rooftop" ? "selected" : ""
              }`}
            >
              <div className="side-nav-item rooftop-button">
                <FontAwesomeIcon icon={faBuilding} className="icon" />
                {isSidebarVisible && "Rooftop"}
              </div>
              {isRooftopPopoverOpen && (
                <div
                  className="rooftop-popover"
                  style={{
                    width: isSidebarVisible ? "300px" : "300px",
                  }}
                >
                  {user.dataset_mapping.map((entry, index) => (
                    <div
                      key={entry.rooftop}
                      className={`rooftop-option ${
                        currentRooftop === entry.rooftop ? "selected" : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRooftopChange(entry.rooftop);
                      }}
                    >
                      <span className="rooftop-number">{index + 1}.</span>
                      <span className="rooftop-name">{entry.rooftop}</span>
                      <hr className="rooftop-separator" />
                    </div>
                  ))}
                </div>
              )}
            </li>

            {/* Conditionally render Admin menu */}
            {(user.userRole === "admin" ||
              user.permissions.includes("accessAdminSection")) && (
              <li
                onClick={() => handleGroupToggle("Admin")}
                className={`${
                  expandedGroup === "Admin" ? "expanded" : ""
                } ${selectedSection === "Admin" ? "selected" : ""}`}
              >
                <div className="side-nav-item">
                  <FontAwesomeIcon icon={faUserShield} className="icon" />
                  {isSidebarVisible && "Admin"}
                </div>
                {expandedGroup === "Admin" && isSidebarVisible && (
                  <ul className="nested">
                    <li>
                      <Link
                        to="/admin/switchboard"
                        onClick={() => {
                          setSelectedSection("Admin");
                          handleLinkClick();
                        }}
                      >
                        Switchboard
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            )}

            {/* Dashboards Menu */}
            {user.permissions.includes("accessDashboards") && (
              <li
                onClick={() => handleGroupToggle("Dashboards")}
                className={`${
                  expandedGroup === "Dashboards" ? "expanded" : ""
                } ${selectedSection === "Dashboards" ? "selected" : ""}`}
              >
                <div className="side-nav-item">
                  <FontAwesomeIcon icon={faChartPie} className="icon" />
                  {isSidebarVisible && "Dashboards"}
                </div>
                {expandedGroup === "Dashboards" && isSidebarVisible && (
                  <ul className="nested">
                    {user.permissions.includes("viewSalesDashboard") && (
                      <li>
                        <Link
                          to="/dashboards/sales"
                          onClick={() => {
                            setSelectedSection("Dashboards");
                            handleLinkClick();
                          }}
                        >
                          Sales
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes("viewCustomerDashboard") && (
                      <li>
                        <Link
                          to="/dashboards/customer"
                          onClick={() => {
                            setSelectedSection("Dashboards");
                            handleLinkClick();
                          }}
                        >
                          Customer
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes("viewInventoryDashboard") && (
                      <li>
                        <Link
                          to="/dashboards/inventory"
                          onClick={() => {
                            setSelectedSection("Dashboards");
                            handleLinkClick();
                          }}
                        >
                          Inventory
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes("viewAccountingReportsDashboard") && (
                      <li>
                        <Link
                          to="/dashboards/accountingreports"
                          onClick={() => {
                            setSelectedSection("Dashboards");
                            handleLinkClick();
                          }}
                        >
                          Accounting
                        </Link>
                      </li>
                    )}
                  </ul>
                )}
              </li>
            )}

            {/* Forms Menu */}
            {user.permissions.includes("accessForms") && (
              <li
                onClick={() => handleGroupToggle("Forms")}
                className={`${
                  expandedGroup === "Forms" ? "expanded" : ""
                } ${selectedSection === "Forms" ? "selected" : ""}`}
              >
                <div className="side-nav-item">
                  <FontAwesomeIcon icon={faFileAlt} className="icon" />
                  {isSidebarVisible && "Forms"}
                </div>
                {expandedGroup === "Forms" && isSidebarVisible && (
                  <ul className="nested">
                    {user.permissions.includes("submitSalesForm") && (
                      <li>
                        <Link
                          to="/forms/sales"
                          onClick={() => {
                            setSelectedSection("Forms");
                            handleLinkClick();
                          }}
                        >
                          Sales
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes("submitCustomerForm") && (
                      <li>
                        <Link
                          to="/forms/customer"
                          onClick={() => {
                            setSelectedSection("Forms");
                            handleLinkClick();
                          }}
                        >
                          Customer
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes("submitInventoryForm") && (
                      <li>
                        <Link
                          to="/forms/inventory"
                          onClick={() => {
                            setSelectedSection("Forms");
                            handleLinkClick();
                          }}
                        >
                          Inventory
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes("submitInventoryTrade") && (
                      <li>
                        <Link
                          to="/forms/inventoryTrade"
                          onClick={() => {
                            setSelectedSection("Forms");
                            handleLinkClick();
                          }}
                        >
                          Dealer Trade
                        </Link>
                      </li>
                    )}
                  </ul>
                )}
              </li>
            )}

            {/* Data Menu */}
            {user.permissions.includes("accessData") && (
              <li
                onClick={() => handleGroupToggle("Data")}
                className={`${
                  expandedGroup === "Data" ? "expanded" : ""
                } ${selectedSection === "Data" ? "selected" : ""}`}
              >
                <div className="side-nav-item">
                  <FontAwesomeIcon icon={faDatabase} className="icon" />
                  {isSidebarVisible && "Data"}
                </div>
                {expandedGroup === "Data" && isSidebarVisible && (
                  <ul className="nested">
                    {user.permissions.includes("viewUploadData") && (
                      <li>
                        <Link
                          to="/data/upload"
                          onClick={() => {
                            setSelectedSection("Data");
                            handleLinkClick();
                          }}
                        >
                          Upload
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes("viewImportData") && (
                      <li>
                        <Link
                          to="/data/import"
                          onClick={() => {
                            setSelectedSection("Data");
                            handleLinkClick();
                          }}
                        >
                          Import
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes("viewInventoryData") && (
                      <li>
                        <Link
                          to="/data/inventory"
                          onClick={() => {
                            setSelectedSection("Data");
                            handleLinkClick();
                          }}
                        >
                          Inventory
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes("viewPersonnelData") && (
                      <li>
                        <Link
                          to="/data/personnel"
                          onClick={() => {
                            setSelectedSection("Data");
                            handleLinkClick();
                          }}
                        >
                          Personnel
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes("viewPerformancePlanData") && (
                      <li>
                        <Link
                          to="/data/performance-plan"
                          onClick={() => {
                            setSelectedSection("Data");
                            handleLinkClick();
                          }}
                        >
                          Performance Plan
                        </Link>
                      </li>
                    )}
                  </ul>
                )}
              </li>
            )}

            {/* Leaders Menu */}
            {user.permissions.includes("accessLeaders") && (
              <li
                onClick={() => handleGroupToggle("Leaders")}
                className={`${
                  expandedGroup === "Leaders" ? "expanded" : ""
                } ${selectedSection === "Leaders" ? "selected" : ""}`}
              >
                <div className="side-nav-item">
                  <FontAwesomeIcon icon={faTrophy} className="icon" />
                  {isSidebarVisible && "Leaders"}
                </div>
                {expandedGroup === "Leaders" && isSidebarVisible && (
                  <ul className="nested">
                    {user.permissions.includes("viewVariableLeaders") && (
                      <li>
                        <Link
                          to="/leaders/variable"
                          onClick={() => {
                            setSelectedSection("Leaders");
                            handleLinkClick();
                          }}
                        >
                          Variable
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes("viewFixedLeaders") && (
                      <li>
                        <Link
                          to="/leaders/fixed"
                          onClick={() => {
                            setSelectedSection("Leaders");
                            handleLinkClick();
                          }}
                        >
                          Fixed
                        </Link>
                      </li>
                    )}
                  </ul>
                )}
              </li>
            )}

            {/* Reports Menu */}
            {user.permissions.includes("accessReports") && (
              <li
                onClick={() => handleGroupToggle("Reports")}
                className={`${
                  expandedGroup === "Reports" ? "expanded" : ""
                } ${selectedSection === "Reports" ? "selected" : ""}`}
              >
                <div className="side-nav-item">
                  <FontAwesomeIcon icon={faListOl} className="icon" />
                  {isSidebarVisible && "Reports"}
                </div>
                {expandedGroup === "Reports" && isSidebarVisible && (
                  <ul className="nested">
                    {user.permissions.includes("viewSalesReport") && (
                      <li>
                        <Link
                          to="/reports/sales"
                          onClick={() => {
                            setSelectedSection("Reports");
                            handleLinkClick();
                          }}
                        >
                          Sales Report
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes("viewTOReport") && (
                      <li>
                        <Link
                          to="/reports/to"
                          onClick={() => {
                            setSelectedSection("Reports");
                            handleLinkClick();
                          }}
                        >
                          TO Report
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes("viewTradeReport") && (
                      <li>
                        <Link
                          to="/reports/trade"
                          onClick={() => {
                            setSelectedSection("Reports");
                            handleLinkClick();
                          }}
                        >
                          Trade Report
                        </Link>
                      </li>
                    )}
                  </ul>
                )}
              </li>
            )}

            {/* Pay Menu */}
            {user.permissions.includes("accessPay") && (
              <li
                onClick={() => handleGroupToggle("Pay")}
                className={`${
                  expandedGroup === "Pay" ? "expanded" : ""
                } ${selectedSection === "Pay" ? "selected" : ""}`}
              >
                <div className="side-nav-item">
                  <FontAwesomeIcon icon={faMoneyBill} className="icon" />
                  {isSidebarVisible && "Pay"}
                </div>
                {expandedGroup === "Pay" && isSidebarVisible && (
                  <ul className="nested">
                    {user.permissions.includes("viewCompPlan") && (
                      <li>
                        <Link
                          to="/pay/compplan"
                          onClick={() => {
                            setSelectedSection("Pay");
                            handleLinkClick();
                          }}
                        >
                          Comp Plan
                        </Link>
                      </li>
                    )}
                    {/* {user.permissions.includes("viewFixedLeaders") && (
                      <li>
                        <Link
                          to="/leaders/fixed"
                          onClick={() => {
                            setSelectedSection("Leaders");
                            handleLinkClick();
                          }}
                        >
                          Fixed
                        </Link>
                      </li>
                    )} */}
                  </ul>
                )}
              </li>
            )}
          </ul>

          {/* Dark Mode Toggle Button at the Bottom */}
          <div className="side-nav-footer">
            <button
              onClick={toggleTheme}
              className="dark-mode-toggle-button"
              aria-label="Toggle Dark Mode"
            >
              <FontAwesomeIcon
                icon={isDarkMode ? faSun : faMoon}
                className="dark-mode-icon"
              />
              {isSidebarVisible && (
                <span className="dark-mode-text">
                  {isDarkMode ? "Light Mode" : "Dark Mode"}
                </span>
              )}
            </button>
          </div>
        </div>

        {/* Main Content Area */}
        <div className={`content ${isSidebarVisible ? "expanded" : "collapsed"}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
