// src/components/ProtectedRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ user, allowedRoles = [], allowedPermissions = [], children }) => {
  // If the user is not logged in, redirect to login page
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  // Check if user has one of the allowed roles
  if (allowedRoles.length > 0 && !allowedRoles.includes(user.userRole)) {
    return <Navigate to="/not-authorized" replace />;
  }

  // Check if user has at least one of the allowed permissions
  if (allowedPermissions.length > 0) {
    const hasPermission = allowedPermissions.some((permission) =>
      user.permissions.includes(permission)
    );
    if (!hasPermission) {
      return <Navigate to="/not-authorized" replace />;
    }
  }

  // User is authorized to view the component
  return children;
};

export default ProtectedRoute;

