// AccountingReportsDashboard.js
import React, { useState } from "react";
import UploadData from "../Data/UploadData"; // Your existing upload component
import FPReconPage from "../../components/Reports/FPReconPage"; // FP Recon report component
// import SalesReportPage from "../../components/Reports/SalesReportPage";
// import TradeReportPage from "../../components/Reports/TradeReportPage";
import "./AccountingReportsDashboard.css";

const AccountingReportsDashboard = () => {
  // State to control whether the UploadData component is shown.
  const [showUpload, setShowUpload] = useState(true);
  // State for selecting a report.
  const [selectedReport, setSelectedReport] = useState("FP_Recon");

  const renderReport = () => {
    switch (selectedReport) {
      case "FP_Recon":
        return <FPReconPage />;
      // case "SalesReport":
      //   return <SalesReportPage />;
      // case "TradeReport":
      //   return <TradeReportPage />;
      default:
        return <div>Please select a report.</div>;
    }
  };

  return (
    <div className="landing-page">
      <header className="landing-header">
        <h1>Accounting Reports Dashboard</h1>
        <p>
          Welcome to the accounting reports dashboard. Here you can view, analyze,
          and download your reports.
        </p>
        <div className="dashboard-controls">
          <button onClick={() => setShowUpload(!showUpload)}>
            {showUpload ? "Hide Upload Data" : "Show Upload Data"}
          </button>
        </div>
      </header>

      <main className="dashboard-content">
        {showUpload && (
          <section className="dashboard-section">
            <h2>Upload Data</h2>
            <UploadData />
          </section>
        )}

        <section className="dashboard-section">
          <h2>View Reports</h2>
          <div className="report-selector-container">
            <label htmlFor="reportType">Select Report:</label>
            <select
              id="reportType"
              value={selectedReport}
              onChange={(e) => setSelectedReport(e.target.value)}
            >
              <option value="FP_Recon">FP Recon Report</option>
              {/* <option value="SalesReport">Sales Report</option>
              <option value="TradeReport">Trade Report</option> */}
            </select>
          </div>
          <div className="report-container">{renderReport()}</div>
        </section>
      </main>

      <footer className="landing-footer">
        <p>&copy; {new Date().getFullYear()} OptiSight. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default AccountingReportsDashboard;
