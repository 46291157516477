// SalesForm.js

import React, { useState, useEffect, useMemo } from 'react';
import './SalesForm.css';

// A default form data object used when creating a new record.
const defaultFormData = {
  deal_date: new Date().toISOString().split('T')[0], // YYYY-MM-DD
  deal_number: '',
  customer_name_first: '',
  customer_name_last: '',
  vin_number: '',
  trade_vin_number: '', // <-- New field added for trade VIN
  stock_number: '',
  sale_type: '',
  incentive: '',
  front_gross: '',
  retail_price: '',
  test_drive: false,
  test_drive_count: '',
  make: '',
  model: '',
  sold_model_year: '',
  trade_make: '',
  trade_model: '',
  trade_model_year: '',
  trade_acv: '',
  trade_allowance: '',
  salesperson_1: '',
  salesperson_2: '',
  sales_manager: '',
  fi_producer: '',
  title: '',
  payoff: '',
  down_payment: '',
  term_in: '',
  term_out: '',
  rate_in: '',
  rate_out: '',
  pay_in: '',
  pay_out: '',
  reserve: '',
  product_1: '',
  product_2: '',
  product_3: '',
  product_4: '',
  product_5: '',
  payment_type: '',
  lender: '',
  back_gross: '',
  rdr_date: '',
};

// -------------------- Helper Functions --------------------

// Convert a date string from MM/DD/YYYY to YYYY-MM-DD.
// If dateStr is null, undefined, or not a string, return an empty string.
const convertDateFormat = (dateStr) => {
  if (!dateStr || typeof dateStr !== 'string') return "";
  if (dateStr.includes('/')) {
    const parts = dateStr.split('/');
    if (parts.length === 3) {
      const [month, day, year] = parts;
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }
  }
  return dateStr;
};

// If the value is an object with a "value" key (BigQuery sometimes returns objects),
// return that inner value; otherwise, return the value as is.
const displayValue = (val) =>
  val && typeof val === 'object' && 'value' in val ? val.value : val;

// -------------------- Component --------------------
const SalesForm = () => {
  // State initialization.
  const [formData, setFormData] = useState(defaultFormData);
  // Flag indicating whether we are editing an existing record.
  const [isEditing, setIsEditing] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [searchField, setSearchField] = useState('stock_number');
  const [showMetadata, setShowMetadata] = useState(true);
  const [showFrontSale, setShowFrontSale] = useState(true);
  const [showBackSale, setShowBackSale] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [salespeople, setSalespeople] = useState([]);
  const [fiProducers, setFiProducers] = useState([]);
  const [salesManagers, setSalesManagers] = useState([]);
  const [availableInventory, setAvailableInventory] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState('');

  // State for fetched sales records (latest 50 unique records)
  const [fetchedSales, setFetchedSales] = useState([]);
  // Control whether to show only the top 10 or all 50 records.
  const [showAllRecords, setShowAllRecords] = useState(false);
  // Collapsible sales table: collapsed by default.
  const [showSalesTable, setShowSalesTable] = useState(false);
  // State for table sorting.
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

  // New state for the metadata mapping
  const [metadataMapping, setMetadataMapping] = useState({});

  // -------------------- VIN Decoding Function --------------------
  // This function fetches data from the NHTSA API and populates the appropriate fields.
  // If the optional fieldPrefix is 'trade_', it sets trade_make, trade_model, and trade_model_year.
  // Otherwise, it sets make, model, and sold_model_year.
  const decodeVin = async (vin, fieldPrefix = '') => {
    try {
      const response = await fetch(`https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinValuesExtended/${vin}?format=json`);
      const data = await response.json();

      if (data.Results && data.Results.length > 0) {
        const result = data.Results[0];
        
        // Convert Make (e.g. "TOYOTA") to "Toyota" so it matches your dropdown list
        const capitalize = (str) => {
          if (!str) return "";
          return str[0].toUpperCase() + str.substring(1).toLowerCase();
        };

        const capitalizedMake = capitalize(result.Make);

        if (fieldPrefix === 'trade_') {
          setFormData((prev) => ({
            ...prev,
            trade_make: capitalizedMake || "",
            trade_model: result.Model || "",
            trade_model_year: result.ModelYear || "",
          }));
        } else {
          setFormData((prev) => ({
            ...prev,
            make: capitalizedMake || "",
            model: result.Model || "",
            sold_model_year: result.ModelYear || "",
          }));
        }
      }
    } catch (error) {
      console.error("Error decoding VIN:", error);
    }
  };

  // -------------------- Data Fetching --------------------
  useEffect(() => {
    const fetchAvailableInventory = async () => {
      const token = localStorage.getItem('accessToken');
      if (!token) return;
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/available-inventory`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.ok) {
          const data = await response.json();
          setAvailableInventory(data);
        }
      } catch (error) {
        console.error('Error fetching available inventory:', error);
      }
    };
    fetchAvailableInventory();
  }, []);

  useEffect(() => {
    const fetchMaxDealNumber = async () => {
      const token = localStorage.getItem('accessToken');
      if (!token) return;
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/max-deal-number`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.ok) {
          const data = await response.json();
          const maxDealNumber = data.maxDealNumber || 0;
          // For new records, use a new deal_number.
          if (!isEditing) {
            setFormData((prevState) => ({ ...prevState, deal_number: maxDealNumber + 1 }));
          }
        }
      } catch (error) {
        console.error('Error fetching max deal number:', error);
      }
    };
    fetchMaxDealNumber();
  }, [isEditing]);

  useEffect(() => {
    const fetchPersonnelByRole = async (role, setStateFn) => {
      const token = localStorage.getItem('accessToken');
      if (!token) return;
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/personnel-by-role?role=${role}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.ok) {
          const data = await response.json();
          setStateFn(data);
        }
      } catch (error) {
        console.error(`Error fetching ${role}:`, error);
      }
    };
    fetchPersonnelByRole('salesperson', setSalespeople);
    fetchPersonnelByRole('fi producer', setFiProducers);
    fetchPersonnelByRole('sales manager', setSalesManagers);
  }, []);

  const fetchLatestSalesRecords = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) return;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/sales-records`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.ok) {
        const data = await response.json();
        setFetchedSales(data);
      }
    } catch (error) {
      console.error('Error fetching sales records:', error);
    }
  };

  useEffect(() => {
    fetchLatestSalesRecords();
  }, []);

  useEffect(() => {
    const fetchMetadata = async () => {
      const token = localStorage.getItem('accessToken');
      if (!token) return;
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/metadata`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.ok) {
          const data = await response.json();
          setMetadataMapping(data);
        }
      } catch (error) {
        console.error("Error fetching metadata:", error);
      }
    };
    fetchMetadata();
  }, []);  

  // -------------------- Sorting --------------------
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortValue = (record, key) => {
    if (key === 'customer') {
      const customer =
        (displayValue(record.customer_name_first) || '') +
        ' ' +
        (displayValue(record.customer_name_last) || '');
      return customer.toLowerCase();
    } else if (key === 'deal_date') {
      return new Date(displayValue(record.deal_date)).getTime();
    } else if (key === 'deal_number') {
      return Number(displayValue(record.deal_number));
    } else {
      const val = displayValue(record[key]);
      return typeof val === 'string' ? val.toLowerCase() : val;
    }
  };

  const sortedRecords = useMemo(() => {
    let records = [...fetchedSales];
    if (sortConfig.key) {
      records.sort((a, b) => {
        const aVal = getSortValue(a, sortConfig.key);
        const bVal = getSortValue(b, sortConfig.key);
        if (aVal < bVal) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (aVal > bVal) return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
      });
    }
    return records;
  }, [fetchedSales, sortConfig]);

  const displayedRecords = showAllRecords ? sortedRecords : sortedRecords.slice(0, 10);

  // -------------------- Event Handlers --------------------
  const handleInventorySelect = (e) => {
    const selectedVin = e.target.value;
    const selectedItem = availableInventory.find((item) => item.vin_number === selectedVin);
    if (selectedItem) {
      setFormData({
        ...formData,
        sale_type: selectedItem.sale_type || "",
        stock_number: selectedItem.stock_number || "",
        vin_number: selectedItem.vin_number || "",
        make: selectedItem.make || "",
        model: selectedItem.model || "",
        sold_model_year: selectedItem.model_year || "",
      });
    }
    setSelectedInventory(selectedVin);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'rate_in' || name === 'rate_out') {
      const numericValue = value.replace(/[^0-9.]/g, '');
      if (numericValue === "" || (/^\d+(\.\d{0,2})?$/.test(numericValue) && parseFloat(numericValue) <= 100)) {
        setFormData({ ...formData, [name]: numericValue });
      }
    } else {
      setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
    }
  };

  useEffect(() => {
    const calculateBackGross = () => {
      const reserve = parseFloat(formData.reserve) || 0;
      const products = [
        parseFloat(formData.product_1) || 0,
        parseFloat(formData.product_2) || 0,
        parseFloat(formData.product_3) || 0,
        parseFloat(formData.product_4) || 0,
        parseFloat(formData.product_5) || 0,
      ];
      const backGross = reserve + products.reduce((acc, curr) => acc + curr, 0);
      setFormData({ ...formData, back_gross: backGross.toFixed(2) });
    };
    calculateBackGross();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData.reserve,
    formData.product_1,
    formData.product_2,
    formData.product_3,
    formData.product_4,
    formData.product_5,
  ]);

  // -------------------- Submission Handler --------------------
  // Every submission calls the POST endpoint.
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('accessToken');
    if (!token) return;
  
    // Validate required fields.
    if (!formData.deal_date || !formData.deal_number || !formData.vin_number || !formData.stock_number) {
      alert('Please provide Deal Date, Deal Number, VIN Number, and Stock Number.');
      return;
    }
  
    // Convert deal_date to YYYY-MM-DD (if necessary).
    const formattedDealDate = convertDateFormat(formData.deal_date.trim());
    const rateInDecimal = parseFloat(formData.rate_in) / 100;
    const rateOutDecimal = parseFloat(formData.rate_out) / 100;
  
    const formatDate = (dateStr) => {
      if (!dateStr || dateStr.trim() === "") return null;
      return convertDateFormat(dateStr.trim());
    };
  
    const formattedFormData = {
      ...formData,
      deal_date: formattedDealDate,
      rate_in: isNaN(rateInDecimal) ? null : rateInDecimal,
      rate_out: isNaN(rateOutDecimal) ? null : rateOutDecimal,
      rdr_date: formData.rdr_date ? formatDate(formData.rdr_date) : null,
      submitted_at: new Date().toISOString(),
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/submit-sales`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ formData: formattedFormData }),
      });
  
      if (response.ok) {
        alert('Sales data processed successfully.');
  
        // Scroll the window to the top.
        window.scrollTo({ top: 0, behavior: 'smooth' });
  
        // Refresh the Latest Sales Records table.
        fetchLatestSalesRecords();
  
        // Clear the form only if this is a new record.
        if (!isEditing) {
          clearForm();
        }
      } else {
        alert('Failed to process sales data. Please try again.');
      }
    } catch (error) {
      console.error('Error processing form:', error);
      alert('An error occurred while processing the form.');
    }
  };  

  // -------------------- Search Handler --------------------
  const handleSearch = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) return;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/fetch-latest-sales-record?${searchField}=${searchValue}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        if (data && data.length > 0) {
          const latestRecord = data[0];
          // Simply copy the record's values into the form fields.
          setFormData({
            deal_date: latestRecord.deal_date ? convertDateFormat(latestRecord.deal_date) : "",
            deal_number: latestRecord.deal_number || "",
            customer_name_first: latestRecord.customer_name_first || "",
            customer_name_last: latestRecord.customer_name_last || "",
            vin_number: latestRecord.vin_number || "",
            trade_vin_number: latestRecord.trade_vin_number || "",
            stock_number: latestRecord.stock_number || "",
            sale_type: latestRecord.sale_type || "",
            incentive: latestRecord.incentive || "",
            front_gross: latestRecord.front_gross || "",
            retail_price: latestRecord.retail_price || "",
            test_drive: latestRecord.test_drive || false,
            test_drive_count: latestRecord.test_drive_count || "",
            make: latestRecord.make || "",
            model: latestRecord.model || "",
            sold_model_year: latestRecord.sold_model_year || "",
            trade_make: latestRecord.trade_make || "",
            trade_model: latestRecord.trade_model || "",
            trade_model_year: latestRecord.trade_model_year || "",
            trade_acv: latestRecord.trade_acv || "",
            trade_allowance: latestRecord.trade_allowance || "",
            salesperson_1: latestRecord.salesperson_1 || "",
            salesperson_2: latestRecord.salesperson_2 || "",
            sales_manager: latestRecord.sales_manager || "",
            fi_producer: latestRecord.fi_producer || "",
            title: latestRecord.title || "",
            payoff: latestRecord.payoff || "",
            down_payment: latestRecord.down_payment || "",
            term_in: latestRecord.term_in || "",
            term_out: latestRecord.term_out || "",
            rate_in: latestRecord.rate_in !== null ? (latestRecord.rate_in * 100).toFixed(2) : "",
            rate_out: latestRecord.rate_out !== null ? (latestRecord.rate_out * 100).toFixed(2) : "",
            pay_in: latestRecord.pay_in || "",
            pay_out: latestRecord.pay_out || "",
            reserve: latestRecord.reserve || "",
            product_1: latestRecord.product_1 || "",
            product_2: latestRecord.product_2 || "",
            product_3: latestRecord.product_3 || "",
            product_4: latestRecord.product_4 || "",
            product_5: latestRecord.product_5 || "",
            payment_type: latestRecord.payment_type || "",
            lender: latestRecord.lender || "",
            back_gross: latestRecord.back_gross || "",
            rdr_date: latestRecord.rdr_date ? convertDateFormat(latestRecord.rdr_date) : "",
          });
          setIsEditing(true);
        } else {
          alert('No records found');
        }
      }
    } catch (error) {
      console.error('Error fetching sales record:', error);
    }
  };

  // -------------------- Clear Form Handler --------------------
  const clearForm = () => {
    const fetchNewDealNumber = async () => {
      const token = localStorage.getItem('accessToken');
      if (!token) return;
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/max-deal-number`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.ok) {
          const data = await response.json();
          const maxDealNumber = data.maxDealNumber || 0;
          setFormData({ ...defaultFormData, deal_number: maxDealNumber + 1 });
          setIsEditing(false);
        }
      } catch (error) {
        console.error('Error fetching max deal number during form reset:', error);
      }
    };
    fetchNewDealNumber();
  };

  const toggleShowAllRecords = () => {
    setShowAllRecords((prev) => !prev);
  };

  // Toggle the sales table (collapse/expand) when clicking its header.
  const toggleSalesTable = () => {
    setShowSalesTable((prev) => !prev);
  };

  // -------------------- Record Selection --------------------
  // Simply copy all the fields from the record into the form.
  const handleSelectSalesRecord = (record) => {
    setFormData({
      deal_date: record.deal_date ? convertDateFormat(displayValue(record.deal_date)) : "",
      deal_number: record.deal_number || "",
      customer_name_first: record.customer_name_first || "",
      customer_name_last: record.customer_name_last || "",
      vin_number: record.vin_number || "",
      trade_vin_number: record.trade_vin_number || "",
      stock_number: record.stock_number || "",
      sale_type: record.sale_type || "",
      incentive: record.incentive || "",
      front_gross: record.front_gross || "",
      retail_price: record.retail_price || "",
      test_drive: record.test_drive || false,
      test_drive_count: record.test_drive_count || "",
      make: record.make || "",
      model: record.model || "",
      sold_model_year: record.sold_model_year || "",
      trade_make: record.trade_make || "",
      trade_model: record.trade_model || "",
      trade_model_year: record.trade_model_year || "",
      trade_acv: record.trade_acv || "",
      trade_allowance: record.trade_allowance || "",
      salesperson_1: record.salesperson_1 || "",
      salesperson_2: record.salesperson_2 || "",
      sales_manager: record.sales_manager || "",
      fi_producer: record.fi_producer || "",
      title: record.title || "",
      payoff: record.payoff || "",
      down_payment: record.down_payment || "",
      term_in: record.term_in || "",
      term_out: record.term_out || "",
      rate_in: record.rate_in !== null ? (record.rate_in * 100).toFixed(2) : "",
      rate_out: record.rate_out !== null ? (record.rate_out * 100).toFixed(2) : "",
      pay_in: record.pay_in || "",
      pay_out: record.pay_out || "",
      reserve: record.reserve || "",
      product_1: record.product_1 || "",
      product_2: record.product_2 || "",
      product_3: record.product_3 || "",
      product_4: record.product_4 || "",
      product_5: record.product_5 || "",
      payment_type: record.payment_type || "",
      lender: record.lender || "",
      back_gross: record.back_gross || "",
      rdr_date: record.rdr_date ? convertDateFormat(displayValue(record.rdr_date)) : "",
    });
    setIsEditing(true);
  };

  // -------------------- Table Columns --------------------
  const columns = [
    { key: 'deal_date', label: 'Deal Date' },
    { key: 'deal_number', label: 'Deal Number' },
    { key: 'customer', label: 'Customer' },
    { key: 'vin_number', label: 'VIN' },
    { key: 'stock_number', label: 'Stock Number' },
    { key: 'sale_type', label: 'Sale Type' },
  ];

  return (
    <div>
      <form onSubmit={handleSubmit} className="sales-form">
        <h2>Sales Form</h2>

        {/* Toggle Search Section */}
        <button type="button" onClick={() => setShowSearch(!showSearch)}>
          {showSearch ? "Hide Search" : "Show Search"}
        </button>

        {/* Search Section: dropdown and input side by side */}
        {showSearch && (
          <div className="search-section">
            <div className="search-input-group">
              <select
                value={searchField}
                onChange={(e) => setSearchField(e.target.value)}
              >
                <option value="stock_number">Stock Number</option>
                <option value="deal_number">Deal Number</option>
                <option value="vin_number">VIN Number</option>
              </select>
              <input
                type="text"
                placeholder={`Enter ${searchField}`}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <div className="button-container">
              <button type="button" onClick={handleSearch}>
                Search
              </button>
              <div className="button-divider"></div>
              <button type="button" onClick={clearForm}>
                Clear Form
              </button>
            </div>
          </div>
        )}

        {/* Inventory Selection */}
        <div className="inventory-selection">
          <label>
            Select Inventory:
            <select value={selectedInventory} onChange={handleInventorySelect}>
              <option value="">Select an Inventory Item</option>
              {availableInventory.map((item) => (
                <option key={item.vin_number} value={item.vin_number}>
                  {item.make} {item.model} ({item.model_year}) -{" "}
                  {item.stock_number}
                </option>
              ))}
            </select>
          </label>
        </div>

        {/* Collapsible Latest Sales Records Table */}
        <h2 onClick={toggleSalesTable} style={{ cursor: "pointer" }}>
          Latest Sales Records {showSalesTable ? "▼" : "►"}
        </h2>
        {showSalesTable && (
          <div className="fetched-sales-table">
            <table>
              <thead>
                <tr>
                  {columns.map((col) => (
                    <th
                      key={col.key}
                      onClick={() => handleSort(col.key)}
                      style={{ cursor: "pointer" }}
                    >
                      {col.label}
                      {sortConfig.key === col.key
                        ? sortConfig.direction === "ascending"
                          ? " ▲"
                          : " ▼"
                        : null}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {displayedRecords.map((record) => (
                  <tr
                    key={displayValue(record.deal_number)}
                    onClick={() => handleSelectSalesRecord(record)}
                    className="table-row"
                  >
                    <td>{displayValue(record.deal_date)}</td>
                    <td>{displayValue(record.deal_number)}</td>
                    <td>
                      {displayValue(record.customer_name_first)}{" "}
                      {displayValue(record.customer_name_last)}
                    </td>
                    <td>{displayValue(record.vin_number)}</td>
                    <td>{displayValue(record.stock_number)}</td>
                    <td>{displayValue(record.sale_type)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <p className="note">* Click on a row to edit that entry.</p>
            {sortedRecords.length > 10 && (
              <button type="button" onClick={toggleShowAllRecords}>
                {showAllRecords ? "Show Less" : "Show More"}
              </button>
            )}
          </div>
        )}

        {/* Metadata Section */}
        <section className="form-section">
          <h3
            className="section-header"
            onClick={() => setShowMetadata(!showMetadata)}
          >
            Metadata {showMetadata ? "▼" : "►"}
          </h3>
          {showMetadata && (
            <div className="table-layout">
              <label>
                Deal Date:
                <input
                  type="date"
                  name="deal_date"
                  value={formData.deal_date}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Deal Number:
                <input
                  type="number"
                  name="deal_number"
                  value={formData.deal_number}
                  onChange={handleChange}
                  readOnly
                />
              </label>
              <label>
                First Name:
                <input
                  type="text"
                  name="customer_name_first"
                  value={formData.customer_name_first}
                  onChange={handleChange}
                />
              </label>
              <label>
                Last Name:
                <input
                  type="text"
                  name="customer_name_last"
                  value={formData.customer_name_last}
                  onChange={handleChange}
                />
              </label>
              <label>
                VIN Number:
                <input
                  type="text"
                  name="vin_number"
                  value={formData.vin_number}
                  onChange={handleChange}
                  onBlur={() => { if(formData.vin_number) decodeVin(formData.vin_number) }}
                  required
                />
              </label>
              <label>
                Stock Number:
                <input
                  type="text"
                  name="stock_number"
                  value={formData.stock_number}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Sale Type:
                <select
                  name="sale_type"
                  value={formData.sale_type}
                  onChange={handleChange}
                >
                  <option value=""></option>
                  <option value="New">New</option>
                  <option value="Used">Used</option>
                  <option value="Wholesale">Wholesale</option>
                </select>
              </label>
              <label>
                RDR Date:
                <input
                  type="date"
                  name="rdr_date"
                  value={formData.rdr_date}
                  onChange={handleChange}
                />
              </label>
            </div>
          )}
        </section>

        {/* Front Sale Section */}
        <section className="form-section">
          <h3
            className="section-header"
            onClick={() => setShowFrontSale(!showFrontSale)}
          >
            Front Sale {showFrontSale ? "▼" : "►"}
          </h3>
          {showFrontSale && (
            <div className="table-layout">
              <label>
                Incentive:
                <input
                  type="number"
                  name="incentive"
                  value={formData.incentive}
                  onChange={handleChange}
                />
              </label>
              <label>
                Front Gross:
                <input
                  type="number"
                  name="front_gross"
                  value={formData.front_gross}
                  onChange={handleChange}
                />
              </label>
              <label>
                Retail Price:
                <input
                  type="number"
                  name="retail_price"
                  value={formData.retail_price}
                  onChange={handleChange}
                />
              </label>
              <label>
                Test Drive:
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="test_drive"
                    checked={formData.test_drive}
                    onChange={handleChange}
                  />
                </div>
              </label>
              <label>
                Test Drive Count:
                <input
                  type="number"
                  name="test_drive_count"
                  value={formData.test_drive_count}
                  onChange={handleChange}
                />
              </label>
              {/* New Trade VIN Number input with onBlur to decode trade details */}
              <label>
                Trade VIN Number:
                <input
                  type="text"
                  name="trade_vin_number"
                  value={formData.trade_vin_number}
                  onChange={handleChange}
                  onBlur={() => { if(formData.trade_vin_number) decodeVin(formData.trade_vin_number, 'trade_') }}
                />
              </label>
              <label>
                Make:
                <input
                  type="text"
                  name="make"
                  value={formData.make}
                  onChange={handleChange}
                />
              </label>
              <label>
                Model:
                <input
                  type="text"
                  name="model"
                  value={formData.model}
                  onChange={handleChange}
                />
              </label>
              <label>
                Sold Model Year:
                <input
                  type="number"
                  name="sold_model_year"
                  value={formData.sold_model_year}
                  onChange={handleChange}
                />
              </label>
              <label>
                Trade Make:
                <input
                  type="text"
                  name="trade_make"
                  value={formData.trade_make}
                  onChange={handleChange}
                />
              </label>
              {formData.trade_make && (
                <>
                  <label>
                    Trade Model:
                    <input
                      type="text"
                      name="trade_model"
                      value={formData.trade_model}
                      onChange={handleChange}
                    />
                  </label>
                  <label>
                    Trade Model Year:
                    <input
                      type="number"
                      name="trade_model_year"
                      value={formData.trade_model_year}
                      onChange={handleChange}
                    />
                  </label>
                  <label>
                    Trade ACV:
                    <input
                      type="number"
                      name="trade_acv"
                      value={formData.trade_acv}
                      onChange={handleChange}
                    />
                  </label>
                  <label>
                    Trade Allowance:
                    <input
                      type="number"
                      name="trade_allowance"
                      value={formData.trade_allowance}
                      onChange={handleChange}
                    />
                  </label>
                </>
              )}
              <label>
                Salesperson 1:
                <select
                  name="salesperson_1"
                  value={formData.salesperson_1}
                  onChange={handleChange}
                >
                  <option value="">Select Salesperson</option>
                  {salespeople.map((person, index) => (
                    <option
                      key={index}
                      value={`${person.employee_name_first} ${person.employee_name_last}`}
                    >
                      {`${person.employee_name_first} ${person.employee_name_last}`}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Salesperson 2:
                <select
                  name="salesperson_2"
                  value={formData.salesperson_2}
                  onChange={handleChange}
                >
                  <option value="">Select Salesperson</option>
                  {salespeople.map((person, index) => (
                    <option
                      key={index}
                      value={`${person.employee_name_first} ${person.employee_name_last}`}
                    >
                      {`${person.employee_name_first} ${person.employee_name_last}`}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Sales Manager:
                <select
                  name="sales_manager"
                  value={formData.sales_manager}
                  onChange={handleChange}
                >
                  <option value="">Select Sales Manager</option>
                  {salesManagers.map((person, index) => (
                    <option
                      key={index}
                      value={`${person.employee_name_first} ${person.employee_name_last}`}
                    >
                      {`${person.employee_name_first} ${person.employee_name_last}`}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          )}
        </section>

        {/* Back Sale Section */}
        <section className="form-section">
          <h3
            className="section-header"
            onClick={() => setShowBackSale(!showBackSale)}
          >
            Back Sale {showBackSale ? "▼" : "►"}
          </h3>
          {showBackSale && (
            <div className="table-layout">
              <label>
                FI Producer:
                <select
                  name="fi_producer"
                  value={formData.fi_producer}
                  onChange={handleChange}
                >
                  <option value="">Select FI Producer</option>
                  {fiProducers.map((person, index) => (
                    <option
                      key={index}
                      value={`${person.employee_name_first} ${person.employee_name_last}`}
                    >
                      {`${person.employee_name_first} ${person.employee_name_last}`}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Payment Type:
                <select
                  name="payment_type"
                  value={formData.payment_type}
                  onChange={handleChange}
                >
                  <option value="">Select Payment Type</option>
                  <option value="Cash">Cash</option>
                  <option value="Finance">Finance</option>
                  <option value="Lease">Lease</option>
                </select>
              </label>
              {formData.payment_type !== "Cash" && (
                <>
                  <label>
                    Down Payment:
                    <input
                      type="number"
                      name="down_payment"
                      value={formData.down_payment}
                      onChange={handleChange}
                    />
                  </label>
                  <label>
                    Lender:
                    <input
                      type="text"
                      name="lender"
                      value={formData.lender}
                      onChange={handleChange}
                    />
                  </label>
                  <label>
                    Term In:
                    <input
                      type="number"
                      name="term_in"
                      value={formData.term_in}
                      onChange={handleChange}
                    />
                  </label>
                  <label>
                    Term Out:
                    <input
                      type="number"
                      name="term_out"
                      value={formData.term_out}
                      onChange={handleChange}
                    />
                  </label>
                  <label className="percentage-label">
                    Rate In:
                    <div className="percentage-input-container">
                      <input
                        type="text"
                        name="rate_in"
                        value={formData.rate_in}
                        onChange={handleChange}
                        placeholder="e.g., 12.5%"
                      />
                      <span className="percentage-symbol">%</span>
                    </div>
                  </label>
                  <label className="percentage-label">
                    Rate Out:
                    <div className="percentage-input-container">
                      <input
                        type="text"
                        name="rate_out"
                        value={formData.rate_out}
                        onChange={handleChange}
                        placeholder="e.g., 8.75%"
                      />
                      <span className="percentage-symbol">%</span>
                    </div>
                  </label>
                  <label>
                    Pay In:
                    <input
                      type="number"
                      name="pay_in"
                      value={formData.pay_in}
                      onChange={handleChange}
                    />
                  </label>
                  <label>
                    Pay Out:
                    <input
                      type="number"
                      name="pay_out"
                      value={formData.pay_out}
                      onChange={handleChange}
                    />
                  </label>
                  <label>
                    Reserve:
                    <input
                      type="number"
                      name="reserve"
                      value={formData.reserve}
                      onChange={handleChange}
                    />
                  </label>
                </>
              )}
              {formData.trade_make && (
                <>
                  <label>
                    Title:
                    <select
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                    >
                      <option value="">Select Title</option>
                      <option value="Clean">Clean</option>
                      <option value="Salvage">Salvage</option>
                    </select>
                  </label>
                  <label>
                    Payoff:
                    <input
                      type="number"
                      name="payoff"
                      value={formData.payoff}
                      onChange={handleChange}
                    />
                  </label>
                </>
              )}
              <label>
                {metadataMapping.product_1 ? metadataMapping.product_1 : "Product 1"}:
                <input
                  type="number"
                  name="product_1"
                  value={formData.product_1}
                  onChange={handleChange}
                />
              </label>
              <label>
                {metadataMapping.product_2 ? metadataMapping.product_2 : "Product 2"}:
                <input
                  type="number"
                  name="product_2"
                  value={formData.product_2}
                  onChange={handleChange}
                />
              </label>
              <label>
                {metadataMapping.product_3 ? metadataMapping.product_3: "Product 3"}:
                <input
                  type="number"
                  name="product_3"
                  value={formData.product_3}
                  onChange={handleChange}
                />
              </label>
              <label>
              {metadataMapping.product_4 ? metadataMapping.product_4: "Product 4"}:
                <input
                  type="number"
                  name="product_4"
                  value={formData.product_4}
                  onChange={handleChange}
                />
              </label>
              <label>
              {metadataMapping.product_5 ? metadataMapping.product_5: "Product 5"}:
                <input
                  type="number"
                  name="product_5"
                  value={formData.product_5}
                  onChange={handleChange}
                />
              </label>
              {formData.payment_type !== "Cash" && (
                <label>
                  Reserve:
                  <input
                    type="number"
                    name="reserve"
                    value={formData.reserve}
                    onChange={handleChange}
                  />
                </label>
              )}
              <label>
                Back Gross:
                <input
                  type="number"
                  name="back_gross"
                  value={formData.back_gross}
                  readOnly
                />
              </label>
            </div>
          )}
        </section>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default SalesForm;
