/* TOReport.js */

import React, { useEffect, useState, useRef, useMemo } from 'react';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable'; // Import the plugin
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'; // Import sort icons
import './TOReport.css';

const TOReport = ({ user }) => {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const dropdownRef = useRef(null);

  // Fetch Report Data from Backend
  const fetchReportData = async () => {
    if (!user || !user.token) {
      console.error("User token is missing");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/to-report?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            'Authorization': `Bearer ${user.token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch report data');
      }
      const fetchedData = await response.json();

      // The backend already filters out records with blank manager names.
      setData(fetchedData);
    } catch (error) {
      console.error('Error fetching report data:', error);
      alert('Failed to fetch report data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch data when date range changes
  useEffect(() => {
    if (startDate && endDate) {
      fetchReportData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    if (dropdownVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownVisible]);

  // Helper function to render cell data safely
  const renderCell = (cellData) => {
    if (cellData === null || cellData === undefined) return '';
    return cellData.toString();
  };

  // Process data to aggregate manager stats
  const processData = useMemo(() => {
    const managerStats = {};
    let noTOSoldCount = 0;
    let noTOCount = 0;

    data.forEach(row => {
      const { manager_name, deal_number, TO_time } = row;

      if (manager_name === "No TO") {
        noTOCount += 1;
        if (deal_number) {
          noTOSoldCount += 1;
        }
      } else {
        if (!managerStats[manager_name]) {
          managerStats[manager_name] = {
            total_tos: 0,
            sold: 0,
            single_to: 0,
            double_to: 0,
            triple_to: 0,
            homerun_to: 0,
          };
        }

        managerStats[manager_name].total_tos += 1;

        if (deal_number) {
          managerStats[manager_name].sold += 1;
        }

        // Increment TO levels based on TO_time
        switch (TO_time) {
          case 1:
            managerStats[manager_name].single_to += 1;
            break;
          case 2:
            managerStats[manager_name].double_to += 1;
            break;
          case 3:
            managerStats[manager_name].triple_to += 1;
            break;
          case 4:
            managerStats[manager_name].homerun_to += 1;
            break;
          default:
            break; // If TO_time is undefined or invalid
        }
      }
    });

    return { managerStats, noTOCount, noTOSoldCount };
  }, [data]);

  // Calculate Totals
  const calculateTotals = useMemo(() => {
    const { managerStats } = processData;
    let total_tos = 0;
    let sold = 0;
    let single_to = 0;
    let double_to = 0;
    let triple_to = 0;
    let homerun_to = 0;

    Object.values(managerStats).forEach(stats => {
      total_tos += stats.total_tos;
      sold += stats.sold;
      single_to += stats.single_to;
      double_to += stats.double_to;
      triple_to += stats.triple_to;
      homerun_to += stats.homerun_to;
    });

    const closing_percent = total_tos > 0 ? ((sold / total_tos) * 100).toFixed(1) : '0.0';

    return { total_tos, sold, closing_percent, single_to, double_to, triple_to, homerun_to };
  }, [processData]);

  // Sorting Function
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Sorted Manager Data
  const sortedManagerData = useMemo(() => {
    const sortableData = Object.entries(processData.managerStats).map(([manager, stats]) => ({
      manager,
      ...stats,
    }));

    if (sortConfig.key !== null) {
      sortableData.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // If sorting by manager name, compare as strings
        if (sortConfig.key === 'manager') {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
          if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
          if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
          return 0;
        }

        // For numerical values
        if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
      });
    }

    return sortableData;
  }, [processData, sortConfig]);

  // Get sort indicator icon
  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return null;
    }
    return sortConfig.direction === 'ascending' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
  };

  // Download CSV Function
  const downloadCSV = () => {
    if (data.length === 0) {
      alert('No data available to download.');
      return;
    }

    const { noTOCount, noTOSoldCount } = processData;
    const totals = calculateTotals;

    const headers = [
      'Manager', 
      'Total T/Os', 
      'Sold', 
      'Closing %',
      'Single TO',
      'Double TO',
      'Triple TO',
      'Homerun TO'
    ];

    const rows = sortedManagerData.map(item => [
      `"${renderCell(item.manager)}"`,
      item.total_tos,
      item.sold,
      `${(item.total_tos > 0 ? ((item.sold / item.total_tos) * 100).toFixed(1) : '0.0')}%`,
      item.single_to,
      item.double_to,
      item.triple_to,
      item.homerun_to,
    ]);

    // Add Totals Row
    rows.push([
      '"Total"',
      totals.total_tos,
      totals.sold,
      `${totals.closing_percent}%`,
      totals.single_to,
      totals.double_to,
      totals.triple_to,
      totals.homerun_to,
    ]);

    // Add No TO Row
    rows.push([
      '"No TO"',
      noTOCount, // Total T/Os for No TO
      noTOSoldCount, // Sold
      `${(processData.noTOCount > 0 ? ((processData.noTOSoldCount / processData.noTOCount) * 100).toFixed(1) : '0.0')}%`, // Closing %
      '', // Single TO
      '', // Double TO
      '', // Triple TO
      '', // Homerun TO
    ]);

    const csvContent = [headers, ...rows].map(e => e.join(',')).join('\n');

    // Create a blob and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `TO_Report_${startDate}_to_${endDate}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Hide the dropdown after download
    setDropdownVisible(false);
  };

  // Download PDF Function using jspdf-autotable
  const downloadPDF = () => {
    if (data.length === 0) {
      alert('No data available to download.');
      return;
    }

    const doc = new jsPDF();

    // Set the title
    doc.setFontSize(18);
    doc.text('Turnover Report', 14, 22);

    const { noTOCount, noTOSoldCount } = processData;
    const totals = calculateTotals;

    // Define the table columns
    const tableColumn = [
      'Manager', 
      'Total T/Os', 
      'Sold', 
      'Closing %',
      'Single TO',
      'Double TO',
      'Triple TO',
      'Homerun TO'
    ];

    // Format the table rows
    const tableRows = sortedManagerData.map(item => [
      renderCell(item.manager),
      item.total_tos.toString(),
      item.sold.toString(),
      `${(item.total_tos > 0 ? ((item.sold / item.total_tos) * 100).toFixed(1) : '0.0')}%`,
      item.single_to.toString(),
      item.double_to.toString(),
      item.triple_to.toString(),
      item.homerun_to.toString(),
    ]);

    // Add Totals Row
    tableRows.push([
      'Total',
      totals.total_tos.toString(),
      totals.sold.toString(),
      `${totals.closing_percent}%`,
      totals.single_to.toString(),
      totals.double_to.toString(),
      totals.triple_to.toString(),
      totals.homerun_to.toString(),
    ]);

    // Add No TO Row
    tableRows.push([
      'No TO',
      noTOCount.toString(), // Total T/Os for No TO
      noTOSoldCount.toString(), // Sold
      `${(processData.noTOCount > 0 ? ((processData.noTOSoldCount / processData.noTOCount) * 100).toFixed(1) : '0.0')}%`, // Closing %
      '', // Single TO
      '', // Double TO
      '', // Triple TO
      '', // Homerun TO
    ]);

    // Add the table using autoTable
    autoTable(doc, {
      startY: 30,
      head: [tableColumn],
      body: tableRows,
      theme: 'striped', // Options: 'striped', 'grid', 'plain'
      headStyles: {
        fillColor: [0, 123, 255], // Bootstrap primary color
        textColor: [255, 255, 255], // White text
        fontStyle: 'bold',
      },
      styles: {
        fontSize: 10,
        cellPadding: 3,
        halign: 'center',
        valign: 'middle',
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Light gray for alternate rows
      },
      margin: { top: 30 },
      columnStyles: {
        0: { cellWidth: 40 }, // Manager
        1: { cellWidth: 20 }, // Total T/Os
        2: { cellWidth: 15 }, // Sold
        3: { cellWidth: 20 }, // Closing %
        4: { cellWidth: 20 }, // Single TO
        5: { cellWidth: 20 }, // Double TO
        6: { cellWidth: 20 }, // Triple TO
        7: { cellWidth: 25 }, // Homerun TO
      },
      didDrawPage: function (data) {
        // Footer with page numbers
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(10);
        doc.text(
          `Page ${doc.internal.getCurrentPageInfo().pageNumber} of ${pageCount}`,
          data.settings.margin.left,
          doc.internal.pageSize.height - 10
        );
      },
    });

    // Save the PDF
    doc.save(`TO_Report_${startDate}_to_${endDate}.pdf`);

    // Hide the dropdown after download
    setDropdownVisible(false);
  };

  // Toggle Dropdown Function
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <div className="to-report">
      <h1>Turnover Report</h1>

      <div className="controls-row">
        <label>
          Start Date:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            aria-label="Select Start Date"
          />
        </label>
        <label>
          End Date:
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            aria-label="Select End Date"
          />
        </label>
        <button onClick={fetchReportData} className="fetch-button" aria-label="Fetch Turnover Report">Fetch Report</button>
        <div className="export-section" ref={dropdownRef}>
          <button
            className="download-button"
            onClick={toggleDropdown}
            aria-haspopup="true"
            aria-expanded={dropdownVisible}
            aria-label="Download Turnover Report"
          >
            <FontAwesomeIcon icon={faDownload} style={{ marginRight: '5px' }} /> Download
          </button>
          {dropdownVisible && (
            <div className="dropdown-menu" role="menu">
              <button onClick={downloadCSV} className="dropdown-item" role="menuitem">CSV</button>
              <button onClick={downloadPDF} className="dropdown-item" role="menuitem">PDF</button>
            </div>
          )}
        </div>
      </div>

      {loading ? (
        <div className="loading-indicator">
          <p>Loading...</p>
          {/* You can add a spinner here if desired */}
        </div>
      ) : (
        <table className="report-table">
          <thead>
            <tr>
              <th scope="col" onClick={() => requestSort('manager')} style={{ cursor: 'pointer' }}>
                Manager {getSortIcon('manager')}
              </th>
              <th scope="col" onClick={() => requestSort('total_tos')} style={{ cursor: 'pointer' }}>
                Total T/Os {getSortIcon('total_tos')}
              </th>
              <th scope="col" onClick={() => requestSort('sold')} style={{ cursor: 'pointer' }}>
                Sold {getSortIcon('sold')}
              </th>
              <th scope="col" onClick={() => requestSort('closing_percent')} style={{ cursor: 'pointer' }}>
                Closing % {getSortIcon('closing_percent')}
              </th>
              <th scope="col">
                Single TO
              </th>
              <th scope="col">
                Double TO
              </th>
              <th scope="col">
                Triple TO
              </th>
              <th scope="col">
                Homerun TO
              </th>
            </tr>
          </thead>
          <tbody>
            {/* Render Manager Rows */}
            {sortedManagerData.map((item, index) => (
              <tr key={`manager-${item.manager}-${index}`}>
                <td>{renderCell(item.manager)}</td>
                <td>{item.total_tos}</td>
                <td>{item.sold}</td>
                <td>{item.total_tos > 0 ? `${((item.sold / item.total_tos) * 100).toFixed(1)}%` : '0.0%'}</td>
                <td>{item.single_to}</td>
                <td>{item.double_to}</td>
                <td>{item.triple_to}</td>
                <td>{item.homerun_to}</td>
              </tr>
            ))}

            {/* Totals Row */}
            {data.length > 0 && (
              <tr className="totals-row">
                <td><strong>Total</strong></td>
                <td><strong>{calculateTotals.total_tos}</strong></td>
                <td><strong>{calculateTotals.sold}</strong></td>
                <td><strong>{calculateTotals.closing_percent}%</strong></td>
                <td><strong>{calculateTotals.single_to}</strong></td>
                <td><strong>{calculateTotals.double_to}</strong></td>
                <td><strong>{calculateTotals.triple_to}</strong></td>
                <td><strong>{calculateTotals.homerun_to}</strong></td>
              </tr>
            )}

            {/* No TO Row */}
            {processData.noTOCount > 0 && (
              <tr className="no-to-row">
                <td><strong>No TO</strong></td>
                <td>{processData.noTOCount}</td>
                <td>{processData.noTOSoldCount}</td>
                <td>{processData.noTOCount > 0 ? `${((processData.noTOSoldCount / processData.noTOCount) * 100).toFixed(1)}%` : '0.0%'}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TOReport;
