import React, { useState, useEffect } from "react";
import axios from "axios";
import "./UploadData.css"; // Ensure this file includes the updated styles

// Set backend URL via environment variable or default value
const backendUrl = process.env.REACT_APP_BACKEND_URL || "";

const UploadData = () => {
  const [user, setUser] = useState(null);
  const [files, setFiles] = useState([]); // Files as stored in the bucket (their file names)
  const [selectedFiles, setSelectedFiles] = useState([]); // Files chosen for upload
  const [uploading, setUploading] = useState(false);
  const [uploadButtonText, setUploadButtonText] = useState("Upload");
  const [deletingFile, setDeletingFile] = useState(null);
  const [reportType, setReportType] = useState("FP_Recon"); // Default report type
  const [requiredFiles, setRequiredFiles] = useState([]);
  // User-selected month for filtering (format "YYYY-MM")
  const [selectedMonth, setSelectedMonth] = useState(() => {
    const now = new Date();
    return now.toISOString().slice(0, 7);
  });

  // Define required file names per report type.
  const reportFileRequirements = {
    FP_Recon: ["FP ADP.txt", "Suntrust.xls"],
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (!user) return;
    fetchFiles();
    setRequiredFiles(reportFileRequirements[reportType] || []);
  }, [reportType, user, selectedMonth]);

  const fetchUserDetails = async () => {
    try {
      const token = sessionStorage.getItem("accessToken");
      if (!token) {
        console.error("No token found in session storage.");
        return;
      }
      const response = await axios.get(`${backendUrl}/getUserDetails`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("Fetched user:", response.data);
      setUser({ ...response.data, token });
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const fetchFiles = async () => {
    try {
      if (!user || !user.token) return;
      const response = await axios.get(
        `${backendUrl}/api/gcs/list-files/${reportType}`,
        { headers: { Authorization: `Bearer ${user.token}` } }
      );
      console.log("Fetched files:", response.data.files);
      setFiles(response.data.files);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const handleFileChange = (event) => {
    const filesArray = Array.from(event.target.files);
    setSelectedFiles(filesArray);
  };

  const formatDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
  };

  // Upload selected files with naming: <baseName>_<selectedMonth>_<timestamp>.<extension>
  const handleUpload = async () => {
    if (selectedFiles.length === 0 || !user || !user.token) {
      setUploadButtonText("Select file(s) to upload");
      return;
    }
    setUploading(true);
    setUploadButtonText("Uploading...");
    try {
      const uploadPromises = selectedFiles.map(async (file) => {
        const formattedDate = formatDate();
        const fileNameParts = file.name.split(".");
        const baseName = fileNameParts.slice(0, -1).join(".");
        const extension = fileNameParts.pop();
        const formattedFileName = `${baseName}_${selectedMonth}_${formattedDate}.${extension}`;
        console.log("Uploading file:", formattedFileName);
        const { data } = await axios.post(
          `${backendUrl}/api/gcs/generate-upload-url`,
          { filename: formattedFileName, reportType },
          { headers: { Authorization: `Bearer ${user.token}` } }
        );
        await fetch(data.uploadUrl, {
          method: "PUT",
          body: file,
        });
      });
      await Promise.all(uploadPromises);
      setUploadButtonText("Upload Successful");
      setSelectedFiles([]);
      fetchFiles();
      setTimeout(() => {
        setUploadButtonText("Upload");
      }, 2500);
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadButtonText("Upload Failed");
      setTimeout(() => {
        setUploadButtonText("Upload");
      }, 2500);
    }
    setUploading(false);
  };

  const handleDownload = async (fileName) => {
    try {
      if (!user || !user.token) return;
      const { data } = await axios.post(
        `${backendUrl}/api/gcs/generate-download-url`,
        { filename: fileName, reportType },
        { headers: { Authorization: `Bearer ${user.token}` } }
      );
      window.open(data.downloadUrl);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleDelete = async (fileName) => {
    try {
      if (!user || !user.token) return;
      setDeletingFile(fileName);
      await axios.delete(`${backendUrl}/api/gcs/delete-file`, {
        data: { filename: fileName, reportType },
        headers: { Authorization: `Bearer ${user.token}` },
      });
      fetchFiles();
      setDeletingFile(null);
    } catch (error) {
      console.error("Error deleting file:", error);
      setDeletingFile(null);
    }
  };

  // Filter files: only show files whose names include _<selectedMonth>_.
  const filteredFiles = files.filter((file) =>
    file.toLowerCase().includes(`_${selectedMonth}_`)
  );

  // Normalize a name by removing spaces and converting to lowercase.
  const normalizeName = (name) => name.toLowerCase().replace(/\s+/g, "");

  // Check if a required file has been uploaded.
  const isRequiredFileUploaded = (reqFile) => {
    const requiredBase = normalizeName(reqFile.split(".")[0]);
    return files.some((file) => {
      if (!file.toLowerCase().includes(`_${selectedMonth}_`)) return false;
      const parts = file.split(`_${selectedMonth}_`);
      if (parts.length < 2) return false;
      const uploadedBase = normalizeName(parts[0]);
      return uploadedBase.includes(requiredBase);
    });
  };

  if (!user) {
    return <p>Loading user details...</p>;
  }

  return (
    <div className="upload-container">
      <h2>Upload Data for {reportType} Report</h2>
      <div className="header-row">
        <div>
          <label htmlFor="reportTypeSelect">Report Type:</label>
          <select
            id="reportTypeSelect"
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
          >
            <option value="FP_Recon">FP Recon</option>
          </select>
        </div>
        <div>
          <label htmlFor="monthSelect">Select Month:</label>
          <input
            id="monthSelect"
            type="month"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
          />
          <p style={{ fontSize: "0.9em", color: "#666" }}>
          (Select date to submit and retrieve files.)
        </p>
        </div>
      </div>
      <br />
      <h3>Required Files for {reportType}</h3>
      <ul className="required-list">
        {requiredFiles.map((reqFile, index) => (
          <li
            key={index}
            className={
              isRequiredFileUploaded(reqFile) ? "submitted" : "not-submitted"
            }
          >
            <span className="icon" />
            {reqFile}
          </li>
        ))}
      </ul>
      <br />
      {selectedFiles.length > 0 && (
        <div className="selected-files">
          <h3>Selected Files</h3>
          <ul>
            {selectedFiles.map((file, idx) => (
              <li key={idx}>{file.name}</li>
            ))}
          </ul>
        </div>
      )}
      <br />
      {user.user_role &&
        ["admin", "general_manager", "executive", "controller"].includes(
          user.user_role
        ) && (
          <>
            <input type="file" multiple onChange={handleFileChange} />
            <button onClick={handleUpload} disabled={uploading}>
              {uploadButtonText}
            </button>
          </>
        )}
      <br />
      {deletingFile && (
        <div className="spinner">Deleting {deletingFile}...</div>
      )}
      <h3>Uploaded Files</h3>
      <ul className="files-list">
        {filteredFiles.map((file, idx) => (
          <li key={idx}>
            <span className="file-name">{file}</span>
            <span className="actions">
              {user.user_role &&
                ["admin", "general_manager", "executive", "controller"].includes(
                  user.user_role
                ) && (
                  <>
                    <button onClick={() => handleDownload(file)}>
                      Download
                    </button>
                    <button onClick={() => handleDelete(file)}>
                      {deletingFile === file ? "Deleting..." : "Delete"}
                    </button>
                  </>
                )}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UploadData;
