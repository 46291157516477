// src/pages/ChangePasswordPage.js
import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './ChangePasswordPage.css';
import loginLogo from '.././Assets/login_logo.png'; // Replace with your logo path
import ParticleBackground from '.././Assets/ParticleBackground';

const ChangePasswordPage = ({ onChangePassword }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showParticles, setShowParticles] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        setError('No authorization token found');
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/change-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ newPassword }),
      });

      if (response.ok) {
        setError('');
        localStorage.setItem('firstLogin', 'false');
        onChangePassword();
      } else if (response.status === 403) {
        setError('Invalid token or session expired. Please log in again.');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('firstLogin');
      } else {
        setError('Error changing password');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div
      className="change-password-page"
      onMouseEnter={() => setShowParticles(true)}
      onMouseLeave={() => setShowParticles(false)}
    >
      {/* <div className="change-password-background-container"> */}
        <ParticleBackground animate={showParticles} />
        <div className="change-password-container">
          <img src={loginLogo} alt="Login Logo" className="login-logo" />
          <h1 className="change-password-title">Change Password</h1>
          <form onSubmit={handleSubmit} className="change-password-form">
            <div className="change-password-field">
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <span className="change-password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            <div className="change-password-field">
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <span className="change-password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            {error && <p className="error-message">{error}</p>}
            <button type="submit">Change Password</button>
          </form>
        </div>
      {/* </div> */}
    </div>
  );
};

export default ChangePasswordPage;
