// FPReconReport.jsx
import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import "./FPReconReport.css";

const FPReconReport = ({ reportData, selectedMonth }) => {
  // -------------------------------------
  // 1) Tab states & Sorting states
  // -------------------------------------
  const [activeTab, setActiveTab] = useState("discrepancies");

  // Sorting for Discrepancies
  const [sortedDetailRows, setSortedDetailRows] = useState([]);
  const [sortConfigDetail, setSortConfigDetail] = useState({
    key: "issue",
    direction: "ascending",
  });

  // Sorting for Matches
  const [sortedMatchingRows, setSortedMatchingRows] = useState([]);
  const [sortConfigMatches, setSortConfigMatches] = useState({
    key: "issue",
    direction: "ascending",
  });

  // Fallback for dealershipName if not in reportData
  const [rooftop, setRooftop] = useState("");

  // -------------------------------------
  // 2) Possibly fetch user details to get rooftop
  // -------------------------------------
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = sessionStorage.getItem("accessToken");
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/getUserDetails`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }
        const data = await response.json();
        setRooftop(data.rooftop || "");
      } catch (err) {
        console.error("Error fetching user details:", err);
      }
    };

    if (reportData && !reportData.dealershipName) {
      fetchUserDetails();
    }
  }, [reportData]);

  // -------------------------------------
  // 3) Generic sorting function
  // -------------------------------------
  const sortRows = (rows, config) => {
    if (!rows) return [];
    const { key, direction } = config;
    return [...rows].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
  };

  // Sort detailRows whenever they or sortConfigDetail changes
  useEffect(() => {
    if (reportData?.detailRows) {
      setSortedDetailRows(sortRows(reportData.detailRows, sortConfigDetail));
    }
  }, [reportData, sortConfigDetail]);

  // Sort matchingRows whenever they or sortConfigMatches changes
  useEffect(() => {
    if (reportData?.matchingRows) {
      setSortedMatchingRows(sortRows(reportData.matchingRows, sortConfigMatches));
    }
  }, [reportData, sortConfigMatches]);

  // -------------------------------------
  // 4) Helpers to request a sort
  // -------------------------------------
  const requestSortDetail = (key) => {
    setSortConfigDetail((prev) => {
      let direction = "ascending";
      if (prev.key === key && prev.direction === "ascending") {
        direction = "descending";
      }
      return { key, direction };
    });
  };

  const requestSortMatches = (key) => {
    setSortConfigMatches((prev) => {
      let direction = "ascending";
      if (prev.key === key && prev.direction === "ascending") {
        direction = "descending";
      }
      return { key, direction };
    });
  };

  // Indicators for sorted columns
  const getSortIndicatorDetail = (key) => {
    if (sortConfigDetail.key === key) {
      return sortConfigDetail.direction === "ascending" ? " ▲" : " ▼";
    }
    return "";
  };

  const getSortIndicatorMatches = (key) => {
    if (sortConfigMatches.key === key) {
      return sortConfigMatches.direction === "ascending" ? " ▲" : " ▼";
    }
    return "";
  };

  // -------------------------------------
  // 5) Download Excel with two sheets
  // -------------------------------------
  const handleDownloadExcel = () => {
    if (!reportData) return;

    // Create workbook
    const wb = XLSX.utils.book_new();

    // Summary sheet
    const summaryData = (reportData.scheduleSummaries || []).map((item) => [
      item.label,
      item.value,
    ]);
    // Insert blank rows at certain places for spacing
    if (summaryData.length >= 4) summaryData.splice(4, 0, []);
    if (summaryData.length >= 7) summaryData.splice(7, 0, []);
    const wsSummary = XLSX.utils.aoa_to_sheet([["Label", "Value"], ...summaryData]);
    XLSX.utils.book_append_sheet(wb, wsSummary, "Summary");

    // Discrepancies sheet
    if (sortedDetailRows.length > 0) {
      const wsDiscrep = XLSX.utils.json_to_sheet(sortedDetailRows, {
        header: [
          "issue",
          "serialNumber",
          "yearAndMake",
          "scheduleDate",
          "amount",
          "scheduleOrigin",
          "soldDate",
          "customer",
        ],
      });
      XLSX.utils.book_append_sheet(wb, wsDiscrep, "Discrepancies");
    }

    // Matches sheet
    if (sortedMatchingRows.length > 0) {
      const wsMatches = XLSX.utils.json_to_sheet(sortedMatchingRows, {
        header: [
          "issue",
          "serialNumber",
          "yearAndMake",
          "scheduleDate",
          "amount",
          "scheduleOrigin",
          "soldDate",
          "customer",
        ],
      });
      XLSX.utils.book_append_sheet(wb, wsMatches, "Matches");
    }

    // Build file name
    const currentRooftop = reportData.dealershipName || rooftop || "Report";
    let formattedDate = "";
    if (selectedMonth) {
      const parts = selectedMonth.split("-");
      if (parts.length === 2) {
        formattedDate = `${parts[1]}-${parts[0]}`;
      } else {
        formattedDate = selectedMonth;
      }
    }
    const fileName = formattedDate
      ? `FP Recon - ${currentRooftop} ${formattedDate}.xlsx`
      : `FP Recon - ${currentRooftop}.xlsx`;

    // Download
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    saveAs(blob, fileName);
  };

  // If no data yet, show loading
  if (!reportData) {
    return <div>Loading FP Recon report...</div>;
  }

  const { dealershipName, reportTitle, asOfDate, scheduleSummaries = [] } = reportData;

  // -------------------------------------
  // 6) Render the UI
  // -------------------------------------
  return (
    <div className="fp-recon-report">
      {/* Header Section */}
      <div className="header-section">
        <h3>{dealershipName || rooftop}</h3>
        <h3>{reportTitle}</h3>
        <div>
          <strong>As of:</strong> {asOfDate}
        </div>
      </div>

      {/* Summary Box */}
      <div className="summary-box">
        {scheduleSummaries.map((item, idx) => (
          <React.Fragment key={idx}>
            <div className="summary-line">
              <span className="label">{item.label}</span>
              <span className="value">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(item.value)}
              </span>
            </div>
            {(idx === 3 || idx === 5) && <hr />}
          </React.Fragment>
        ))}
      </div>

      {/* Download Button */}
      <button className="download-btn" onClick={handleDownloadExcel}>
        Download Excel
      </button>

      {/* Tab Buttons (overlap styling in CSS) */}
      <div className="tab-buttons" style={{ position: "relative" }}>
        <button
          className={`tab-button ${activeTab === "discrepancies" ? "active" : ""}`}
          onClick={() => setActiveTab("discrepancies")}
        >
          Discrepancies
        </button>
        <button
          className={`tab-button ${activeTab === "matches" ? "active" : ""}`}
          onClick={() => setActiveTab("matches")}
        >
          Matches
        </button>
      </div>

      {/* DISCREPANCIES TABLE */}
      {activeTab === "discrepancies" && (
        <>
          {/* <h4>Detail of Unit Discrepancies</h4> */}
          <table className="discrepancies-table">
            <thead>
              <tr>
                <th onClick={() => requestSortDetail("issue")}>
                  Issue{getSortIndicatorDetail("issue")}
                </th>
                <th onClick={() => requestSortDetail("serialNumber")}>
                  Serial Number{getSortIndicatorDetail("serialNumber")}
                </th>
                <th onClick={() => requestSortDetail("yearAndMake")}>
                  Year and Make{getSortIndicatorDetail("yearAndMake")}
                </th>
                <th onClick={() => requestSortDetail("scheduleDate")}>
                  Schedule Date{getSortIndicatorDetail("scheduleDate")}
                </th>
                <th onClick={() => requestSortDetail("amount")}>
                  Amount{getSortIndicatorDetail("amount")}
                </th>
                <th onClick={() => requestSortDetail("scheduleOrigin")}>
                  Schedule Origin{getSortIndicatorDetail("scheduleOrigin")}
                </th>
                <th onClick={() => requestSortDetail("soldDate")}>
                  Sold Date{getSortIndicatorDetail("soldDate")}
                </th>
                <th onClick={() => requestSortDetail("customer")}>
                  Customer{getSortIndicatorDetail("customer")}
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedDetailRows.map((row, idx) => (
                <tr key={idx}>
                  <td>{row.issue}</td>
                  <td>{row.serialNumber}</td>
                  <td>{row.yearAndMake}</td>
                  <td>{row.scheduleDate}</td>
                  <td>
                    {typeof row.amount === "number"
                      ? new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(row.amount)
                      : row.amount}
                  </td>
                  <td>{row.scheduleOrigin}</td>
                  <td>{row.soldDate}</td>
                  <td>{row.customer}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      {/* MATCHES TABLE */}
      {activeTab === "matches" && (
        <>
          {/* <h4>Detail of Unit Matches</h4> */}
          {sortedMatchingRows.length > 0 ? (
            <table className="matches-table">
              <thead>
                <tr>
                  <th onClick={() => requestSortMatches("issue")}>
                    Issue{getSortIndicatorMatches("issue")}
                  </th>
                  <th onClick={() => requestSortMatches("serialNumber")}>
                    Serial Number{getSortIndicatorMatches("serialNumber")}
                  </th>
                  <th onClick={() => requestSortMatches("yearAndMake")}>
                    Year and Make{getSortIndicatorMatches("yearAndMake")}
                  </th>
                  <th onClick={() => requestSortMatches("scheduleDate")}>
                    Schedule Date{getSortIndicatorMatches("scheduleDate")}
                  </th>
                  <th onClick={() => requestSortMatches("amount")}>
                    Amount{getSortIndicatorMatches("amount")}
                  </th>
                  <th onClick={() => requestSortMatches("scheduleOrigin")}>
                    Schedule Origin{getSortIndicatorMatches("scheduleOrigin")}
                  </th>
                  <th onClick={() => requestSortMatches("soldDate")}>
                    Sold Date{getSortIndicatorMatches("soldDate")}
                  </th>
                  <th onClick={() => requestSortMatches("customer")}>
                    Customer{getSortIndicatorMatches("customer")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedMatchingRows.map((row, idx) => (
                  <tr key={idx}>
                    <td>{row.issue}</td>
                    <td>{row.serialNumber}</td>
                    <td>{row.yearAndMake}</td>
                    <td>{row.scheduleDate}</td>
                    <td>
                      {typeof row.amount === "number"
                        ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(row.amount)
                        : row.amount}
                    </td>
                    <td>{row.scheduleOrigin}</td>
                    <td>{row.soldDate}</td>
                    <td>{row.customer}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No matching records found.</p>
          )}
        </>
      )}
    </div>
  );
};

export default FPReconReport;